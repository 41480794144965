import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';
import { BasicBlueButton } from '../common/commonUI';
import { getFormattedNow } from '../common/util';

const NoticePosting = () => {
    const history: any = useHistory();
    // const groupValue = useContext(GroupContext);
    const [title, setTitle] = useState("");
    const [content, setContent] = useState("");
    // const [uploadFileUrl, setUploadFileUrl] = useState("");
    const [checkFixLevel, setCheckFixLeve] = useState(false);
    const [selectedFiles, setSelectedFiles] = useState<Array<any>>([]);
    const [originalFileNames, setOriginalFileNames] = useState<Array<string>>([]);
    
    const jiguId = history.location.state.jigu;
    const authLevel = history.location.state.authLevel;

    useEffect(() => {
        console.log("selectedFiles >> ", selectedFiles);
        let fileNames : Array<string> = [];
        selectedFiles.forEach((each: Array<any>) => {
            for (let i = 0; i < each.length; i++) {
                fileNames.push(each[i].name);
            }
        })
        setOriginalFileNames(fileNames);
    }, [selectedFiles]);

    const handleFileInputChange = (ev: any) => {
        let uploadFiles = ev.target.files;
        setSelectedFiles(selectedFiles.concat(uploadFiles));
    }

    const handleFixLeve = () => {
        setCheckFixLeve(!checkFixLevel);
    }

    const initSelectFile = () =>{
        setSelectedFiles([]);
        setOriginalFileNames([]);
    }

    const uploadNotice = () => {
        let finalFiles: Array<any> = [];
        let fileNames: Array<string> = [];
        selectedFiles.forEach((each: Array<any>) => {
            for (let i = 0; i < each.length; i++) {
                finalFiles.push(each[i]);
                fileNames.push(getFormattedNow() + "_" + each[i].name);
            }
        })
        let uploadData = {
            fixedLevel: checkFixLevel ? 1 : 0,
            title: title,
            content: content,
            fileNames: fileNames,
            jiguId: jiguId,
        }
        // const actualFile = inputPic[0];
        console.log(" uploadData >> ", uploadData);
        try {
            const formBody = new FormData();
            for (let i = 0; i < finalFiles.length; i++) {
                formBody.append("files", finalFiles[i]);
                formBody.append("fileNames", fileNames[i]);
            }

            axios.post(comm.SERVER_URL + "/notice/withoutpush", uploadData).then(resNotice => {
                console.log(" resNotice >> ", resNotice);
                if (resNotice.data.ok) {
                    if(selectedFiles.length == 0){
                        alert("공지가 성공적으로 등록되었습니다.");
                        history.push('/noticeListJigu', {jigu:jiguId, authLevel:authLevel});
                    }else{   // 파일이 있는 경우
                        axios.post(comm.SERVER_URL + "/common/multipleFileUpload", formBody, {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }).then(resUpload => {
                            console.log(" resUpload >> ", resUpload);
                            if(resUpload.data.ok){
                                alert("공지가 성공적으로 등록되었습니다.");
                                history.push('/noticeListJigu', {jigu:jiguId, authLevel:authLevel});
                            }
                        });
                    }
                }
            });
        } catch (ex) {

        }
    }

    return ( <div className='grid place-items-center'>
                <div className='mt-12 font-bold text-2xl'>공지 등록</div>
                <div className='flex flex-row items-end mt-4'><div className='w-100'></div><span className='hover:underline hover:text-blue-700' onClick={(ev) => {history.push('/noticeListJigu', {jigu:jiguId, authLevel:authLevel})}}>리스트 보기</span></div>
                <div className='flex flex-col mt-2'>
                    <div className='flex flex-row items-center mt-4'>
                        <span>제목: </span>
                        <input type='text' className='input w-116 h-12 ml-4' onChange={(ev) => {setTitle(ev.target.value)}} />
                    </div>
                    <div className='flex flex-col items-start mt-4'>
                        <span>내용: </span>
                        <textarea className='w-132 h-52 border p-2 border-gray-400' onChange={(ev) => {setContent(ev.target.value)}} />
                    </div>
                    <div className='flex flex-row justify-between items-center mt-4'>
                        <div className='flex flex-row'>
                            <span className="w-20">파일 첨부</span>
                            <label className='w-22 ml-1 flex items-center justify-center border-gray-300 border-2 rounded-lg'>파일 선택
                                <input type="file" name="inputPic" multiple id="inputPic" accept="*.*" className='hidden' onChange={(ev) => { handleFileInputChange(ev) }} />
                            </label>
                        </div>
                        <div>
                            <BasicBlueButton btnText='선택 초기화' width='w-24' height='h-8' font='text-sm' margin='mr-3' handleClick={initSelectFile} />
                        </div>
                    </div>
                    <div className={originalFileNames.length > 0 ? 'flex flex-col mt-4 p-2 border border-gray-200': 'hidden'} id="file-area">
                    {
                        originalFileNames.map(fileName => {
                            return (<div className='flex flex-row ml-1 mr-1'>
                                <span className='text-sm'>{fileName}</span>
                            </div>)
                        })
                    }
                    </div>
                    <div className='h-4'></div>
                    <div className='flex flex-row justify-center mt-4'>
                        <BasicBlueButton btnText='공지 올리기' width='w-48' height='h-10' font='text-lg' margin='mr-6' handleClick={uploadNotice} />
                        <label className='flex items-center ml-6'>
                            <input type='checkbox' defaultChecked={false} className='w-5 h-5 mr-2 text-sm' onChange={handleFixLeve} />상단 고정
                        </label>
                    </div>
                </div>
            </div>)
}

export default NoticePosting;
