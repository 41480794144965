import React from 'react';
import './aboutLions.css';
import LionsSong from '../../images/lions_song.png'; 
import {useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';


interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutSong: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();
    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="라이온스 노래" />
            </div>
            <div className="flex flex-col mt-22 pl-8 pr-8">
                <img src={LionsSong} className="song-img"  />
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutSong;
