import React , { useState, useEffect } from 'react';
import logo from '../../images/lions_main.png';
import comm from '../../common';
import { IconButton, Snackbar } from '@material-ui/core';
import { Button } from '@material-ui/core';
import axios from 'axios';
import {useHistory} from 'react-router-dom';
import { useForm } from "react-hook-form";
import CloseIcon from '@material-ui/icons/Close';

// import { belongToInfo } from './main';
interface Club {
    id:number,
    name:string,
}

interface belongToInfo {
    belongTo:number,
    jiyeokId:number,
    jiguId:number,
}

const AdminSignUp = (props:any) => {
    const { register, handleSubmit, getValues, setValue } = useForm();
    const history = useHistory();
    const [clubs, setClubs] = useState<Array<Club>>([]);
    const [belongToInfo, setBelongToInfo] = useState<belongToInfo>();
    const [deleteMemberSnackBarOpen, setDeleteMemberSnackBarOpen] = useState(false);

    useEffect(()=>{
        axios.get(comm.SERVER_URL+"/group/allclubs").then(res =>{
            setClubs(res.data);
        });

    },[]); 

    const DeleteWarningSnackBar = () => {
        const handleClose = () => {
            setDeleteMemberSnackBarOpen(false);
        };
        const handleDeleteMember = () =>{
            setDeleteMemberSnackBarOpen(false);
            history.push("/admin");
        }

        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ backgroundColor: '#000000' }}
                    open={deleteMemberSnackBarOpen}
                    autoHideDuration={6000}
                    onClose={handleClose}
                    message="회원 가입 신청이 접수되었습니다. 관리자 확정 후 로그인 가능합니다."
                    action={
                        <div className="flex flex-row justify-start items-center">
                            <Button color="secondary" variant="contained" size="medium" onClick={handleDeleteMember}>
                                확인
                            </Button>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                />
            </div>
        );
    };

    const onSubmit = () =>{
        const {inputId, inputPass, inputPassRe, inputMobile, inputClub} = getValues();
        console.log("inputClub >> ", inputClub);
        console.log("inputMobile >> ", inputMobile.substr(0,3));
        console.log("inputMobile >> ", inputMobile.length);
        if(inputMobile.length == 11 || inputMobile.length == 10 || inputMobile.substr(0,3) == "010"){

        }else{
            alert("올바른 전화번호인지 확인해 주세요.");
            return;
        }
        
        if(inputId && inputPass){ 
            if(inputPass === inputPassRe){
                const adminData = {
                    mId: inputId,
                    pass: inputPass,
                    mobile: inputMobile,
                    club: Number(inputClub),
                    jidae: belongToInfo?.belongTo,
                    jiyeok: belongToInfo?.jiyeokId,
                    jigu: belongToInfo?.jiguId,
                }
                console.log("belongToInfo >> ", adminData);
                if(inputClub === -1){
                    alert("소속 클럽을 선택해 주세요.");
                    return;
                }

                axios.post(comm.SERVER_URL+"/admin", adminData).then((res)=>{
                    console.log(" res >> ", res.data);
                    if(res.data.ok){
                        // sign up 성공
                        console.log(" @@@@@@@@@ res @@@@@@@@@ ");
                        // setDeleteMemberSnackBarOpen(true);
                        alert("가입 요청이 성공적으로 전달 되었습니다.");
                        props.fire();
                    }else{
                        alert("이미 존재하는 ID이거나 전화번호 입니다.");
                    }
                })
            }else{
                alert("패쓰워드를 다시 확인 해주세요.");
            }
        }else{
            alert("아이디 혹은 패쓰워드를 입력해주세요");
        }
    }
 
    const handleClubs = (ev:any) => {
       axios.get(comm.SERVER_URL+"/group/belongto", { params: {
           id: ev.target.value
       }}).then(res => {
        setBelongToInfo(res.data);
       });
    };

    return(
        <>
            <div className="flex flex-col pl-2 pt-2 pr-3 pb-2 lg:pl-6 lg:pt-3 w-128 bg-white">
                {/* <div>
                    <img className="lg:h-25h lg:w-2/5" src={logo} />
                </div> */}
                <form className="lg:h-54h lg:w-9/10" onSubmit={handleSubmit(onSubmit)}>
                    <div className="pl-2 lg:pl-8">
                        <div className="mt-5 lg:mt-6 mb-4 font-bold text-2xl">Linons Admin 회원 가입</div>
                        <div className="flex flex-row items-center mt-3">
                            <span className="w-28">아이디</span>
                            <input type="text" name="inputId" className="input w-72" ref={register} required />
                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <span className="w-28">패스워드</span>
                            <input type="password" name="inputPass" className="input w-72" ref={register} required />
                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <span className="w-28">패스워드 확인</span>
                            <input type="password" name="inputPassRe" className="input w-72" ref={register} required />
                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <span className="w-28">전화 번호</span>
                            <input type="number" name="inputMobile" className="input w-72" placeholder="숫자만 ex)01091910101" ref={register} required />
                        </div>
                        <div className="flex flex-row items-center mt-3">
                            <span className="w-28">소속 클럽</span>
                            <select className="input mt-2 w-72" name="inputClub" onChange={handleClubs} ref={register} required>
                                <option value={-1}>소속 클럽을 선택해 주세요.</option>
                                {
                                    clubs?.map(club => {
                                        return (<option value={club.id}>{club.name}</option>)
                                    })
                                }
                            </select>
                        </div>
                        <button type="submit" className="mt-5 lg:mt-8 mb-4 rounded-md border-black-900 border-solid border-4 border-opacity-80 w-100 h-16 hover:bg-blue-200">회원 가입 요청</button>
                    </div>
                </form>
            </div>
            {DeleteWarningSnackBar()}
        </>
    )
}

export default AdminSignUp;