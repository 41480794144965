import React from 'react';
import './aboutLions.css';
import ExecutivesImg from '../../images/club_executives.jpg'; 
import EnterDocImg from '../../images/enter_docs.jpg'; 
import MeetingDocImg from '../../images/meeting_docs.jpg'; 
import NotebookFormImg from '../../images/notebook_form.jpg'; 
import RecommendFormImg from '../../images/recommend_form.jpg'; 


import {useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';


interface DocProps {
    title: string,
    id: number,
}

const AboutDocs: React.FC<DocProps> = (props) => {
    const history : any = useHistory();
    let imgToShow = '';
    let title = history.location.state.title
    if(title == '회의록'){
        imgToShow = MeetingDocImg;
    }else if(title == '입회원서'){
        imgToShow = EnterDocImg;
    }else if(title == '추천서'){
        imgToShow = RecommendFormImg;
    }else if(title == '임원명단'){
        imgToShow = ExecutivesImg;
    }else if(title == '지구수첩'){
        imgToShow = NotebookFormImg;
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title={title} />
            </div>
            <div className="flex flex-col mt-22 pl-8 pr-8">
                <img src={imgToShow} className="song-img"  />
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutDocs;
