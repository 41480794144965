import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory, useParams } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import { SmsDto } from '../common/dto';
import { BasicBlueButton } from '../common/commonUI';
import { ISmsInfo, ISurveyItem } from '../mobile/survey';

export interface ISurveyBoard{
    originalSms:ISmsInfo,
    items: Array<ISurveyItem>,
}

function SurveyBoard(props: any): any {
    const history: any = useHistory();
    const jiguId = history.location.state.jigu;
    const [surveyList, setSurveyList] = useState<Array<ISurveyBoard>>([]);
    
    useEffect(()=>{
        axios.get(comm.SERVER_URL+"/sms/surveyBoard/"+jiguId).then(res => {
            setSurveyList(res.data);
        });


    },[]);

    const getTotalRepliedUsers = (survey: ISurveyBoard) => {
        let totalUsers = 0;
        survey.items.forEach(each => {
            totalUsers = totalUsers + each.replyUsers.length;
        });
        return totalUsers;
    }

    const getEachItemsRatio = (survey: ISurveyBoard, repliedUsersCnt:number ) => {
        let totalUsers = getTotalRepliedUsers(survey);
        let ratio = Math.floor((repliedUsersCnt / totalUsers) * 100); 
        return ratio;
    }

    const getTotalSentUsers = async (survey : ISurveyBoard) => {
        let smsId = survey.originalSms.id;
        let result = await axios.get(comm.SERVER_URL+"/sms/sentUsers/"+smsId);
        console.log(" sentUsers >> ", result);
        return result;
    }

    return (
        <div className="grid place-items-center">
            <div className='flex flex-col'>
                {
                    surveyList.map((eachSurvey : ISurveyBoard) => {
                        let totalSentUsers = getTotalSentUsers(eachSurvey);
                        return (<div className='flex flex-col border-2 mt-2 border-gray-400 rounded-lg p-2'>
                            <span className='mb-2'>{eachSurvey.originalSms.content}</span>
                            {
                                eachSurvey.items.map(eachItem => {
                                    return (<div className='flex flex-row'>
                                        <span className=''>{eachItem.itemContent}</span>
                                        <span className=''>{eachItem.replyUsers.length}명</span>
                                        <span className=''>{getEachItemsRatio(eachSurvey, eachItem.replyUsers.length)}%</span>
                                    </div>)
                                })
                            }
                        </div>)
                    })
                }
            </div>
        </div>
    )
}  

export default SurveyBoard;