import React from 'react';
import './aboutLions.css';
import { useHistory, useParams } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
import comm from '../../common';

interface LoginProps {
    club: string,
    id: number,
}

const Login: React.FC<LoginProps> = (props) => {
    const [code, setCode] = useState("");
    const {deviceId} = useParams<any>()
    let history = useHistory();
    const onCodeChange = (ev:any) => {
        setCode(ev.target.value);
    };

    const handleLogin = () => {
        axios.get(comm.SERVER_URL+"/member/getByPhone", {
            params:{id:code}            // 전화번호로 조회 
        }).then(res => {
            if(res.data){
                if(res.data.deviceId){    // 해당 deviceId에 등록된 정보가 없어서 로그인 페이지로 왔는데, 기존에 디바이스 아이디가 있으면 새로운 디바이스 아이디인 셈  
                    if(res.data.mobileNum == "01098090761"){  // 아이폰 테스트 계정 
                        let data = res.data;
                        const userPath = data.id + "&" + data.belongToClub + "&" + data.belongToJidae + "&" + data.belongToJiyeok + "&" + data.belongToJigu;
                        // deviceId를 등록해 줌. 
                        axios.put(comm.SERVER_URL+"/member/addInitalInfo", {
                            memberId: data.id,
                            deviceId: deviceId,
                            fcmToken: data.fcmToken
                          }).then(response => {
                            history.push("/clubList/"+userPath);
                          });
                    }else{
                        alert("이미 등록된 회원입니다. 새로운 핸드폰에 사용하시는 경우, 관리자에게 초기화를 요청해주세요.");   // 전화번호도 있고 디바이스 아이디도 있다. --> 어떤 하나의 폰에 등록되어 있다. (1계정 1폰)
                    }
                
                }else{  // 새로 등록하는 경우: deviceId를 업데이트 한다. (전화번호만 등록되어 있고 디바이스 아이디가 없다 --> 어드민이 등록은 했으나, 아직 회원이 앱설치, 실행을 안한 경우)
                    // 이때 디바이스 아이디랑 fcmToken도 같이 등록해준다.
                    let data = res.data;
                    const userPath = data.id + "&" + data.belongToClub + "&" + data.belongToJidae + "&" + data.belongToJiyeok + "&" + data.belongToJigu;
                    // deviceId를 등록해 줌. 
                    axios.put(comm.SERVER_URL+"/member/addInitalInfo", {
                        memberId: data.id,
                        deviceId: deviceId,
                        fcmToken: data.fcmToken
                      }).then(response => {
                        history.push("/clubList/"+userPath);
                      });
                }
            }else{   // 전화번호가 등록되지 않은 회원 --> Admin이 전화번호를 등록해줘야 한다.
                alert("아직 등록되지 않은 회원입니다. 관리자에게 회원 등록을 먼저 요청하세요.");
            }

        });

    }

    return (
        <div className="flex flex-col flex-1 bg-lions-blue h-screen items-center justify-center">
            <div className='text-white text-xl font-bold'>코드를 입력하세요</div>
            <div>
                <input type="number" name="inputCode" className="input mt-2" onChange={onCodeChange}/>
            </div>
            <div>
                <button className="rounded-md mt-3 border-gray-200 border-solid border-2 border-opacity-60 w-48 h-12 hover:bg-red-200" onClick={handleLogin}><span className='text-white'>로그인</span></button>
            </div>
        </div>
    )
}



export default Login;
