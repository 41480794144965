import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
// import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { Button } from '@material-ui/core';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

interface noticeType {
    id?: number,
    clubId?: number,
    title?: string,
    content?: string,
    updatedAt?: string,
    imgUrl?: string,
}

interface ChildProps {
    fire: () => void,
    club: number,
}

const AddNotice: React.FC<ChildProps> = (props: ChildProps) => {
    const { register, handleSubmit, getValues, setValue } = useForm();
    // const location = useLocation<Location>();
    console.log(" props => ", props);
    const [noticeImg, setNoticeImg] = useState("");
    const [noticeList, setNoticeList] = useState<Array<noticeType>>();
    const [selectedNotice, setSelectedNotice] = useState<noticeType | null>(null);
    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    useEffect(() => {
        // props.memberId
        console.log(" props => ", props.club);
        axios.get(comm.SERVER_URL + "/notice", {
            params: { id: props.club }
        }).then(res => {
            console.log(" res >> ", res.data);
            let resultData = res.data;
            setNoticeList(resultData);
        });

    }, []);

    useEffect(() => {
        console.log(" selectedNotice => ", selectedNotice);
        setValue("inputTitle", selectedNotice?.title);
        setValue("inputContent", selectedNotice?.content);
    }, [selectedNotice])

    const onSubmit = (data: any) => {
        const { inputTitle, inputContent } = getValues();

        let dtoObj: noticeType = {};

        if (selectedNotice?.id) {    // update
            dtoObj = {
                id: selectedNotice.id,
                clubId: props.club,
                title: inputTitle,
                content: inputContent
            }
        } else {                     // add  
            dtoObj = {
                clubId: props.club,
                title: inputTitle,
                content: inputContent
            }
        }

        console.log("dtoObj >> ", dtoObj);
        axios.post(comm.SERVER_URL + "/notice/", dtoObj).then((res) => {
            console.log(" update res >> ", res.data);
            if (res.data.ok) {
                console.log(" Modify notice info !!");
                props.fire();
                axios.post(comm.SERVER_URL + "/push", dtoObj).then(pushRes => {
                    console.log(" pushRes => ", pushRes);
                });
            }
        });
    };

    const showWhen = (when?: string) => {
        let updatedAtStr = when?.split('-');
        let totalStr = "";
        console.log(" updatedAtStr >> ", updatedAtStr);
        if (updatedAtStr) {
            let year = updatedAtStr[0]?.substr(2, 2) + ".";
            let month = updatedAtStr[1] + ".";
            let day = updatedAtStr[2]?.split('T');
            let dayNTime = day[0] + "일 " + day[1].substr(0, 5) + "분";
            totalStr = year + month + dayNTime;
        } else {
            totalStr = "";
        }
        console.log(" totalStr >> ", totalStr);
        return totalStr;
    }

    const showNotice = (noticeId: number) => {

    }

    const handleAddNotice = () => {
        setSelectedNotice({
            title: "",
            content: "",
        });
    }

    const handleDeleteNotice = () => {
        axios.delete(comm.SERVER_URL + "/notice/", {
            params: {
                id: selectedNotice?.id
            }
        }).then((res) => {
            console.log(" update res >> ", res.data);
            if (res.data.ok) {
                console.log(" Delete Notice !!");
                props.fire();
            }
        });
    };

    const handleFileInputChange = (ev: any) => {
        console.log(" handleFileInputChange ev >>> ", ev);
        let imageFile = ev.target.files[0];

        console.log(" file >>> ", URL.createObjectURL(imageFile));
        setNoticeImg(URL.createObjectURL(imageFile));
    }

    const onEditorStateChange = (es: any) => {
        setEditorState(es);
        console.log(" editorState >>> ", es);
    }

    const uploadImageCallBack = (file: any) => {
        return new Promise((resolve, reject) => {
            const data = new FormData();
            data.append("image", file);
            axios.post("https://api.imgur.com/3/image", data).then((res) => {
                console.log(res);
            }).catch(err => {
                console.log(err)
            });
        });
    }

    // const url = comm.SERVER_URL + 'member';
    return (
        <form onSubmit={handleSubmit(onSubmit)} className=" border-gray-800 border-2 bg-white w-196">
            {noticeList ?
                <div>
                    <div id="noticeList" className="flex flex-row justify-center items-center mt-4 mb-4 pl-4 pr-4">
                        <Button
                            className="invisible"
                            startIcon={<AddToPhotos />}

                        >새 공지</Button>
                        <div className="flex-1"></div>
                        <span className="text-2xl">공지 리스트</span>
                        <div className="flex-1"></div>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddToPhotos />}
                            onClick={handleAddNotice}
                        >새 공지</Button>
                    </div>
                    {
                        noticeList.map((notice: noticeType) => {
                            return (
                                <div className="flex flex-row h-16 items-center justify-between p-2 mt-2 mb-2 mr-4 ml-4 border-gray-300 border-2" onClick={() => {
                                    console.log(' Selected Notice !!');
                                    setSelectedNotice(notice);
                                }}>
                                    <span className="ml-3 text-lg">{notice?.title}</span>
                                    <span className="text-sm">{showWhen(notice?.updatedAt)}</span>
                                </div>);
                        })
                    }
                </div> : null}

            {selectedNotice ? (
                <div>
                    <div className="flex ml-8 mr-8 mt-4 flex-row">
                        <div className="w-184">
                            <div className="text-2xl text-center">{selectedNotice?.title ? "공지 수정" : "공지 등록"}</div>
                            <div className="flex flex-row items-center mt-4">
                                제목
                                <input type="text" name="inputTitle" className="input mt-2 ml-6 w-160" ref={register} defaultValue={selectedNotice.title} required />
                            </div>
                            <div className="flex flex-col items-start mt-4">
                                <div className="flex flex-row items-center">내용</div>
                                {/* <div contentEditable="true" className="mt-2 border-gray-300 border-2 p-2 w-176 h-68" ref={register} 
                                defaultValue={selectedNotice.title} onChange={(ev) => {console.log(ev)}} >
                                  
                                    <img src={noticeImg}/> 
                                </div> */}
                                {/* <div className="mt-2 border-gray-300 border-2 p-2 h-80">
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorStateChange}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                            image: { uploadCallback: uploadImageCallBack, alt: { present: true, mandatory: true } },
                                        }}
                                    />
                                </div> */}

                                <textarea name="inputContent" rows={10} cols={75} className="mt-2 border-gray-300 border-2 p-2" ref={register} defaultValue={selectedNotice.title}  />
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-row justify-center items-center h-28">
                        <button type="submit" className="mr-8 rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-blue-200">{selectedNotice?.title ? "공지 수정하기" : "공지 올리기"}</button>
                        {selectedNotice?.title ? <button type="button" className="mr-8 rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-red-200" onClick={handleDeleteNotice}>공지 삭제</button> : null}
                        <button type="button" className="rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-red-200" onClick={props.fire}>취소</button>
                    </div>
                </div>) : null}
        </form>
    )
}

export default AddNotice;