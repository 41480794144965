import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../../common';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import GroupContext from '../../../context/groupContext';
import { to2DArray } from '../../common/util';
import { useHistory } from 'react-router-dom';
import ExecutiveFooter from '../../common/executiveFooter';
import { pageProps } from './common356E';


interface JiyeokInfo {
    id:number;
    name:string;
}

interface JiguMemberProps {
    club: string,
    id: number,
}


const JiyeokSelectScreen = (props : pageProps) => {

    const [jiyeoks, setJiyeoks] = useState<Array<JiyeokInfo>>([]);
    const groupValue = useContext(GroupContext);
    let history = useHistory();
    
    useEffect(() => {
        axios.get(comm.SERVER_URL + "/group/jiyeok", {
            params: {
                id: groupValue.jiguId,
            }
        }).then(res => {
      
            let ji: any[] = [];
             res.data.forEach((each: any,index: number) => {
                if(index > 0){
                    ji.push(each);
                }
            });

            setJiyeoks(ji);
        })
    }, [])

    useEffect(()=>{
        console.log("jiyeoks >> ", jiyeoks)
    },[jiyeoks])

    const goJiyeok = (jiyeokId:number, name:string) => {
        history.push('/jiyeokExecutiveList',{id:jiyeokId, title:name});
    }

    return(
        <div className='flex flex-col items-center mt-24'>
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title="지역임원 및 3역"/>
            </div>
            <div className='jiyeok-icon-img'></div>
            <span className='text-lg mb-8'>지역을 선택하세요</span>
            <div className='flex flex-col items-center mb-18'>
            {
                to2DArray(jiyeoks, 2).map((oneArray: Array<JiyeokInfo>) => {
                    return (<div className='flex flex-row justify-center mt-5 items-center'>
                        {oneArray.map((each:JiyeokInfo) => {
                            return <div className='jiyeok-bg-img' onClick={(ev) => {goJiyeok(each.id, each.name)}}>
                                <span className='ml-4'>{each?.name}</span>
                            </div>
                        })}
                    </div>)
                })
            }
            </div>
            <div className="footer">
                <ExecutiveFooter position={props.footerPosition} />
            </div>
        </div> 
    )
}

export default JiyeokSelectScreen;
