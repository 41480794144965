import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory, useParams } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import { SmsDto } from '../common/dto';
import { BasicBlueButton } from '../common/commonUI';

// var encodeUrl = require('encodeurl')
export interface ISurveyItem {
    id:number,
    smsId:number,
    itemContent: string,
    replyUsers:Array<number>,
}

export interface ISurveyResponse {
    surveyItemId:number,
    repliedUserId:number,
}

export interface ISmsInfo {
    selectionType:number,
    id:number,
    content:string,
}

function Survey(props: any): any {
    const history: any = useHistory();
    const {smsId, userId} = useParams<any>();
    const [smsInfo, setSmsInfo] = useState<ISmsInfo>({selectionType:0, id:0, content:''});
    const [surveyResponse, setSurveyResponse] = useState<Array<ISurveyResponse>>([]);
    const [surveyItems, setSurveyItems] = useState<Array<ISurveyItem>>([]);

    useEffect(()=>{
        axios.get(comm.SERVER_URL+"/sms/byId/"+smsId).then(res => {
            setSmsInfo(res.data);
        });
        axios.get(comm.SERVER_URL+"/sms/getSurveyItems/"+smsId).then(res => {
            setSurveyItems(res.data);
        });

    },[]);

    useEffect(()=>{
        console.log(" smsInfo >> ", smsInfo);
    },[smsInfo])

    const checkMultipleResponse = (each:ISurveyItem) => {
        setSurveyResponse([...surveyResponse, {surveyItemId:each.id, repliedUserId:userId}]);
    };

    const checkSingleResponse = (each:ISurveyItem) => {
        setSurveyResponse([{surveyItemId:each.id, repliedUserId:userId}]);
    };

    const getMultipleSelectItemsUX = () => {
        return surveyItems.map((eachItem : ISurveyItem)=>{
            return <label className="flex items-center">
            <input type="checkbox" name="surveyCheck" value={eachItem.id} className="w-6 h-6 mr-2 " onChange={(ev) => {checkMultipleResponse(eachItem)}}/>{eachItem.itemContent}
        </label>
        })
    };

    const getSingleSelectItemUX = () => {
        return surveyItems.map((eachItem : ISurveyItem)=>{
            return <label className="flex items-center mt-2">
            <input type="radio" name="surveyRadio" value={eachItem.id} className="w-6 h-6 mr-2 " onChange={(ev) => {checkSingleResponse(eachItem)}}/>{eachItem.itemContent}
        </label>
        })
    };

    const submitSurvey = async () => {
        console.log(" ##### ");
        let updateSurveys = surveyItems.map((eachSurvey : ISurveyItem) => {
            return {...eachSurvey, replyUsers: eachSurvey.replyUsers.push(userId)}
        });
        let result = await axios.post(comm.SERVER_URL+"/sms/surveyItems",updateSurveys);
        console.log("result >> ", result);
        if(result.data.ok){
            alert("설문(투표)이 완료되었습니다.");
        }
        
    }

    return (
        <div className="grid place-items-center">
          <div className='flex flex-col mt-4 ml-4'>
            <div className='mb-4 mt-4'>{smsInfo.content}</div>
            <>
            {
                smsInfo.selectionType == 0 ? null : smsInfo.selectionType == 1 ? getSingleSelectItemUX() : getMultipleSelectItemsUX()
            }
            </>
            <div className='h-6'></div>
            <BasicBlueButton width='w-42' height='h-10' btnText='설문(투표) 제출' font='text-basic' handleClick={submitSurvey} /> 
          </div>
        </div>
    )
}

export default Survey;