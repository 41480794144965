import React, { useState, useEffect } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { useParams } from 'react-router-dom';

const SmsLink :any = () => {
    const {userId, smsId} = useParams<any>();
    useEffect(() => {
        // axios.get(comm.SERVER_URL+"/smsHistory").then(res =>{
        //     setSmsHistoryArr(res.data);
        // });
    }, []);


    return (<div className='flex flex-col items-start ml-3 bg-white'>
        <iframe src='http://naver.com' className='w-88'></iframe>
    </div>)
}

export default SmsLink;

