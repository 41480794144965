import React, { useState, useEffect } from 'react';
import comm from '../../common';
import axios from 'axios';
import { useHistory, useParams } from 'react-router-dom';
import { JiyeokNClubDto } from '../common/dto';
import { BasicBlueButton, BasicRedButton } from '../common/commonUI';
import { getArrayToCommaString } from '../common/util';

const AdminSetting :any = () => {
    const [clubNJiyeok, setClubNJiyeok] = useState<Array<JiyeokNClubDto>>();
    const [mId, setmId] = useState("");
    const [pass, setPass] = useState("");
    const [mobileText, setMobileText] = useState("");
    const [adminMobile, setAdminMobile] = useState<Array<string>>([]);
    let history:any = useHistory();
    let jiguId = history.location.state.jiguId;

    useEffect(() => {
        getClubNJiyeaok()

    }, []);
    const getClubNJiyeaok = async () => {
        const cnj = await axios.get(comm.SERVER_URL+"/group/jiyeokNClubs"+"/"+jiguId);
        console.log("cnj >> ", cnj.data);
        setClubNJiyeok(cnj.data)
    }
    
    const hanldeInputValue = (ev:any, type:string) => {
        if(type == 'mId'){
            let mId = ev.target.value;
            setmId(mId.trim());
        }else if(type == 'pass'){
            let pass = ev.target.value;
            setPass(pass.trim());
        }else if(type == 'adminMobile'){
            let number = ev.target.value;
            setMobileText(number.trim());
        }
    }
    
    useEffect(()=>{
        let mobileArr = mobileText.split(',')
        setAdminMobile(mobileArr.map(each =>{
            return each.trim();
        }));
    },[mobileText])

    const applyingAdminSetting = (clubId : number) => {
        let adminInfo = {
            club: clubId,
            // mId: mId,
            // pass: pass,
            mobileArr:adminMobile
        }
        console.log(" adminInfo >> ", adminInfo);
        axios.post(comm.SERVER_URL+"/admin/update", adminInfo).then(res => {
           setmId(res.data.mId); 
           setPass(res.data.pass);
           let mobiles = getArrayToCommaString(res.data.mobileArr);
           setMobileText(mobiles) 
           alert("관리자가 할당 되었습니다.")
        });
        
    }

    return (
        <div className='grid place-items-center'>
            <div className='flex flex-col items-center max-w-xl bg-white'>
                <div className='mt-6 text-xl font-semibold'>관리자(Admin) 설정</div>
                {
                    clubNJiyeok?.map((each : JiyeokNClubDto) => {
                        // console.log("each >> ", each);
                        return (
                            <div className='flex flex-col w-236'>
                                <div className='text-lg mb-3 mt-3 font-semibold'>{each.jiyeokName}</div>
                                {
                                    each.clubsNadmin?.map(club =>{
                                        return <div className='border p-1 pl-1.5  mt-1 mb-1 flex flex-row items-center'>
                                            <span className='text-sm w-40'>{club.clubName}</span>
                                            <input id='mId' disabled={true} className='input p-1 w-22 border-2 text-sm' type='text' onChange={(ev) => {hanldeInputValue(ev, "mId")}} defaultValue='' />
                                            <input id='pass' disabled={true} className='input p-1 w-22 border-2 ml-4 text-sm' type='text' onChange={(ev) => {hanldeInputValue(ev, "pass")}} defaultValue='' />
                                            <input id='mobileNum' className='input p-1 w-108 border-2 ml-4 mr-2 text-sm' 
                                            type='text' placeholder='쉼표(,)로 구분해서 전화번호입력 ex) 01012345678, 01011223344' onChange={(ev) => {hanldeInputValue(ev, "adminMobile")}} defaultValue={getArrayToCommaString(club.mobileArr)}/>
                                            <BasicBlueButton width='w-16' height='h-7' valueToPass={club.clubId} handleClick={applyingAdminSetting} btnText='적용' />
                                        </div>
                                    })
                                }
                            </div>
                        )
            
                    })
                }

                <div className='h-6'></div>
            </div>
        </div>)
}

export default AdminSetting;

