import React from 'react';
import './aboutLions.css';
import Melvin from '../../images/melvin_jones.png';
import backgroundLogo from '../../images/lionsSymbol.png';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';

interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutInitiator: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();
    const textUI = (text1: string, text2: string) => {
        return (
            <div className="init-text-wrapper">
                <span className="init-text1">{text1}</span>
                <span className="init-text2">{text2}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-screen align-middle">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="창설자 - 멜빈존스" />
            </div>
            <div className="flex flex-col items-center">
                <div className="wrapper-init-back-logo">
                    <img src={backgroundLogo} className="init-back-logo" />
                </div>
                <div className="flex flex-col items-center mb-16 mt-14">
                    <div className="w-100w h-96 items-center flex flex-col bg-lions-black">
                        <span className="melvin-jones">Melvin Jones</span>
                        <img src={Melvin} className="w-54 h-54 rounded-full mt-4 z-10" />
                    </div>

                    <div id="bottom" className="flex flex-col">
                        {textUI("자유", "Liberty")}
                        {textUI("지성", "Intelligence")}
                        {textUI("우리 국가의 안전", "Out Nation's Safety")}
                    </div>
                    <div className="mb-8"></div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}

export default AboutInitiator;
