import React, {useContext, useEffect} from 'react';
import './aboutLions.css';
import backgroundLogo from '../../images/lionsSymbol.png';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import ExecutiveFooter from '../common/executiveFooter';
import { saveAs } from 'file-saver';
import axios from 'axios';
import comm from '../../common';
import GroupContext from '../../context/groupContext';

interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutLions: React.FC<AboutLionsProps> = (props) => {
    const history : any = useHistory();
    const groupValue = useContext(GroupContext);
     console.log("history.location.state.id >> ", groupValue.jiguId);
    useEffect(()=>{
            window.ReactNativeWebView?.postMessage("normalScreen");
            console.log("normalScreen >> ", "normalScreen");
    },[])
    const goToLionsPurpose = () => {
        history.push("/aboutPurpose");
    };

    const goToLionsEthics = () => {
        history.push("/aboutEthics");
    };

    const goToLionsSong = () => {
        history.push("/aboutSong");
    };

    const goToLionsCeremony = () => {
        history.push("/aboutCeremony");
    };

    const goToLionsSlogan = () => {
        history.push("/aboutSlogan");
    };

    const goToLionsInitiator = () => {
        history.push("/aboutInitiator");
    };

    const goToLionsChairman = () => {
        history.push("/aboutChairman");
    };

    const getLionsDocuments = async (fileName:string) => {
        let result = await axios.get(comm.SERVER_URL+"/common/docDown/"+fileName);
        console.log("filedownload >> ", result.data);
        saveAs(result.data.url, result.data.fileName);
    };

    const goToSeeDocImg = async (title:string) => {
        // let result = await axios.get(comm.SERVER_URL+"/common/docDown/"+fileName);
        // console.log("filedownload >> ", result.data);
        // saveAs(result.data.url, result.data.fileName);
        history.push("/aboutDocs", {title:title})
    };

    const downMeetingDoc = async () => {
  
    };

    return (
        <div className="bg-lions-black">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title="라이온스 클럽" />
            </div>
            <div className="flex flex-col justify-center items-center h-178h mb-14 mt-14">
                <div className="backgroundLions">
                    <img src={backgroundLogo} className="lionsImg" />
                </div>
                <div className="each-btn mt-5" onClick={goToLionsPurpose}>
                    <span className="btn-text">라이온스의 목적</span>
                </div>
                <div className="each-btn" onClick={goToLionsEthics}>
                    <span className="btn-text">라이온스 윤리강령</span>
                </div>
                <div className="each-btn" onClick={goToLionsSong}>
                    <span className="btn-text">라이온스 노래</span>
                </div>
                <div className="each-btn" onClick={goToLionsCeremony}>
                    <span className="btn-text">라이온스 의전</span>
                </div>
                <div className="each-btn" onClick={goToLionsSlogan}>
                    <span className="btn-text">라이온스 사자후</span>
                </div>
                <div className="each-btn" onClick={goToLionsInitiator}>
                    <span className="btn-text">창설자-멜빈 존스 </span>
                </div>
                <div className="each-btn" onClick={goToLionsChairman}>
                    <span className="btn-text">국제라이온스협회 회장</span>
                </div>
                {groupValue.jiguId == 3 ?<div>
                    <div className="each-btn" onClick={(ev) => {goToSeeDocImg('회의록')}}>
                        <span className="btn-text">라이온스 회의록</span>
                    </div>
                    <div className="each-btn" onClick={(ev) => {goToSeeDocImg('입회원서')}}>
                        <span className="btn-text">라이온스 입회원서</span>
                    </div>
                    <div className="each-btn" onClick={(ev) => {goToSeeDocImg('추천서')}}>
                        <span className="btn-text">지구임원 추천,승낙서</span>
                    </div>
                    <div className="each-btn" onClick={(ev) => {goToSeeDocImg('임원명단')}}>
                        <span className="btn-text">클럽 임원명단 양식</span>
                    </div>
                    <div className="each-btn" onClick={(ev) => {goToSeeDocImg('지구수첩')}}>
                        <span className="btn-text">지구 수첩 양식</span>
                    </div></div> : <div className='h-32'></div>}

                <div className="bottom">
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                {history.location.state.id == 3 ? <ExecutiveFooter /> :<MobileFooter />}
            </div>
        </div>
    )
}


export default AboutLions;
