import React, { useState, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import { userNclub } from './jiguMemberList';
import GroupContext from '../../context/groupContext';

import ImgCall from '../../images/icon-call.png';
import NameField from '../../images/nameField.png';
import { getMobilePhoneFormText } from '../common/util';

interface JiyeokProps {
    belongTo?: number,    //Jiyeok-Id
    club?: string,
    id: number,
}

const LClubMemberList: React.FC<JiyeokProps> = (props: JiyeokProps) => {
    const [lclubMemberList, setLclubMemberList] = useState<Array<userNclub>>([]);
    const history = useHistory();
    const groupInfo = useContext(GroupContext);
    const [jidaeInfo, setJidaeInfo] = useState([]);

    useEffect(() => {
        getLClubMemberList(groupInfo.jiyeokId);
        getJidaeInfo(groupInfo.jiyeokId);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
        
    }, []);

    const getLClubMemberList = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/member/lclubmember", {
            params: {
                id: jiyeokId
            }
        }).then(res => {
            setLclubMemberList(res.data);
        })
    };

    const getJidaeInfo = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/group/jidae", {
            params: {
                id: jiyeokId
            }
        }).then(response => {
            setJidaeInfo(response.data);
        });
    }

    const changeNumPositionToName = (position: number) => {
        let positionName = "";
        if(position == 1){
            positionName = "회장";
        }else if(position == 2){
            positionName = "총무";
        }else if(position == 3){
            positionName = "부회장";
        }else if(position == 4){
            positionName = "감사";
        }else if(position == 5){
            positionName = "고문";
        }else if(position == 6){
            positionName = "회원";
        }else if(position == 7){
            positionName = "준회원";
        }
        return positionName;
    } 

    const changeIdToName = (id:number) => {
        let resultVal = "";
        jidaeInfo.forEach((each:any) => {
            if(each.id === id){
                resultVal = each.name;
                return;
            }
        })
        return resultVal;
    }

    // const handleCall = (phoneNum: string | undefined) => {
    //     console.log(" phoneNum >> ", phoneNum);
    //     // window.ReactNativeWebView?.postMessage(phoneNum);
    //     window.flutter_inappwebview?.postMessage(phoneNum);
    // };

    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }
    
    const getPrimeMember = (position: number) => {
        const primeMember = lclubMemberList.filter(each => {
            return each.positionLclub === position;
        });
        if (primeMember.length === 0) {
            return (null)
        } else {
            let fChongjae = primeMember[0];
            let imgUrl = fChongjae?.imgName === null ? logo : `https://clublions.s3.ap-northeast-2.amazonaws.com/${fChongjae?.imgName}`;
            return (
                <div className="primemember-wrapper mb-4">
                    <div className="primemember-title">{changeNumPositionToName(position)}</div>
                    <div className="primemeber-box">
                        <div className="primemember-img-wrapper">
                            <img src={imgUrl} alt="" className="primemeber-img" />
                            <span className="primember-name-text">{fChongjae.name}</span>
                            <img src={NameField} className="primember-name-area" />
                        </div>
                        <div className="flex flex-col items-start">
                            {/* <span className="primemember-slogan-text">{fChongjae.slogan}</span> */}
                            <span className="primemember-info-text">{fChongjae.belongTo.name}</span>
                            <span className="primemember-info-text">{fChongjae.address}</span>
                            <div className="primemeber-tel" onClick={(ev) => { handleCall(fChongjae.mobileNum) }}>
                                <img src={ImgCall} className="primemeber-tel-img" />
                                <span className="primemeber-tel-text">{getMobilePhoneFormText(fChongjae.mobileNum)}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    const IsLclumbSubExcutives = (position:number) => {
        let result = false;
        if(position == 1 || position == 2 ){
            result = true;
        }
        return result;

    } 
    const getExcutives = () => {
        const members = lclubMemberList.filter(each => {
            // return each.positionLclub !== 1 && each.positionLclub !== 2 && (each.positionLclub !== null || each.positionLclub !== 0);
            return each.positionLclub !== null || each.positionLclub !== 0;
        });

        return (
            <div className="ex-wrap-center">
                {members.map(member => {
                    let imgUrl = member.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${member.imgName}` : logo;
                    let position = member.positionLclub ? member.positionLclub : 0; 
                    return (
                        <div className={IsLclumbSubExcutives(position) ? "w-full mt-2" : "w-full"}>
                            <div className={IsLclumbSubExcutives(position) ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">{changeNumPositionToName(position)}</span></div>
                            <div className={IsLclumbSubExcutives(position) ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                                <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                                <div className="flex flex-col ml-2">
                                    <span className="ex-name-text">{member.name}</span>
                                    <span className="ex-position-text">{member.belongTo.name + " / " + changeNumPositionToName(position)}</span>
                                    <div className="ex-tel" onClick={(ev) => { handleCall(member.mobileNum) }}>
                                        <img src={ImgCall} className="ex-tel-img" />
                                        <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title={groupInfo.jiyeokName+" 사자회"} />
            </div>
            <div className="flex-1 mt-14">
                {/* <div className="flex flex-col mt-3 items-center">
                    {getPrimeMember(1)}
                    {getPrimeMember(2)}
                </div> */}
                <div className="flex flex-col mb-16">
                    {getExcutives()}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0 z-10">
                <MobileFooter />
            </div>
        </div>
    )
}

export default LClubMemberList;
