import React from 'react';
import { BrowserRouter, Route, Link, Switch} from "react-router-dom";
import AdminSignUp from './screens/admin/signup';
import AdminLogin from './screens/admin/login';
import AdminMain from './screens/admin/main';
import AddMember from './screens/admin/addMember';
import {NotFound} from './screens/notfound';
import MemberList from './screens/mobile/memberList';
import MemberDetail from './screens/mobile/memberDetail';
import ClubList from './screens/mobile/clubList';
import JiyeokMemberList from './screens/mobile/jiyeokMemberList';
import JiguMemberList from './screens/mobile/jiguMemberList';
import NoticeList from './screens/mobile/noticeList';
import SponsorList from './screens/mobile/sponsorList';
import LClubMemberList from './screens/mobile/lclubmemberList'
import SponsorDetail from './screens/mobile/sponsorDetail';
import AboutLions from './screens/mobile/aboutLions';
import AboutPurpose from './screens/mobile/aboutPurpose';
import AboutEthics from './screens/mobile/aboutEthics';
import AboutSong from './screens/mobile/aboutSong';
import AboutSlogan from './screens/mobile/aboutSlogan';
import AboutChairman from './screens/mobile/aboutChairman';
import AboutInitiator from './screens/mobile/aboutInitiator';
// import MobileFooter from './screens/common/mobileFooter';
import TagManager from 'react-gtm-module';
import ReactGA from 'react-ga';
import JiyeokList from './screens/mobile/jiyeokList';
import AboutCeremony from './screens/mobile/aboutCeremony';
import Login from './screens/mobile/login';
import Landing from './screens/mobile/landing';
import FBLogin from './screens/admin/fbLogin';
import FbNotice from './screens/mobile/fbNotice';
import Home from './screens/admin/home';
// import AddMonthlyActivity from './screens/admin/addMonthlyActivity';
import DashBoard from './screens/admin/dashBoard';
import DashBoardDetail from './screens/admin/dashBoardDetail';
import MemberQuitRequest from './screens/admin/memberQuitRequest';
import MonthlyReportTotal from './screens/admin/monthlyReportTotal';
import SendSMSTotal from './screens/admin/sendSMSTotal';
import SmsHistory from './screens/admin/smsHistory';
import SmsHistoryDetail from './screens/admin/smsHistoryDetail';
import SmsLink from './screens/admin/smsLink';
import AdminSetting from './screens/admin/adminSetting';
import ExecutiveList from './screens/mobile/356E/executiveList';
import JiyeokExecutiveList from './screens/mobile/356E/jiyeokExecutiveList';
import JiyeokSelectScreen from './screens/mobile/356E/jiyeokSelectScreen';
import JiyeokExeSelectScreen from './screens/mobile/356E/jiyeokExeSelectScreen';
import CommonExecutivePage from './screens/mobile/356E/commonExecutivePage';
import MemberDetailCommon from './screens/common/memberDetailCommon';
import JiguClubSelectScreen from './screens/mobile/356E/jiguClubSelectScreen';
import AboutDocs from './screens/mobile/aboutDocs';
import NoticePosting from './screens/admin/noticePosting';
import NoticeListJigu from './screens/admin/noticeListJigu';
import NoticeDetail from './screens/admin/noticeDetail';
import Survey from './screens/mobile/survey';
import SurveyBoard from './screens/admin/surveyBoard';

const tagManagerArgs = {
  gtmId: 'GTM-5MKM953',
  dataLayerName: 'UserInfo'
}

function App() {
  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize('UA-225370978-1');
  return (
    <BrowserRouter>
      <Switch> 
        <Route exact path="/admin" component={AdminLogin} />
        <Route exact path="/fblogin" component={FBLogin} />
        <Route path="/adminSignup" component={AdminSignUp} />
        <Route path="/adminMain" component={AdminMain} />
        <Route path="/memberList" component={MemberList} />
        <Route path="/memberDetail" component={MemberDetail} />

        <Route path="/memberDetailCommon" component={MemberDetailCommon} />
        <Route path="/clubList/:pathInfo" component={ClubList} />
        
        <Route path="/jiguClubSelectScreen/:pathInfo" component={JiguClubSelectScreen} />

        <Route path="/executiveList/:pathInfo" component={ExecutiveList} />
        <Route path="/commonExecutivePage/" component={CommonExecutivePage} />

        <Route path="/jiyeokSelectScreen/" component={JiyeokSelectScreen} />
        <Route path="/jiyeokExeSelectScreen/" component={JiyeokExeSelectScreen} />
        <Route path="/jiyeokExecutiveList/" component={JiyeokExecutiveList} />
        <Route path="/jiyeokList" component={JiyeokList} />
        <Route path="/jiyeokMemberList" component={JiyeokMemberList} />
        <Route path="/jiguMemberList" component={JiguMemberList} />
        <Route path="/noticeList" component={NoticeList} />  
        <Route path="/aboutLions" component={AboutLions} />
        {/* <Route path="/monthlyActivity" component={AddMonthlyActivity} /> */}
        <Route path="/dashboard" component={DashBoard} />
        <Route path="/dashboardDetail" component={DashBoardDetail} />
        <Route path="/aboutPurpose" component={AboutPurpose} />  
        <Route path="/aboutEthics" component={AboutEthics} />  
        <Route path="/aboutCeremony" component={AboutCeremony} />  
        <Route path="/aboutSong" component={AboutSong} />  
        <Route path="/aboutSlogan" component={AboutSlogan} />  
        <Route path="/aboutChairman" component={AboutChairman} />  
        <Route path="/aboutInitiator" component={AboutInitiator} />  
        <Route path="/aboutDocs" component={AboutDocs} />  
        
        <Route path="/fbNotice" component={FbNotice} />  
        <Route path="/home" component={Home} />  
        <Route exact path="/" component={Home} />   

        <Route path="/memberQuitRequest" component={MemberQuitRequest} />

        <Route path="/monthReport" component={MonthlyReportTotal} />  
        <Route path="/monthReports/:clubAssignId/:userAssignId/:userPhone" component={MonthlyReportTotal} />  
        <Route path="/sponsorList" component={SponsorList} />  
        <Route path="/sponsorDetail" component={SponsorDetail} />  
        <Route path="/adminSetting" component={AdminSetting} />
        <Route path="/noticePosting" component={NoticePosting} />
        <Route path="/noticeListJigu" component={NoticeListJigu} />
        <Route path="/noticeDetail" component={NoticeDetail} />

        <Route path="/sendSMS" component={SendSMSTotal} />
        <Route path="/smsHistory" component={SmsHistory} />
        <Route path="/smsHistoryDetial" component={SmsHistoryDetail} />
        <Route path="/smsLink/:userId/:smsId" component={SmsLink} />

        <Route path="/survey/:smsId/:userId" component={Survey} />       
        <Route path="/surveyBoard" component={SurveyBoard} />

        <Route path="/lclubmemberList" component={LClubMemberList} />  
        <Route path="/login/:deviceId" component={Login} />  
        <Route path="/landing/:deviceId/:isLatestVersion/:fcmToken" component={Landing} />  
 
        {/* <Route path="/addMember" component={AddMember} /> */}
        <Route component={NotFound} />
      </Switch>
    </BrowserRouter>
    
  );
}

export default App
  