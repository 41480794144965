import React, { useRef, useState, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
import DeleteWarningSnackBar from './deleteWarningSnackBar';

interface SponsorProps {
    fire: () => void,
    memberId: number,
}

export interface Sponsor{
    owner: number,
    sponsorImgName: string,
    contact1: string,
    contact2: string,
    address: string,
    homepage: string,
    title: string,
    type: string,
}

const AddSponsor: React.FC<SponsorProps> = (sponsorProps: SponsorProps) => {
    const { register, handleSubmit, watch, errors, getValues, setValue } = useForm();
    const [sponsorImg, setSponsorImg] = useState(logo);
    const [sponsorId, setSponsorId] = useState(-1);
    // const [sponsorResult, setSponsorResult] = useState({});
    const [deleteSponsorSnackBarOpen, setDeleteSponsorSnackBarOpen] = useState(false);


    useEffect(() => {
        axios.get(comm.SERVER_URL + '/spon/bymember', {
            params: {
                id: sponsorProps.memberId
            }
        }).then(res => {
            console.log(" spon res => ", res.data);
            setSponsorId(res.data.id);
            // setSponsorResult(res.data)

            setValue("inputContact1", res.data.contact1);
            setValue("inputContact2", res.data.contact2);
            setValue("inputAddress", res.data.address);
            setValue("inputHomepage", res.data.homepage);
            setValue("inputTitle", res.data.title);
            setValue("inputIndustry", res.data.type);
            // setValue("sponsorImgName", res.data.sponsorImgName);

            if (res.data.id) {
                const imgUrl = `https://clublions.s3.ap-northeast-2.amazonaws.com/${res.data.sponsorImgName}`;
                if (res.data.sponsorImgName) {
                    setSponsorImg(imgUrl);
                } else {
                    setSponsorImg(logo);
                }
            } else {
                setSponsorImg(logo);
            }

        });
    }, [])

    const uploadProfilePic = (inputPic: Array<any>, imgName: string) => {
        const actualFile = inputPic[0];
        const formBody = new FormData();
        formBody.append("file", actualFile);
        formBody.append("imgName", imgName);

        axios.post(comm.SERVER_URL + "/member/upload", formBody).then((imgUpRes) => {
            if (imgUpRes) {
                console.log(" data.ok !! => ", imgUpRes.data.url);
            }
        })
        sponsorProps.fire();
    }

    const updateUserWithSponsorId = (sponsorId?:number) => {
        let updatedSponsorData = {
            memberId : sponsorProps.memberId,
            sponsorId: sponsorId
        }
        console.log(" updateUserWithSponsorId >> ", updateUserWithSponsorId);
        axios.put(comm.SERVER_URL + "/member/sponsor", updatedSponsorData).then(res => {
            console.log(" updateUserWithSponsorId res >>> ", res.data);
        });    
    }


    // add, modify sponsor
    const onSubmit = (data: any) => {
        const { inputPic, inputContact1, inputContact2, inputAddress, inputHomepage, inputIndustry, inputTitle } = getValues();

        let sponsorImgName = inputPic[0]?.lastModified + '_sponsor_' + inputPic[0]?.name;

        let sponsorData = {
            owner: sponsorProps.memberId,
            sponsorImgName: sponsorImgName,
            contact1: inputContact1,
            contact2: inputContact2,
            address: inputAddress,
            homepage: inputHomepage,
            title: inputTitle,
            type: inputIndustry,
        };

        if (!sponsorId) {
            //  AddMember
            axios.post(comm.SERVER_URL + "/spon", sponsorData).then((res) => {
                console.log(" add res >> ", res.data);
                if (res.data.ok) {
                    console.log(" add sponsor data to DB success !!");
                    uploadProfilePic(inputPic, sponsorImgName);
                    updateUserWithSponsorId(res.data.sponsorId);
                }
            });
        } else {
            //  updateMember
            let updatedSponsorData = {
                id: sponsorId,
                ...sponsorData
            }

            console.log(" updatedSponsorData >> ", updatedSponsorData);
            axios.put(comm.SERVER_URL + "/spon", updatedSponsorData).then((res) => {
                console.log(" update res >> ", res.data);
                if (res.data.ok) {
                    console.log(" Modify member to DB success !!");
                    uploadProfilePic(inputPic, sponsorImgName);
                    updateUserWithSponsorId(res.data.sponsorId);
                }
            });
        }


    };

    // Delete sponsor
    const deleteSponsor = (ev:any) => {
        setDeleteSponsorSnackBarOpen(true);
    }

    const fileSponsorInputStyle = {
        width: '90px'
    }

    const handleSponsorFileInputChange = (ev: any) => {
        let imageFile = ev.target.files[0];
        setSponsorImg(URL.createObjectURL(imageFile));
    }


    return (  
        <div> 
            <form onSubmit={handleSubmit(onSubmit)} className=" border-gray-800 border-2 bg-white">
                <div className="flex m-8 flex-row">
                    <div className="w-240">
                        <div className="flex flex-row items-center justify-center">
                            <span className="text-xl">협찬 정보 입력(수정)</span>
                        </div>
                        <div className="flex flex-row items-start mt-8">
                            <div className="flex flex-col w-42 w-1/2 ">
                                <span>연락처 1</span>
                                <input type="text" name="inputContact1" className="input mt-2" ref={register}  />
                            </div>
                            <div className="flex flex-col w-42 w-1/2 ml-6">
                                <span>연락처 2</span>
                                <input type="text" name="inputContact2" className="input mt-2" ref={register}  />
                            </div>
                        </div>
                        <div className="flex flex-row items-start mt-4">
                            <div className="flex flex-col w-42 w-1/2 ">
                                <span>상호명</span>
                                <input type="text" name="inputTitle" className="input mt-2" ref={register}  />
                            </div>
                            <div className="flex flex-col w-42 w-1/2 ml-6">
                                <span>업종</span>
                                <input type="text" name="inputIndustry" className="input mt-2" ref={register}  />
                            </div>
                        </div>
                        <div className="flex flex-row items-start">
                            <div className="flex flex-col">
                                <div className="flex flex-col mt-4">
                                    <span>주소</span>
                                    <input type="text" name="inputAddress" ref={register} className="input mt-2 w-120" ></input>
                                </div>
                                <div className="flex flex-col mt-4">
                                    <span>홈페이지/블로그</span>
                                    <input type="text" name="inputHomepage" ref={register} className="input mt-2 w-120" ></input>
                                </div>
                            </div>
                        </div>
                        <div className="flex flex-row mt-6">
                            <div className="flex flex-col w-1/2">
                                <span className="mb-2">협찬 이미지</span>
                                <input type="file" ref={register} name="inputPic" id="inputPic" accept="image/*" style={fileSponsorInputStyle} onChange={handleSponsorFileInputChange} />
                            </div>
                            <img src={sponsorImg} className="w-96 h-96 mt-5 ml-10" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center h-32 ml-6 mr-6">
                    <button type="submit" className="mr-8 rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-blue-200">협찬 정보(입력/수정)</button>
                    {sponsorId ? <button type="button" className="mr-8 rounded-xl border-red-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-red-200" onClick={deleteSponsor}>협찬 정보 삭제</button>:null}
                    <button type="button" className="ml-8 rounded-xl border-gray-600 border-solid border-2 border-opacity-60 w-24 h-16 hover:bg-gray-200" onClick={sponsorProps.fire}>취소</button>
                </div>
            </form>  
            <DeleteWarningSnackBar fire={()=>{setDeleteSponsorSnackBarOpen(false)}} doubleFire={()=>{sponsorProps.fire()}} afterDelete={()=>{ console.log(" ##### "); updateUserWithSponsorId();}} open={deleteSponsorSnackBarOpen} memberId={sponsorProps.memberId} idToDelete={sponsorId} message="삭제 진행 버튼을 클릭하시면 해당 회원의 협찬 정보가 삭제됩니다. 정말로 삭제 하시겠습니까?" place="bottom"/>
        </div>
    )
}

export default AddSponsor;