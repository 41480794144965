import React, { useState, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import JiguMemberList, { userNclub } from './jiguMemberList';
import GroupContext from '../../context/groupContext';

import ImgCall from '../../images/icon-call.png';
import NameField from '../../images/nameField.png';
import { getMemberImg, getMemberImgSimple, getMemberImgWithOption2, getMobilePhoneFormText } from '../common/util';
import MobileSeeSpon from '../common/mobileSeeSpon';
import { PrimeMemberUI } from '../common/primeMemberUI';
import { userDto } from './memberList';

interface JiyeokProps {
    belongTo?: number,    //Jiyeok-Id
    club?: string,
    id: number,
}

const JiyeokMemberList: React.FC<JiyeokProps> = (props: JiyeokProps) => {
    const [jiyeokMemberList, setJiyeokMemberList] = useState<Array<userNclub>>([]);
    const [jiyeokUserList, setJiyeokUserList] = useState<Array<userDto>>([]);
    const history = useHistory();
    const groupInfo = useContext(GroupContext);
    const [jidaeInfo, setJidaeInfo] = useState([]);
    const [jiyeokInfo, setJiyeokInfo] = useState({name:"", homepage:"", des:"", belongTo:-1});
    
    useEffect(() => {
        // console.log("groupInfo >>>> ", groupInfo);

        getJiyeokMemberList(groupInfo.jiyeokId);
        getJidaeInfo(groupInfo.jiyeokId);
        if(jiyeokInfo.homepage === ""){
            getJiyeokInfo(groupInfo.jiyeokId);
        }
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
        
    }, []);

    const getJiyeokMemberList = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/member/jiyeokMembers", {
            params: {
                id: jiyeokId
            }
        }).then(res => {
            setJiyeokMemberList(res.data);
            setJiyeokUserList(res.data.map((each : userNclub)  =>{
               let user = {...each, belongTo: each.belongToClub};
               return user;
            }))
        })
    };

    useEffect(()=>{
        // console.log("jiyeokMembers >>>> ", jiyeokMemberList)
    },[jiyeokMemberList])

    const getJidaeInfo = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/group/jidae", {
            params: {
                id: jiyeokId
            }
        }).then(response => {
            setJidaeInfo(response.data);
        });
    }

    const getJiyeokInfo = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/group/jiyeokById", {
            params: {
                id: jiyeokId
            }
        }).then(response => {
            setJiyeokInfo(response.data);
        });
    }

    const changeIdToName = (id:number) => {
        let resultVal = "";
        jidaeInfo.forEach((each:any) => {
            if(each.id === id){
                resultVal = each.name;
                return;
            }
        })
        return resultVal;
    }

    const goDetail = (member:userNclub) =>{
        history.push("/memberDetail", { memberId: member.id, sponsorId: member.sponId });
    }

    const getExcutives = () => {
        const members = jiyeokMemberList.filter(each => {
            return each.positionJiyeokVal !== 2 && each.positionJiyeokVal !== 1 && (each.positionJiyeok !== "" || each.positionJiyeok !== null || each.positionJiyeok !== "없음");
        });

        return (
            <div className="ex-wrap-center">
                {members.map(member => {
                    // let imgUrl = member.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${member.imgName}` : logo;
                    if(member.positionJiyeok || member.positionFreeJiyeok){
                        // let imgUrl = getMemberImgSimple(member.belongToJigu ? member.belongToJigu : 2 , member.imgName ? member.imgName : '', member.gender); 
                        let imgUrl = getMemberImgWithOption2(member, ''); 
                        return (
                            <div className={member.positionJiyeok?.includes("지대 위원장") || member.positionJiyeok?.includes("지대위원장") ? "w-full mt-2" : "w-full"} onClick={(ev) => {goDetail(member)}}>
                                <div className={member.positionJiyeok?.includes("지대 위원장") || member.positionJiyeok?.includes("지대위원장") ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">{changeIdToName(member.belongTo.belongTo)} 위원장</span></div>
                                <div className={member.positionJiyeok?.includes("지대 위원장") || member.positionJiyeok?.includes("지대위원장") ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                                    <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                                    <div className="flex flex-col ml-2">
                                        <span className="ex-name-text">{member.name}</span>
                                        <span className="ex-position-text">{member.positionJiyeok} {member.positionFreeJiyeok && member.positionJiyeok ? " / " + member.positionFreeJiyeok : member.positionFreeJiyeok ? member.positionJiyeok : ""}{" / " +member.belongTo.name}</span>
                                        <div className="ex-tel" onClick={(ev) => {handleCall(member?.mobileNum); ev.stopPropagation() }}>
                                            <img src={ImgCall} className="ex-tel-img" />
                                            <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                })}
            </div>
        )
    }

    const drawJiyeokInfo = () => {
        return( jiyeokInfo.homepage ?
            <div className='ml-4 mr-4 mt-1 pl-3 pt-1 mb-1 bg-gray-100'>
                <div><span className='text-sm'>* 홈페이지</span></div>
                <a href={jiyeokInfo.homepage} target='_blank' className='underline text-blue-600'>{jiyeokInfo.homepage}</a>
            </div>:null
        )
    }

    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                {
                    (groupInfo.jiguName && groupInfo.jiyeokName) ? <MobileHeader title={groupInfo.jiguName+"지구 "+groupInfo.jiyeokName+" 임원"} />: <MobileHeader title='지역 임원' /> 
                }
          
            </div>
            <div className="flex-1 mt-14">
                <div className="flex flex-col mt-3 items-center">
                    {PrimeMemberUI(jiyeokUserList, "지역위원장")}
                    {PrimeMemberUI(jiyeokUserList, "직전 지역위원장")}
                </div>
                <div className="flex flex-col mb-16">
                    {getExcutives()}
                    {drawJiyeokInfo()}
                </div>
            </div>
            {/* <div className="fixed bottom-16 right-4">
                    <MobileSeeSpon title="사자회" />     
            </div> */}
            <div className="footer">
                <MobileFooter />
            </div>
        </div>
    )
}

export default JiyeokMemberList;
