import React from 'react';
import './aboutLions.css';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
 
interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutEthics: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();

    const contents = [
        "자기 직업에 긍지를 가지고 근면성실하여 힘써 사회에 봉사한다.",
        "부정한 이득을 배제하고 정당한 방법으로 성공을 기도한다.",
        "남을 해하지 아니하고 자기 직무에 충실히 임한다.",
        "남을 의심하기 이전에 먼저 자기를 반성한다.",
        "우의를 돈독하게 하며 이를 이용하지 아니한다.",
        "선량한 시민으로서 자기의무를 다하며 국가민족 사회의 향상을 위하여 노력한다.",
        "불행한 사람을 위로하고 약한 사람을 도와준다.",
        "남을 비판하는데 조심하고 칭찬하는데 인색하지 아니하며, 모든 문제를 건설적인 방향으로 추진한다.",
    ];

    const ethicsUI = (idx:number, content:string) => {
        return (
            <div className="flex flex-col mt-6 ">
                <p className="purpose-number">{idx+1}</p>
                <div className="purpose-yellow-line"></div>
                <span className="purpose-text">{content}</span>
            </div>
        )
    }


    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="라이온스 윤리강령" />
            </div>
            <div className="flex flex-col mb-16 mt-16 pl-8 pr-9">
                {
                    contents.map((content, idx) => {
                        return ethicsUI(idx, content);
                    })
                }
            </div>
            <div className="h-16"></div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutEthics;
