import axios from "axios";
import { userDto } from "../mobile/memberList";
import comm from "../../common";

export const handleCall = (phoneNum: string | undefined) => {
    console.log("fffphone > "+phoneNum);
    window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
        console.log('finished');
    });
}

export const isWrapBoxExecutive = (member:userDto, positionTitlesToInclude:Array<string>) => {
    let isIncluded = false;
    positionTitlesToInclude.forEach(eachTitle => {
        if(member.positionClub == eachTitle || member.positionFreeJigu == eachTitle || member.positionFreeJiyeok == eachTitle){
            isIncluded = true;
        }
    });
    return isIncluded; 
}

export const getExecutiveMemberList = async (jiguId: number, type:string) => {
    console.log("type >> ", type);
    const result = await axios.get(comm.SERVER_URL + "/member/pureJiguMembers", {
        params: {
            id: jiguId,
            type:type,
        }
    });
    
    return result.data;
}; 