import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import { userDto } from '../mobile/memberList';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import GroupContext from '../../context/groupContext';
import ImgCall from '../../images/icon-call.png';
import NameField from '../../images/nameField.png';
import { getMemberImg, getMemberImgWithOption, getMemberImgWithOption2, getMobilePhoneFormText } from '../common/util';
import { PrimeMemberUI, PrimeMemberUIOnlyChongJae } from '../common/primeMemberUI';
import { Chongjaedan } from '../common/chongaedan';
interface JiguMemberProps {
    club: string,
    id: number,
}

export interface clubType {
    id: number;
    name: string;
    belongTo: number;
    startDay: string;
    jiguNo: string;
    gukjeNo: string;
    sponsorClub: number;
    address: string;
    tel: string;
    fax: string;
    homepage: string;
    email: string;
    slogan: string;
    clubOnly: string;
}

type clubsType = {
    jidae: string,
    clubs: Array<clubType>,
}

export interface userNclub extends Omit<userDto, 'belongTo'> {
    belongTo: clubType;
}

const JiguMemberList: React.FC<JiguMemberProps> = () => {
    const [jiguMemberList, setJiguMemberList] = useState<Array<userDto>>([]);
    
    const [chongjaeList, setChongjaeList] = useState<Array<userDto>>([]);
    const history = useHistory();
    const groupInfo = useContext(GroupContext);
    const [jidaeInfo, setJidaeInfo] = useState([]);
    const [jiguInfo, setJiguInfo] = useState({name:"", homepage:""});
    useEffect(() => {
        console.log("group Info >> ",  groupInfo);
        getChongjae(groupInfo.jiguId);

        getJiguInfo(groupInfo.jiguId);
        

        // getJidaeInfo(groupInfo.jiguId);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
    
    }, []);



    const getJiguMemberList = (jiguId: number, jiyeokId:number) => {
        axios.get(comm.SERVER_URL + "/member/jiguMembers", {
            params: {
                id: jiguId,
                jiyeokId: jiyeokId,
            }
        }).then(res => {
            let chongjaeIds = chongjaeList.map((eachChongjae:userDto) =>{
                return eachChongjae.memberNo;
            })

            let excludedJigumembers = res.data.filter((each:userDto) => {
                return !chongjaeIds.includes(each.memberNo)
            })
            
            setJiguMemberList(excludedJigumembers);
        })
    };

    // useEffect(() => {
      
    // },[chongjaeList])
    
    const getChongjae = (jiguId: number) => {
        axios.get(comm.SERVER_URL + "/member/pureJiguMembers", {
            params: {
                id: jiguId,
                type:'jibhang'
            }
        }).then(res => {
            // console.log("ResRES >>>> ", res.data);
            setChongjaeList(res.data);
            getJiguMemberList(groupInfo.jiguId, groupInfo.jiyeokId);
        })
    }; 


    const getJiguInfo = (jiguId: number) => {
        axios.get(comm.SERVER_URL + "/group/jiguById", {
            params: {
                id: jiguId
            }
        }).then(response => {
            setJiguInfo(response.data);
        });
    };

    const isWrapBoxExecutive = (positionTitle?:string, formerJiguChongjae?:string) => {
        if(formerJiguChongjae && formerJiguChongjae.length > 0){
            return true;
        }

        if((positionTitle && positionTitle.includes("부총재") && positionTitle != '직능부총재') || (positionTitle && positionTitle.includes("연수원 원장"))){
            return true;
        }else{
            return false;
        }
    }

    const getBoxExecitiveTitle = (title:string, formerChongjae:string) => {
        let result = "";
        if(title?.substring(0,3) == "지구 "){
            result = title?.substr(3);
        }else{
            // if(formerChongjae.length > 0){
            //     result = "전총재"
            // }else{
            //     result = title;
            // }
            result = formerChongjae;
        }
        return result;
    }; 

    const goDetail = (member:userDto) =>{
        history.push("/memberDetail", { memberId: member.id, sponsorId: member.sponId });
    }

    const getExcutives = () => {
        const members = jiguMemberList.filter(each => {
            return (each.positionJiguVal != 1 && each.positionJiguVal != 3 && each.positionJiguVal != 5 && each.positionJiguVal != 7 && each.positionJiguVal !=15 && each.positionJiguVal !=17 && each.positionJiguVal !=19  && each.positionJiguVal !=21) 
        });
        {/* <div className="flex flex-row mt-1 p-2"> */}
        return (
        <div className="ex-wrap-center">
            {members.map(member => {
                
                // let imgUrl = member.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${member.imgName}` : logo;
                if(member.positionJigu || member.positionFreeJigu){
                    let imgUrl = getMemberImg(member);
                    return (
                        <div className={isWrapBoxExecutive(member.positionJigu, member.formerJiguChongjae) ? "w-full mt-2" : "w-full"} onClick={(ev) => {goDetail(member)}}>
                            <div className={isWrapBoxExecutive(member.positionJigu, member.formerJiguChongjae)  ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">{getBoxExecitiveTitle(member.positionJigu ? member.positionJigu : "", member.formerJiguChongjae ? member.formerJiguChongjae:"")} </span></div>
                            <div className={isWrapBoxExecutive(member.positionJigu, member.formerJiguChongjae) ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                            <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                            <div className="flex flex-col ml-2">
                                <span className="ex-name-text">{member.name}</span>
                                <span className="ex-position-text">{member.positionJigu} {member.positionFreeJigu && member.positionJigu ? " / " + member.positionFreeJigu : member.positionFreeJigu ? member.positionFreeJigu : ""} {" / " +member.clubName}</span>
                                <div className="ex-tel" onClick={(ev) => { handleCall(member?.mobileNum); ev.stopPropagation()}}>
                                    <img src={ImgCall} className="ex-tel-img" />
                                    <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                                </div>
                            </div>
                            </div>
                        </div>
                    )
                }
            })}
        </div>
        )
    }

    const drawJiguInfo = () => {
        return(jiguInfo.homepage ?
            <div className='ml-4 mr-4 mt-1 pl-3 pt-1 mb-1 bg-gray-100'>
                <div><span className='text-sm'>* 홈페이지</span></div>
                <a href={jiguInfo.homepage} target='_blank' className='underline text-blue-600'>{jiguInfo.homepage}</a>
            </div> : null
        )
    }

    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }

    return (
        <div className="flex flex-col">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title={groupInfo.jiguName+"지구 임원"}/>
            </div>
            <div className="flex flex-col mb-16 mt-14 w-full">
                <div className="flex flex-col mt-3 items-center ">
                    <Chongjaedan chongjaeList={chongjaeList}  />
                </div>
                {getExcutives()}
                {drawJiguInfo()}
            </div>
            <div className="footer">
                <MobileFooter />
            </div>
        </div>
    )

}


export default JiguMemberList;
