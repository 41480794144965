import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import homeImg from '../../images/home_back.png';

// var encodeUrl = require('encodeurl')

function Home(props: any): any {
    const history: any = useHistory();

    return (
        <div className="flex flex-col w-full justify-center items-center">
            <div className='relative w-90 mb-12 mt-4'>
                <div className='absolute top-4 right-4 text-white' onClick={(ev) => {history.push("/admin")}}>Admin Site로 이동</div>
                <div className='absolute top-9.5/100 right-24 w-160 h-32' onClick={(ev) => {history.push("/admin")}}></div>
                <img src={homeImg}  />
            </div>            
        </div>
    )
}

export default Home;
