import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory, useLocation } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import Select from "react-select";
import { changeMonthFormat, getMonthFormat, getYearMonth, to2DArray } from '../common/util';
import { DashboardTotal, DownLoadResult } from '../common/dto';
import { SnackBar } from '../common/commonUI';
import { Button } from '@material-ui/core';
import SmsIcon from '@material-ui/icons/Sms';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import FileDownloadIcon from '@material-ui/icons/CloudDownload';
import Poll from '@material-ui/icons/Poll';

import { saveAs } from 'file-saver';
import dayjs from 'dayjs'
import AddAlert from '@material-ui/icons/AddAlert';

function DashBoard(props: any): any {
    const history: any = useHistory();
    const location: any = useLocation();
    const screenBackRef = useRef<HTMLDivElement | null>(null);

    let jigu = history.location.state.jigu;
    let authLevel = history.location.state.authLevel;
    console.log("jigu >> ", jigu);
    
    const [dashboardData, setDashboardData] = useState<DashboardTotal>();
    const [jiguName, setJiguName] = useState("");

    const [jiguMemberSum, setJiguMemberSum] = useState("");
    const [jiguActivitySum, setJiguActivitySum] = useState("");

    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [dueDate, setDueDate] = useState("");
    const [prevDueDate, setPrevDueDate] = useState("");

    const [selectedMonth, setSelectedMonth] = useState({value:0, label:'달 선택'})
    const [jiyeokNClubs, setJiyeokNClubs] = useState<Array<Array<any>>>([]);

    let today = new Date();
    const now = dayjs();
    const nowStr = now.format('YYYY-MM-DD');
    const lastDayOfMonth = now.endOf('month').format('YYYY-MM-DD');

    let thisYear = today.getFullYear()+"";
    let thisMonth = getMonthFormat(today.getMonth()+1);
    let thisYearMonth = thisYear+"-"+thisMonth;
    let yearMonth = getYearMonth();
    let returned_clubId = history.location.state.clubId;
    // let y_cor = history.location.state.y_cor;
    console.log("returned_clubId > ", returned_clubId)
    // console.log("y_cor > ", y_cor)

    const onSelectMonth = (ev:any) => {
        setSelectedMonth(ev);
    }
    
    const goTodetail = (clubName:string, clubId:number, x:number, y:number) => {
        console.log("selectedMonth >> ", selectedMonth);
        console.log("x >> ", x);
        console.log("y >> ", y);
        history.push('/dashboardDetail', {month:selectedMonth, clubName: clubName, clubId:clubId, allMonths:yearMonth, x_cor:x, y_cor:y})
    };

    const getData = async () => {
        console.log("getData !!");
        let jiguName = await axios.get(comm.SERVER_URL + "/group/jiguById",{params:{id: jigu}});
        setJiguName(jiguName.data.name);

        let dashboard = await axios.get(comm.SERVER_URL+"/member/dashboardTotal/"+jigu);
        console.log("dashboard >>> ", dashboard);
        setDashboardData(dashboard.data);
        
        let monthTofind ="";
        if(selectedMonth.value == 0){ 
            monthTofind = thisYearMonth;
        }else{
            monthTofind = changeMonthFormat(selectedMonth.label);
        }

        console.log("monthToFind >> ", monthTofind);

        let dashBoardTotal = await axios.get(comm.SERVER_URL+"/group/dashboardmain/"+jigu+"/"+monthTofind);
        // console.log(" dashBoardTotal >> ", dashBoardTotal);
        let twoDarray = to2DArray(dashBoardTotal.data.jiyeokData, 3);
    
        setJiguMemberSum(dashBoardTotal.data.jiguMemberSum);
        setJiguActivitySum(dashBoardTotal.data.jiguActivitySum);
        setJiyeokNClubs(twoDarray);
        
        if (location.state && location.state.from === 'dashboardDetail') {
            console.log(" from dashBoardDetail !!");
            screenBackRef.current?.scrollIntoView({ behavior: 'smooth'});
        }
        
    };
    
    const getRecentDueDate = async (thisMonth:string) => {
        const recentDueDate = await axios.get(comm.SERVER_URL+"/monthReport/recentDueDate/"+thisMonth);
        console.log("recentDueDate >> ", recentDueDate.data);
        setDueDate(recentDueDate.data.result);
    }

    useEffect(()=>{
        console.log(" >>>>>>>>>>>>> ", location.state.from);
        if (location.state && location.state.from === 'dashboardDetail') {
            console.log(" from dashBoardDetail !!");
            setSelectedMonth(yearMonth[0]);

        }else{
            console.log(" yearMonth >>>>>> ", yearMonth[0])
            setSelectedMonth(yearMonth[0]);
            getRecentDueDate(thisYearMonth);
        }
    },[]);

    useEffect(() => {
        getData();

    },[selectedMonth]);
  
    const setReportingDueDate = (ev:any) => {
        setPrevDueDate(dueDate);
        setDueDate(ev.target.value);
        setSnackBarOpen(true)
    }

    const goToSmsTotal = () => {
        history.push('/sendSMS',{jiguId:jigu});
    };
    
    const goToAdminSetting = () => {
        console.log(" jigu !! >> ", jigu);
        history.push('/adminSetting', {jiguId:jigu})
    }

    const downLoadFile = async () => {
        let result = await axios.get(comm.SERVER_URL+"/common/makeDashboardReport/"+changeMonthFormat(selectedMonth.label)+"/"+jigu);
        console.log("filedownload >> ", result.data);
        saveAs(result.data.url, result.data.fileName);
    }

    const downLoadMemberFile = async () => {
        console.log("downloadMembers !");
        if (jigu !== 0 && jigu != null) {
          let result = await axios.get(comm.SERVER_URL+"/member/memberFileDownloadsByJigu/"+jigu);
          saveAs(result.data.url, result.data.fileName);
        }else if(jigu == 0 || jigu == null || jigu == undefined) {
          alert('지구를 먼저 선택해 주세요');
        }
    }

    const moveToNoticePosting = async () => {
        history.push('/noticePosting', {jigu:jigu, authLevel:authLevel});
    }

    const moveToSurveyBoard = () => {
        history.push('/surveyBoard', {jigu:jigu});
    }
    return (
        <div className="flex flex-col w-full justify-center ml-5 mr-5">
            <div className='flex flex-row w-full items-center mt-8'>
                <div className='text-lg lg:text-xl xl:text-2xl font-bold'>{jiguName} 지구 현황 ({selectedMonth ? selectedMonth.label.split(".")[1] : thisMonth}월) </div>
                <span className='ml-3 lg:ml-9 mr-1 lg:mr-2 xl:mr-4 text-sm xl:text-base'>전월대비 회원:{parseInt(jiguMemberSum) > 0 ? "+" + jiguMemberSum : jiguMemberSum}</span>
                <span className='ml-3 lg:ml-9 mr-4 lg:mr-9 xl:mr-14 text-sm xl:text-base'>전월대비 활동:{parseInt(jiguActivitySum) > 0 ? "+" + jiguActivitySum : jiguActivitySum}</span>
                <Select
                    value={selectedMonth}
                    className='w-28 ml-2 xl:ml-4'
                    onChange={onSelectMonth}
                    options={yearMonth}
                />
                <div className='w-3 lg:w-4'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SmsIcon />}
                    onClick={goToSmsTotal}
                    className="h-10 w-32 text-sm xl:text-base"
                >
                    문자발송
                </Button>
                <div className='w-3 lg:w-4'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<SupervisorAccountIcon />}
                    onClick={goToAdminSetting}
                    className="h-10 w-36 text-sm xl:text-base "
                >
                    관리자 설정
                </Button>
                <div className='w-3 lg:w-4'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                    onClick={downLoadFile}
                    className="h-10 w-36 text-sm xl:text-base xl:w-36"
                >
                    월간보고파일
                </Button>
                <div className='w-3 lg:w-4'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<FileDownloadIcon />}
                    onClick={downLoadMemberFile}
                    className="h-10 w-32 text-sm xl:text-base xl:w-32"
                >
                    회원파일
                </Button>
                <div className='w-3 lg:w-4'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<AddAlert />}
                    onClick={moveToNoticePosting}
                    className="h-10 w-32 text-sm xl:text-base xl:w-32"
                >
                    공지등록
                </Button>
            </div>
            <div className='flex flex-row items-center mt-5'>
                <div className='flex flex-col lg:p-4 p-2 bg-gray-300 w-108 lg:w-160'>
                    <div className='flex flex-row items-center'>
                        <span className='text-sm lg:text-base font-bold'>현재 회원 : </span>
                        <span className='text-xs lg:text-sm ml-2 xl:ml-6'>회원수:{dashboardData?.totalMemberCount}</span>
                        <span className='text-xs lg:text-sm ml-2 xl:ml-6'>남성회원:{dashboardData?.maleMemberCount}</span>
                        <span className='text-xs lg:text-sm ml-2 xl:ml-6'>여성회원:{dashboardData?.femaleMemberCount}</span>
                        <span className='text-xs lg:text-sm ml-2 xl:ml-6'>가족회원:{dashboardData?.familyMemberCount}</span>
                    </div>
                </div>
                <div className='flex flex-col justify-center ml-4'>
                    <span className='text-sm font-semibold lg:text-base'>{selectedMonth.label} 월간 보고 마감일</span>
                    <div className='flex flex-row'>
                        {/* <span className='text-sm'>마감일 설정: </span><input type='date' min={nowStr} max={lastDayOfMonth} className='w-30 h-5 ml-1 lg:ml-4 lg:w-32' value={dueDate} onChange={(ev) => {setReportingDueDate(ev);}}/> */}
                        <span className='text-sm'>마감일 설정: </span><input type='date' max={lastDayOfMonth} className='w-30 h-5 ml-1 lg:ml-4 lg:w-32' value={dueDate} onChange={(ev) => {setReportingDueDate(ev);}}/>
                    </div>
                </div>
                <div className='w-8'> </div>
                <Button
                    variant="contained"
                    color="primary"
                    startIcon={<Poll />}
                    onClick={moveToSurveyBoard}
                    className="h-10 text-sm xl:text-base"
                >
                    설문(투표) 현황
                </Button>
            </div>

            <div className='mt-4 text-lg'>
                지역, 클럽 활동 내역
            </div>
            <div className='ml-4'>
                {
                    jiyeokNClubs.map(oneDarray => {
                        // console.log(" oneDarray >> ", oneDarray); 
                        return (<div className='flex flex-row mt-4'>
                            {oneDarray.map((eachRowItem:any) => {
                                return (<div className='flex flex-col mr-6'>
                                    <div className='flex flex-row items-center'>
                                        <div className='flex flex-row justify-center items-center h-8 w-20 '>
                                            <span className='text-xl'>{eachRowItem.jiyeokName}</span>
                                        </div>
                                        <div className='w-16 ml-12'>회원:{eachRowItem.memberSum > 0 ? "+"+eachRowItem.memberSum : eachRowItem.memberSum} </div>
                                        <div className='w-16 ml-6'>활동:{eachRowItem.activitySum > 0 ? "+"+eachRowItem.activitySum : eachRowItem.activitySum} </div>
                                    </div>

                                    {
                                        eachRowItem.clubs.map((eachClub:any) => {
                                            let borderClass = eachClub.isReportFinish ? "flex flex-row w-136 items-center h-12 border-2 mb-1 justify-center hover:bg-green-100 border-green-400" : "flex flex-row w-136 items-center h-12 border mb-1 justify-center hover:bg-gray-300 border-gray-400";
                                            return(<div className='mt-2' >
                                                <div className={borderClass}
                                                onClick={(ev) => {goTodetail(eachClub.clubName, eachClub.clubId, ev.clientX, ev.clientY)}} ref={eachClub.clubId == returned_clubId ? screenBackRef:null}>
                                                    <span className={eachClub.isReportFinish ? 'text-green-500 font-semibold w-44' : 'w-44' }>{eachClub.clubName}</span>
                                                    <div className='h-8 w-16 ml-4 text-black flex items-center justify-center text-sm'>{eachClub.clubType ? eachClub.clubType.replace("클럽","") : "" }</div>
                                                    <div className='text-sm ml-2 w-14'>회원 {eachClub.reportedMemberCnt}</div>
                                                    {/* <div className='text-sm ml-4 w-12'>입회 {eachClub.in}</div>
                                                    <div className='text-sm ml-2 w-12'>퇴회 {eachClub.out}</div> */}
                                                    <div className='text-sm ml-4 w-11'>활동 {eachClub.activityCnt}</div>
                                                    <div className='h-8 w-16 ml-4 flex items-center justify-center text-sm'><span className={eachClub.isReportFinish ? "text-green-500 font-semibold" : "text-red-800 font-semibold"}>{eachClub.isReportFinish ? "완료" : "미완료" }</span></div>
                                                </div>
                                            </div>)
                                        })
                                    }
                                </div>)
                            })}
                        </div>)
                    })
                }
            </div>
            {SnackBar({snackBarMessage: dueDate+"로 마감일을 설정합니다.", snackBarOpen:snackBarOpen, handleCose:()=>{setSnackBarOpen(false); setDueDate(prevDueDate);}, handleSanckBarConfirm(param) {
                axios.get(comm.SERVER_URL+"/monthReport/addReport/"+dueDate+"/"+jigu).then(res => {setSnackBarOpen(false)});
            }, })}
        </div>
    )
}

export default DashBoard;
