import React, { useState, useEffect } from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import comm from '../../common';
import axios from 'axios';
import Button from '@material-ui/core/Button';

type placeType = 'top' | 'bottom'

interface WarningSnackBarProps {
    fire: () => void,
    doubleFire:() => void,
    afterDelete?:()=> void,
    open: boolean,
    memberId?:number,
    idToDelete: number,
    message:string,
    place: placeType,
} 

const DeleteWarningSnackBar = (snackBarProps: WarningSnackBarProps) => {
    const [snackBar, setSnackBar] = useState(false);    
    useEffect(() => {
        setSnackBar(snackBarProps.open)
    },[snackBarProps.open])

    const handleDeleteSponsor = () => {
        let updatedSponsorData = {
            memberId : snackBarProps.memberId,
            sponsorId: null
        }
        console.log(" updatedSponsorData >>>>> ", updatedSponsorData);
        axios.put(comm.SERVER_URL+"/member/sponsor", updatedSponsorData).then(resUpdate =>{
            console.log(" resUpdate >>>>> ", resUpdate.data);
            axios.delete(comm.SERVER_URL + "/spon", {
                params: {
                    id: snackBarProps.idToDelete  
                }
            }).then(res => {
                setSnackBar(false);
                snackBarProps.fire();
                snackBarProps.doubleFire();
                if(snackBarProps.afterDelete){
                    snackBarProps.afterDelete();
                }
                
            });
        });

    };

    const handleClose = () => {
        setSnackBar(false);
        snackBarProps.fire();

    };

    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: snackBarProps.place,
                    horizontal: 'center',
                }}
                style={{ backgroundColor: '#000000' }}
                open={snackBar}
                //   autoHideDuration={6000}
                onClose={handleClose}
                message={snackBarProps.message}
                action={
                    <div className="flex flex-row justify-start items-center">
                        <Button color="secondary" variant="contained" size="medium" onClick={handleDeleteSponsor}>
                            삭제 진행
                        </Button>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={handleClose}
                        >
                        <CloseIcon />
                        </IconButton>  
                    </div>
                }
            />
        </div>
    );
};

export default DeleteWarningSnackBar;
