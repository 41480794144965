import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import comm from '../../common';
import { Link, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import arrowDown from '../../images/messageArrowDown.png';
import arrowRight from '../../images/messageArrowRight.png';
import GroupContext from '../../context/groupContext';


interface NoticeProps {
    club: string,
    id: number,
}

export interface noticeType {
    id: number;
    updatedAt: string;
    title: string;
    content: string;
    writer?: string;
}

type noticeListType = {
    jidae: string,
    clubs: Array<noticeType>,
}

const NoticeList: React.FC<NoticeProps> = (props) => {
    const [noticeList, setNoticeList] = useState<Array<noticeType>>([]);
    const [showContent, setShowContent] = useState<number>(-1);
    const history = useHistory();
    const groupValue = useContext(GroupContext);

    useEffect(() => {
        getNoticeList(groupValue.clubId);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
    }, []);

    const getNoticeList = (clubId: number) => {
        axios.get(comm.SERVER_URL + "/notice/", {
            params: {
                id: clubId
            }
        }).then(res => {
            setNoticeList(res.data);
        })
    };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-1">
                <MobileHeader title="알림" />
                <div className="flex flex-col mt-3">
                    {noticeList.map(notice => {
                        return (
                            <div className="mt-1">
                                <div className="notice-close" onClick={(ev: any) => {
                                    if (notice.id === showContent) {
                                        setShowContent(-1);
                                    } else {
                                        setShowContent(notice.id);
                                    }
                                }}>

                                    <div className="flex flex-col pl-4">
                                        <span className="notice-day">{notice?.updatedAt.substr(0, 10).replace(/-/gi, '.')}</span>
                                        <span className="notice-title">{notice?.title}</span>
                                    </div>
                                    <div className="flex-1"></div>
                                    <img src={showContent === notice.id ? arrowDown : arrowRight} className="notice-arrow-img"></img>

                                </div>
                                {showContent === notice.id ? <div className="notice-content-wrapper"><div className="notice-content-text">{notice.content}</div></div> : null}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default NoticeList;
