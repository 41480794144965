import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../../common';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import GroupContext from '../../../context/groupContext';
import { to2DArray } from '../../common/util';
import { useHistory } from 'react-router-dom';
import ExecutiveFooter from '../../common/executiveFooter';
import { pageProps } from './common356E';
import JiguExe from '../../../images/jigu_exe.png'

interface ExecutiveType {
    id:number;
    name:string;
    keyword:string;
}

const JiyeokExeSelectScreen = (props : pageProps) => {
    // const [exeTypes, setExetypes] = useState<Array<ExecutiveType>>([]);
    const groupValue = useContext(GroupContext);
    let history = useHistory();
    const execTypes : Array<ExecutiveType> = [
        {id:16, name:"총재협의회", keyword:'chongjaeexe' },
        {id:17, name:"총장협의회", keyword:'chongjangexe'},
        {id:18, name:"감사", keyword:'gamsa'},
        {id:39, name:"상임고문", keyword:'higomun'},
        {id:42, name:"고문", keyword:'gomun'},
        {id:60, name:"교육연수원", keyword:'edu'},
        {id:68, name:"인사위원회", keyword:'insa'},
        {id:25, name:"총재특보", keyword:'chongjaespecialbo'},
        {id:44, name:"총재특별자문위원", keyword:'chongjaespecial'},
        {id:45, name:"자문위원", keyword:'jamun'},
        {id:101, name:"지역위원장", keyword:'jiyeokwi'},
        {id:112, name:"지역특보", keyword:'localspecial'},
        {id:75, name:"직능부총재", keyword:'jikneung'},
        {id:79, name:"특별위원장", keyword:'jiyeokspecial'},
        {id:54, name:"부총장", keyword:'buchongjang'},
        {id:103, name:"지대위원장", keyword:'jidaewi'},
        {id:57, name:"분과위원장", keyword:'bungwa'},
        {id:200, name:"지역임원 및 3역", keyword:'clubexe'},
    ]; 
    
    useEffect(() => {
        
    }, [])


    const goJiyeok = (id:number, name:string, keyword:string) => {
        if(id == 200){
            history.push('/jiyeokSelectScreen');
        }else{
            history.push('/commonExecutivePage',{jiguId:groupValue.jiguId,  title:name, keyword:keyword});
        }
    }

    return(
        <div className='flex flex-col items-center mt-12'>
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title="지구임원"/>
            </div>
            {/* <span className='text-xl mb-8 font-bold'>항목을 선택하세요</span> */}
            <div className='flex flex-col items-center mb-18'>
            {
                to2DArray(execTypes, 2).map((oneArray: Array<ExecutiveType>) => {
                    return (<div className='flex flex-row justify-center mt-5 items-center'>
                        {oneArray.map((each:ExecutiveType) => {
                            return (<div className='jiyeok-exe-img' onClick={(ev) => {goJiyeok(each.id, each.name, each.keyword)}}>
                                <img src={JiguExe} className='w-5 h-5 mr-6'></img>
                                <span className={each.name.length > 6 ?  'text-sm' : 'text-base'}>{each?.name}</span>
                            </div>)
                        })}
                    </div>)
                })
            }
            </div>
            <div className="footer">
                <ExecutiveFooter position={props.footerPosition} />
            </div>
        </div> 
    )
}

export default JiyeokExeSelectScreen;
