import { MouseEvent, useContext } from 'react';
import ImgCall from '../../images/icon-call.png';
import NameField from '../../images/nameField.png';
import { getMemberImg, getMemberImgWithOption, getMobilePhoneFormText } from './util';
import GroupContext from '../../context/groupContext';
import { useHistory } from 'react-router-dom';
import { userDto } from '../mobile/memberList';
import { handleCall, isWrapBoxExecutive } from './listUtils';
import { userNclub } from '../mobile/jiguMemberList';

const CommonExecutiveList = (members:Array<userDto>, boxMembersTitle:Array<string>, header_title:string) => {
    console.log("header_title >> ", header_title);
    let history = useHistory();
    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }
    
    const getPositionTitle = (member:userDto) =>{
        console.log("member >>> ", member);
        let position_title = "";
        if(member.positionJigu){
            position_title = member.positionJigu;
        }else if(member.positionJiyeok){
            position_title = member.positionJiyeok;  
        }
        
        if(member.formerJiguChongjae && header_title=='총재협의회'){
            position_title = member.formerJiguChongjae;
        }
        
        if(member.formerJiguChongjang && header_title=='총장협의회'){
            position_title = member.formerJiguChongjang;
        }
        
        if(member.formerEdu && header_title=='교육연수원'){
            position_title = member.formerEdu;
        }
        

        if(header_title == '인사위원회'){
            position_title = member.formerInsa? member.formerInsa : "인사위원회";
        }
        return position_title;
    }

    let changeIndex = -1;
    return (
    <div className="ex-wrap-center">
         {members.map((member,index) => {
            let imgUrl = getMemberImg(member);
            let lengthRow = 0;
            if(member.positionJigu && member.clubName){
                lengthRow = member.positionJigu?.length + member.clubName?.length;
            }
            return (
                <div className={isWrapBoxExecutive(member, boxMembersTitle) ? "w-full mt-2" : "w-full"} onClick={(ev) => {goDetail(ev, member)}}>
                    <div className={isWrapBoxExecutive(member, boxMembersTitle) ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                    <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                    <div className="flex flex-col ml-2">
                        <span className="ex-name-text">{member.name}</span>
                        <div className='flex flex-row'>
                            <span className={ lengthRow > 16 ? "ex-position-text-overflow" : "ex-position-text" }>{getPositionTitle(member)} / </span>
                            <span className={ lengthRow > 16 ? "ex-position-text-overflow ml-1" : "ex-position-text ml-1" }>{member.clubName}</span>
                        </div>
                        <div className="ex-tel" onClick={(ev) => { handleCall(member?.mobileNum); ev.stopPropagation() }} >
                            <img src={ImgCall} className="ex-tel-img" />
                            <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                        </div>
                    </div>
                    </div>
                </div>
            )
        })}
    </div>
    )
}

export default CommonExecutiveList