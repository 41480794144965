import React from 'react';
import { useHistory } from "react-router-dom";
import './common.css'; 
import arrowImg from '../../images/backImg.png';

interface MobileHeaderProps {
    title?: string,
    clubOnly?: boolean,
};

const MobileHeader: React.FC<MobileHeaderProps> = (props: MobileHeaderProps) => {
    let history = useHistory();
    const handleGoBack = () => {
        console.log("go Back !!");
        history.goBack();
    };
    
    // console.log("props.clubonly >> ", props.clubOnly);

    return(<div className="flex flex-row justify-items-center items-center bg-lions-blue">
        <div className={props.clubOnly ? "invisible ml-8":"back-btn"} onClick={handleGoBack}>
            <img src={arrowImg} className="arrow-img" />
        </div>
        <div className="flex-1"></div>
        <span className="header-text">{props.title}</span>
        <div className="flex-1"></div>
        <div className="back-btn invisible"></div>
    </div>);
}

export default MobileHeader;