import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
// import { Location } from 'history';
import { useLocation } from 'react-router-dom';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import AddToPhotos from '@material-ui/icons/AddToPhotos';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import Send from '@material-ui/icons/Send';
import Cancel from '@material-ui/icons/Cancel';

interface memberType {
    id?: number,
    positionClub?: string,
    name?: string,
    mobileNum?: string,
    imgUrl?: string,
}

interface ChildProps {
    fire: () => void,
    club: number,
}

const SendAppInstallSMS: React.FC<ChildProps> = (props: ChildProps) => {
    const { register, handleSubmit, getValues, setValue } = useForm();
    // const location = useLocation<Location>();
    console.log(" props => ", props);
    const [memberList, setMemberList] = useState<Array<memberType>>([]);
    const [checkedMemberList, setCheckedMemberList] = useState<Array<memberType>>([]);
    const [snackBarOpen, setSnackBarOpen] = useState(false);
    const [checkedArr, setCheckedArr] = useState<Array<number>>([]);

    useEffect(() => {
        // props.memberId
        console.log(" props => ", props.club);
        axios.get(comm.SERVER_URL + "/member/club", {
            params: { id: props.club }
        }).then(res => {
            console.log(" res >> ", res.data);
            let resultData = res.data;
            let finalArr = resultData.map((data: { id: number; name: string; mobileNum: string; positionClub: string; imgName: string; }) => {
                return { id: data.id, name: data.name, mobileNum: data.mobileNum, positionClub: data.positionClub, imgUrl: data.imgName }
            })
            setMemberList(finalArr);
        });
    }, []);

    const SendingSMSSnackBar = () => {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ backgroundColor: '#000000' }}
                    open={snackBarOpen}
                    autoHideDuration={2000}
                    message="앱 설치 안내 문자를 전송 중입니다..."
                />
            </div>
        );
    };

    const sendSMS = () => {
        console.log("sendSMS >>>> ", checkedMemberList);
        setSnackBarOpen(true);
        try{
            axios.post(comm.SERVER_URL + "/member/sendingAppInstallSMS", checkedMemberList).then(res => {
                console.log("res sendSMS => ", res.data)
                props.fire();
            })
        }catch(ex){
            console.log(" ex >>> ", ex);
            setSnackBarOpen(false);
        }finally{

        }

    };

    const closeModal = () => {
        props.fire();
    }

    const handlingCheckBox = (idx: number, ev: any) => {

        console.log(" idx >>> ", idx);
        // if(idx == ev.target.value){
        //     setChecked(!checked);
        // } 
        if (checkedArr.includes(idx)) {
            let array = checkedArr.filter(val => { return val != idx })
            setCheckedArr(array);
        } else {
            setCheckedArr((checkedArr: Array<number>) => [...checkedArr, idx]);
        }

    }

    const checkAll = () => {
        console.log("checkAll!");
        
        if(checkedArr.length > 0){
            setCheckedArr([]);
        }else{   
            let arr = memberList.map((each,idx)=>{
                return idx;   
            });
            setCheckedArr(arr);
        }

    }

    useEffect(() => {
        console.log(" checkedMemberList >>", checkedMemberList);
    }, [checkedMemberList])

    useEffect(() => {
        let checkedMembers = checkedArr.map(eachIdx => {
            return memberList[eachIdx];
        });
        setCheckedMemberList(checkedMembers);
        // console.log("checkedMembers >>>>> ", checkedMembers);
    }, [checkedArr])

    return (<div className='flex flex-col items-center'>
        <div className='border-gray-800 border-2 border-b-0 bg-white h-144 w-128 overflow-auto flex flex-col items-center'>
            <div className='mt-3'>
                <p className='text-lg'>체크를 하고 전송 버튼을 클릭하면 앱설치 안내 문자를 전송합니다.</p>
                <div className='flex flex-row-reverse'><button className='bg-blue-100 border-blue-600 border-2 w-20 h-8' onClick={checkAll}>{checkedArr.length == 0 ? "전원 체크": "체크 해제"}</button></div>
            </div>
            {
                memberList?.map((member, idx) => {
                    return (<div className='flex flex-row items-center pl-5 pr-5 pb-5'>
                        <img src={`https://clublions.s3.ap-northeast-2.amazonaws.com/${member.imgUrl}`} className='w-28 h-28' />
                        <div className='flex flex-col w-60 ml-5 mr-10'>
                            <p>{member.name + "/" + member.positionClub}</p>
                            <p>{member.mobileNum}</p>
                        </div>
                        <input type='checkbox' className='w-7 h-7' checked={checkedArr.includes(idx) ? true : false} value="" onChange={(ev) => { handlingCheckBox(idx, ev) }} />
                    </div>);
                })
            }

            {SendingSMSSnackBar()}
        </div>
        <div className='border-gray-800 border-2 border-t-0 w-128 h-24 bg-white flex items-center flex-row justify-between'>
            <button
                className="mr-8 rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-36 h-12 hover:bg-blue-200 ml-16"
                onClick={sendSMS}
            ><span className='text-xl text-red-700'>전송</span></button>
            <div className='w-3'></div>
            <button
                className="rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-36 h-12 hover:bg-blue-200 mr-16"
                onClick={closeModal}
            ><span className='text-xl text-blue-700'>취소</span></button>
        </div>
    </div>)
}

export default SendAppInstallSMS;

