import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../../common';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import MobileFooter from '../../common/mobileFooter';
import GroupContext from '../../../context/groupContext';
import ImgCall from '../../../images/icon-call.png';
import NameField from '../../../images/nameField.png';
import { getMemberImg, getMemberImgWithOption, getMobilePhoneFormText } from '../../common/util';
import ExecutiveFooter from '../../common/executiveFooter';
import { clubType } from '../jiguMemberList';

interface JiyeokProps {
    title: string,
    id: number,
}

export interface ClubInfo{
    clubName:string,
    positionTitle:string,
    member:userDto
   }
   
export interface JidaeInfo{
    id:number,
    name:string,
    boss: userDto,
    club:Array<ClubInfo>
}
   
export interface JiyeokExecutive{
    jiyeok_boss: userDto,
    jidae: Array<JidaeInfo>,
}

export interface userNclub extends Omit<userDto, 'belongTo'> {
    belongTo: clubType;
}

const JiyeokExecutiveList: React.FC<JiyeokProps> = (props) => {
    const [jiyeokMembeInfo, setJiyeokMemberInfo] = useState<JiyeokExecutive>();
    const location = useLocation<JiyeokProps>();
    const history = useHistory();
    const groupValue = useContext(GroupContext);
    console.log(" props >> ", props);
    
    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }

    useEffect(() => {
        console.log("group Info >> ", groupValue);
        getJiyeokMemberList(location.state.id);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
    
    }, []);

    const getJiyeokMemberList = (jiyeokId: number) => {
        axios.get(comm.SERVER_URL + "/member/pureJiyeokMembers", {
            params: {
                id: jiyeokId,
            }
        }).then(res => {
            console.log("jiyeok res >>>> ", res.data);
            setJiyeokMemberInfo(res.data);
        })
    }; 

    const getPrimeMember = (position: string) => {
        const primeMember = jiyeokMembeInfo?.jiyeok_boss;

            // let imgUrl = fChongjae?.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${fChongjae?.imgName}` : logo ;
            let imgUrl = '';
            if(primeMember){
                imgUrl = getMemberImg(primeMember);
                return (
                    <div className="primemember-wrapper">
                        <div className="primemember-title">{position}</div>
                        <div className="primemeber-box">
                            <div className="primemember-img-wrapper" onClick={(ev) => goDetail(ev, primeMember)}>
                                <img src={imgUrl} alt="" className="primemeber-img" />
                                <span className="primember-name-text">{primeMember?.name}</span>
                                <img src={NameField} className="primember-name-area" />
                            </div>
                            <div className="flex flex-col items-start ">
                                <span className="primemember-slogan-text">{primeMember?.slogan}</span>
                                <span className="primemember-info-text">{primeMember?.clubName}</span>
                                <span className="primemember-info-text">{primeMember?.address}</span>
                                <div className="primemeber-tel" onClick={(ev) => {handleCall(primeMember?.mobileNum)}}>
                                    <img src={ImgCall} className="primemeber-tel-img" />
                                    <span className="primemeber-tel-text">{getMobilePhoneFormText(primeMember?.mobileNum ?primeMember?.mobileNum :"")}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
    }

    const isWrapBoxExecutive = (positionTitle?:string) => {

        if((positionTitle && (positionTitle.includes("지대위원장") || positionTitle.includes("지대 위원장")))){
            return true;
        }else{
            return false;
        }
    }

    const getBoxExecitiveTitle = (title:string, formerChongjae:Array<number>) => {
        let result = "";
        if(title?.substring(0,3) == "지구 "){
            result = title?.substr(3);
        }else{
            if(formerChongjae.length > 0){
                // let former = "";
                // formerChongjae.forEach((each:number, index:number) => {
                //     let gisu = each - 30;
                //     if(index != formerChongjae.length -1){
                //         former = former + gisu+"대, ";
                //     }else{
                //         former = former + gisu + "대 지구 총재";
                //     }
                // });
                // result = former;
                result = "전총재"
            }else{
                result = title;
            }
        }
        return result;
    };

    const getClubExcutives = (jidae :JidaeInfo) => {
        console.log("jidae >>>>>> ", jidae);
        return jidae.club.map((club : ClubInfo) => {
            let imgUrl = getMemberImg(club.member);
            return(<div className="flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4" onClick={(ev) => {goDetail(ev, club.member)}}>
                <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                <div className="flex flex-col ml-2">
                    {/* <span className="ex-name-text">{club.clubName}</span> */}
                    <span className="ex-name-text">{club.member.name}</span>
                    <span className="ex-position-text">{club.clubName} {club.positionTitle}</span>
                    <div className="ex-tel" onClick={(ev) => { handleCall(club.member.mobileNum) }}>
                        <img src={ImgCall} className="ex-tel-img" />
                        <span className="ex-tel-text">{getMobilePhoneFormText(club.member.mobileNum)}</span>
                    </div>
                </div>
            </div>)
        })
    }

    const getJidaeExcutives = () => {
        const jidaes = jiyeokMembeInfo?.jidae.sort((x,y)=>
            x.id - y.id
        );
        console.log("jidaes >> ", jidaes);
        return (
            <div className="ex-wrap-center">
                {jidaes?.map(jidae => {
                    let imgUrl = getMemberImg(jidae.boss);
                    return (
                        <div className='flex flex-col w-full' >
                            <div className='w-full mt-2' onClick={(ev) => {goDetail(ev, jidae.boss)}}>
                                <div className={isWrapBoxExecutive(jidae.boss.positionJiyeok) ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">{jidae.boss.positionJiyeok?.replace('지대위원장',"")+" "+"지대위원장"}</span></div>
                                <div className={isWrapBoxExecutive(jidae.boss.positionJiyeok) ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                                    <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                                    <div className="flex flex-col ml-2">
                                        <span className="ex-name-text">{jidae.boss.name}</span>
                                        <span className="ex-position-text">{jidae.boss.clubName}</span>
                                        <div className="ex-tel" onClick={(ev) => { handleCall(jidae?.boss.mobileNum); ev.stopPropagation() }}>
                                            <img src={ImgCall} className="ex-tel-img" />
                                            <span className="ex-tel-text">{getMobilePhoneFormText(jidae?.boss.mobileNum)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {getClubExcutives(jidae)}
                        </div>
                    )
                })}

            </div>)
    }


    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }

    return (
        <div className="flex flex-col">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title={location.state.title}/>
            </div>
            <div className="flex flex-col mb-16 mt-14">
                <div className="flex flex-col mt-3 items-center">
                    {getPrimeMember("지역위원장")}
                </div>
                <div className="h-2"></div>
                {getJidaeExcutives()}
            </div>
            <div className="footer">
                <ExecutiveFooter />
            </div>
        </div>
    )

}


export default JiyeokExecutiveList;
