import { Button, IconButton, Snackbar } from "@material-ui/core";
import { snackProps } from "./dto";
import CloseIcon from '@material-ui/icons/Close';
import { userDto } from "../mobile/memberList";

export const SnackBar = (props:snackProps) => {
    
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                style={{ backgroundColor: '#000000' }}
                open={props.snackBarOpen}
                autoHideDuration={6000}
                onClose={props.handleCose}
                message={props.snackBarMessage}
                action={
                    <div className="flex flex-row justify-start items-center">
                        <Button color="secondary" variant="contained" size="medium" onClick={props.handleSanckBarConfirm}>
                            확인
                        </Button>
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            onClick={props.handleCose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                }
            />
        </div>
    );
};

export const memberDetailUI = (member:userDto) =>{

}

export const BasicBlueButton = (props:any) => {
    let cls = `flex items-center justify-center rounded-md border-blue-400 border-solid border-2 bg-blue-500 ${props.width} ${props.height} ${props.margin} hover:border-blue-800 hover:text-black text-white`;
    return <button type='button' className={cls} onClick={(ev) => {props.handleClick(props.valueToPass)}}><span className={`${props.font}`}>{props.btnText}</span></button>
}

export const BasicRedButton = (props:any) => {
    let cls = `rounded-md border-red-400 border-solid border-2 bg-red-500 ${props.width} ${props.height} hover:border-red-800 hover:text-black text-white`;
    return <button type='button' className={cls} onClick={(ev) => {props.handleClick(props.valueToPass)}}>{props.btnText}</button>
}

export const Hello = (props:any) => {
    return <div>안녕하세요. {props.name}</div>
}