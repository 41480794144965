import React, { useState, useRef, useEffect, useContext } from 'react';
import axios from 'axios';
import comm from '../../common';
import { Link, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import arrowDown from '../../images/messageArrowDown.png';
import arrowRight from '../../images/messageArrowRight.png';
import GroupContext from '../../context/groupContext';

interface NoticeProps {
    club: string,
    id: number,
}

export interface fbnoticeType {
    id: number;
    updatedAt: string;
    title?: string;
    content: string;
    writer?: string;
    originalUrl?: string;
}

type noticeListType = {
    jidae: string,
    clubs: Array<fbnoticeType>,
}

const FbNotice: React.FC<NoticeProps> = (props) => {
    const [noticeList, setNoticeList] = useState<Array<fbnoticeType>>([
        {
            id:1,
            updatedAt:'2023-04-09',
            title:'경산라이온스클럽(회장 권중석)은 최근 생활이 어려운 지역 노인 부부 가정을 찾아',
            content:'경산라이온스클럽(회장 권중석)은 최근 생활이 어려운 지역 노인 부부 가정을 찾아 낡은 벽지와 장판 교체 등 집수리 재능기부 봉사활동을 실시했다.',
            originalUrl:'https://www.facebook.com/lionskoreatv',
        },{
            id:2,
            updatedAt:'2023-04-11',
            title:'주 청명라이온스클럽(전운배 회장)이 성화개신죽림동 행정복지센터를 찾아 저소득가정 치과치료비를 위한 후원금 200만원을',
            content:`청주 청명라이온스클럽(전운배 회장)이 성화개신죽림동 행정복지센터를 찾아 저소득가정 치과치료비를 위한 후원금 200만원을 지정 기탁했다.
            이날 기탁된 후원금은 치아결손으로 사회활동이 중단된 청장년 가구의 치과치료비로 쓰여 자립의 기반을 마련하는데 사용될 예정이다.
            전운배 회장은"'우리는 봉사한다'라는 모토 아래 지역주민에게 도움이 될 사회공헌 사업 추진에 노력을 기하고 있으며, 저소득 주민들이 자립할 수 있도록 작은 힘이나마 보탬이 되도록 노력하겠다"고 말했다.
            한편, 청명 라이온스클럽은 2002년 창립해 30여 명의 회원들은 20여 년간 청주지역 소외계층들을 위한 봉사 활동을 꾸준히 하고 있다.`,
            originalUrl:'https://www.facebook.com/lionskoreatv',
        },{
            id:3,
            updatedAt:'2023-04-16',
            title:'※ 인도네시아 고아원 기부 물품 구매·도서관 건립 봉사활동 비용 등에 사용',
            content:`※ 인도네시아 고아원 기부 물품 구매·도서관 건립 봉사활동 비용 등에 사용
            국제라이온스협회 355-B1지구 2021∼2022년 회장단협의회(대표회장 정창원·광주탑라이온스클럽)는 지난 27일 355-B1지구 해외봉사단(단장 박민영·광주송정라이온스클럽)에 지원금을 전달했다고 29일 밝혔다.
            355-B1지구 해외봉사단 지원금은 2021∼2022년 회장단협의회 정창원 대표회장을 비롯한 회장들이 ‘도움이 필요한 곳에 라이온이 있다’라는 마음으로 해외에 나눔 전파를 하고자 뜻을 모은 성금이다.
            기탁된 성금은 355-B1지구 해외봉사단이 인도네시아 고아원에 전달할 물품을 구입하거나 도서관 건립 봉사활동 등을 위한 비용으로 사용한다.
            정창원 대표회장은 “355-B1지구 해외봉사단의 인도네시아 봉사활동이 현지 어린들에게 보다 좋은 환경에서 큰 꿈을 꿀 수 있는 발판이 돼주길 기원한다”고 말했다.
            박민영 단장은 “2021∼2022년 회장단들이 355-B1지구 해외봉사단에 큰 관심을 갖고 뜻을 모은 것에 감사하다”며 “인도네시아 어린이들을 위해 의미있게 사용하겠다”고 밝혔다.`,
            originalUrl:'https://www.facebook.com/lionskoreatv',
        },{
            id:4,
            updatedAt:'2023-04-18',
            title:'천안시 일봉동은 엔젤라이온스클럽(회장 지성자)이 5월 12일 지역 내 홀몸노인을 위한 여름이불 24채를 기부',
            content:`천안시 일봉동은 엔젤라이온스클럽(회장 지성자)이 5월 12일 지역 내 홀몸노인을 위한 여름이불 24채를 기부했다고 밝혔다.
            이날 기부물품은 행복키움지원단, 통장 등 인적안전망을 통해 홀몸노인들이 시원한 여름을 보내실 수 있도록 24명에게 전달될 예정이다.
            지성자 회장은 “화목한 가정의 달에 소외되는 어르신들이 항상 마음에 걸렸었는데 이렇게 여름이불을 전달할 수 있는 기회가 생겨 기쁘다”고 말했다.
            서정곤 일봉동장은 “지역 내 홀몸노인 분들이 많으신데, 지역 어려운 부분을 세심하게 신경써주시고 도움을 주신 엔젤라이온스클럽 회장님과 회원 분들에게 감사하다”고 말했다.`,
            originalUrl:'https://www.facebook.com/lionskoreatv',
        }

    ]);
    const [showContent, setShowContent] = useState<number>(-1);
    const history = useHistory();
    const groupValue = useContext(GroupContext);

    // useEffect(() => {
    //     getNoticeList(groupValue.clubId);
    //     window.ReactNativeWebView?.postMessage("normalScreen");
    //     console.log("normalScreen >> ", "normalScreen");
    // }, []);

    // const getNoticeList = (clubId: number) => {
    //     axios.get(comm.SERVER_URL + "/notice/", {
    //         params: {
    //             id: clubId
    //         }
    //     }).then(res => {
    //         setNoticeList(res.data);
    //     })
    // };

    return (
        <div className="flex flex-col min-h-screen">
            <div className="flex-1">
                <MobileHeader title="공지" />
                <div className="flex flex-col mt-3">
                    {noticeList.map(notice => {
                        return (
                            <div className="mt-8">
                                <div className="notice-close" onClick={(ev: any) => {
                                    if (notice.id === showContent) {
                                        setShowContent(-1);
                                    } else {
                                        setShowContent(notice.id);
                                    }
                                }}>
                                    <div className="flex flex-col pl-4">
                                        <span className="notice-day">{notice?.updatedAt.substr(0, 10).replace(/-/gi, '.')}</span>
                                        <span className="fb-notice-title">{notice?.title}</span>
                                    </div>
                                    <div className="flex-1"></div>
                                    <img src={showContent === notice.id ? arrowDown : arrowRight} className="notice-arrow-img"></img>

                                </div>
                                {showContent === notice.id ? <div className="notice-content-wrapper"><div className="notice-content-text">{notice.content}</div></div> : null}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default FbNotice;
