import React, { useState, useEffect, useMemo } from 'react';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';
import { useTable } from 'react-table';
import { Button, IconButton, Snackbar } from '@material-ui/core';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import CloseIcon from '@material-ui/icons/Close';

interface adminType {
    id?: number;
    mId: string;
    pass?: string;
    clubId?: number;
    clubName: string;
    jiyeokId?: number;
    jiyeokName: string;
    jigu: number;
    mobile: string;
    confirmed: boolean;
}

interface updateInfo {
    id?:number;
    status?:boolean;
}

interface tableType {
    mId: string;
    clubName: string;
    jiyeokName: string;
    mobile: string;
    isConfirmed: string;
}

interface ChildProps {
    fire: () => void,
    club: number,
    jigu?: number,
}

const ManageAdmin: React.FC<ChildProps> = (props: ChildProps) => {
    // console.log(" props => ", props);
    const [adminList, setAdminList] = useState<Array<adminType>>([]);
    // const [tableList, setTableList] = useState<Array<tableType>>([]);
    const [adminToUpdate, setAdminToUpdate] = useState<updateInfo>();
    const [snackBarOpen, setSnackBarOpen] = useState(false);

    const columList = [
        { accessor: 'mId', Header: '아이디' },
        { accessor: 'mobile', Header: '핸드폰' },
        { accessor: 'clubName', Header: '클럽' },
        { accessor: 'jiyeokName', Header: '지역' },
        { accessor: 'isConfirmed', Header: 'Confirm' },
    ]

    useEffect(() => {
        console.log(" props => ", props.club);
        axios.get(comm.SERVER_URL + "/admin/allJigu/"+props.jigu).then(res => {
            let resultData = res.data.adminList;
            console.log("resultData", resultData);
            setAdminList(resultData);
        });
        console.log(" !!!!!!! ");
    }, []);

    const handleClose = () => {
        setSnackBarOpen(false);
    };

    const handleChangeConfirm = () => {
        axios.post(comm.SERVER_URL+"/admin/updateConfirm", {id:adminToUpdate?.id, isConfirmed: !adminToUpdate?.status}).then(res => {
            console.log("confirmed res >> ", res);
            if(res.data.adminId){
                axios.get(comm.SERVER_URL + "/admin/allJigu").then(res => {
                    let resultData = res.data.adminList;
                    console.log("resultData !!!! ");
                    setAdminList(resultData);
                });
            }
            setSnackBarOpen(false);  
        });
 
    }

    const chageConfirmSnackBar = () => {
        return (
            <div>
                <Snackbar
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    style={{ backgroundColor: '#000000' }}
                    open={snackBarOpen}
                    //   autoHideDuration={6000}
                    onClose={handleClose}
                    message={adminToUpdate?.status ? "회원 승인을 취소하시겠습니까?" : "회원 가입을 승인하시겠습니까?"}
                    action={
                        <div className="flex flex-row justify-start items-center">
                            <Button color="secondary" variant="contained" size="medium" onClick={handleChangeConfirm}>
                                {adminToUpdate?.status ? "승인 취소" : "승인"} 
                            </Button>
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                onClick={handleClose}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    }
                />
            </div>
        );
    };

    const closeModal = () => {
        props.fire();
    }


    const confirmAdmin = (idToUpdate?:number, status?:boolean) => {

    }; 

    const commonBtnCss = "rounded-md border-solid border-2 border-opacity-60 w-22 h-8";

    return (<div className='flex flex-col items-center'>
        <div className='border-gray-800 border-2 bg-white w-136 lg:h-192 lg:w-256 overflow-auto flex flex-col p-3'>
            <div className='mt-3 mb-3 ml-3'>
                <p className='text-lg'>* Admins</p>
            </div>
            <table>
                <thead>
                    <tr>
                        <th scope="col" className='border-2'>아이디</th>
                        <th scope="col" className='border-2'>핸드폰</th>
                        <th scope="col" className='border-2'>클럽이름</th>
                        <th scope="col" className='border-2'>지역이름</th>
                        <th scope="col" className='border-2'>가입 승인</th>
                    </tr>
                </thead>
                <tbody className='border-2'>
                    {adminList.map(eachRow => {
                        return (<tr className='h-10'>
                            <td className='border-2'>{eachRow.mId}</td>
                            <td className='border-2'>{eachRow.mobile}</td>
                            <td className='border-2'>{eachRow.clubName}</td>
                            <td className='border-2'>{eachRow.jiyeokName}</td>
                            <td className='border-2' > 
                            <button type="button" onClick={() => {
                                setAdminToUpdate({id:eachRow.id, status:eachRow.confirmed})
                                setSnackBarOpen(true);
                            }}
                            className={eachRow.confirmed ? commonBtnCss+" border-red-600" : commonBtnCss+ " border-green-600"}>{eachRow.confirmed ? "가입 취소" : "가입 승인" }</button></td>
                        </tr>)
                    })}
                </tbody>
            </table>
            {chageConfirmSnackBar()}
        </div>
    </div>)
}

export default ManageAdmin;

