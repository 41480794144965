import React, { useContext, useState } from 'react';
import logo from '../../images/lions_main.png';
import comm from '../../common';
import { TextField } from '@material-ui/core';
import { Button } from '@material-ui/core';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';

import axios from 'axios';
import { Link, useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import './admin.css';
import SignUp from './signup';

import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FacebookLogin from '@greatsumini/react-facebook-login';
import { useStyles } from '../common/util';
import GroupContext from '../../context/groupContext';

const AdminLogin = (props: any) => {

    const history = useHistory();
    const groupValue =  useContext(GroupContext);
    const [loginSnackBarOpen, setLoginSnackBarOpen] = useState(false);
    const [id, setId] = useState("");
    const [password, setPassword] = useState("");
    const [signUpModalOpen, setSignUpModalOpen] = useState(false);



    const onIdInputChange = (ev: any) => {
        setId(ev.target.value);
    }

    const onPasswordInputChange = (ev: any) => {
        setPassword(ev.target.value);
    }

    // history.listen((location, action) => {
    //     console.log(" Login !!");
    // });

    const submit = () => {
        // axios.get(comm.SERVER_URL+"/admin/test").then(res => {
        //     console.log(" res => ", res);
        // });
        setLoginSnackBarOpen(true);
        if (id && password) {
            axios.get(comm.SERVER_URL + "/admin", {
                params: {
                    mId: id,
                    pass: password,
                }
            }).then((res) => {
                console.log(" res >> ", res.data);
                setLoginSnackBarOpen(false);
                if (res.data.ok) {
                    // log in 성공 
                    console.log(" res.data.adminInfo >> ", res.data.adminInfo);
                    
                    // groupValue.memberId = groupInfo.memberId;
                    groupValue.clubId = res.data.adminInfo?.club;
                    groupValue.jidaeId = res.data.adminInfo?.jidae;
                    groupValue.jiyeokId = res.data.adminInfo?.jiyeok;
                    groupValue.jiguId = res.data.adminInfo.jigu;
                    groupValue.authLevel = res.data.adminInfo.authLevel;

                    if(res.data.adminInfo.club == 3002){
                        history.push("/dashboard", { jigu: res.data.adminInfo.jigu });
                    }else{
                        history.push("/adminMain", res.data.adminInfo);
                    }

                } else {
                    if (res.data.error === "Admin not found") {
                        alert("아이디 또는 패쓰워드가 틀립니다.");
                    }
                    if (res.data.error === "Admin not confirmed") {
                        alert("인증받지 못한 관리자 입니다. 인증 요청을 해주세요.");
                    }
                }
            }).catch(ex => {
                console.log(" ex >>>>>>>>>>>>>>>>>> ", ex);
            })

        } else {
            alert("아이디 혹은 패쓰워드를 입력해주세요");
        }
    }

    const SignUpModal = () => {
        const classes = useStyles();
        const handleClose = () => {
            setSignUpModalOpen(false);
        };

        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className={classes.modal}
                    open={signUpModalOpen}
                    onClose={handleClose}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={signUpModalOpen}>
                        <div>
                            <SignUp fire={() => {
                                setSignUpModalOpen(false)
                            }} />
                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }

    const goToSignUp = () => {
        console.log("goto sign up!");
        setSignUpModalOpen(true);
        //     history.push("/adminSignup");
    }

    return (
        <div className="flex flex-col ml-4 mt-4 mr-3 lg:ml-8 lg:mt-8 lg:w-192">
            <div className="mb-3 lg:mt-15"><span className='login-page'>라이온스 클럽 로그인</span>

            </div>
            <img className='lg:w-192 lg:h-110 mb-4 lg:mb-8 lg:mt-15' src={logo} />
            <div className='flex flex-row lg:w-192 items-center'>
                <div className='flex flex-col mr-4 lg:mr-10 items-center'>
                    <TextField className='h-16 w-64 lg:w-120' id="outlined-basic" label="아이디" variant="outlined" onChange={onIdInputChange} />
                    <TextField className='h-16 w-64 lg:w-120' id="outlined-basic" label="패쓰워드" variant="outlined" type="password" onChange={onPasswordInputChange} />
                </div>
                <div className="h-34" onClick={submit}>
                    <Button className='h-28 w-28 lg:w-48 ' variant="contained" color="primary"><span className="text-xl" >로그인</span></Button>
                    <div className="h-2"></div>
                </div>
            </div>
            <div className='flex flex-row-reverse align-bottom lg:w-192 pr-4 pt-4'>
                <Button className="h-12 w-30" variant="outlined" color="primary" onClick={goToSignUp}>
                    <span className="text-green-800 text-lg" >클럽 Admin(관리자) 가입</span>
                </Button>
            </div>
            <div className='flex flex-row-reverse mr-5 mt-3'>
                <span className='text-red-500 underline font-bold'>* PC에서 이용해 주세요</span>
            </div>

            <Snackbar
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                message="로그인 처리 중입니다...."
                open={loginSnackBarOpen}
            />
        {SignUpModal()}
        </div>
    )
} 

export default AdminLogin;