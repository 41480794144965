import React, { useState, useEffect, useContext } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { Receiver, SmsDto } from '../common/dto';
import { useHistory } from 'react-router-dom';
import GroupContext from '../../context/groupContext';

export interface IReceiver{
    name:string,
    mobileNum:string,
    position:string,
  }
  
  export interface ISmsHistory{
    sender:string,
    content: string,
    receivers: Array<IReceiver>,
    sendingTime:string, 
    receiverGrop:Array<string>,
  }
  

const SmsHistory = () => {
    const history: any = useHistory();
    const [smsHistoryArr, setSmsHistoryArr] = useState<Array<ISmsHistory>>();
    const groupValue = useContext(GroupContext);
    console.log("groupValue >> ", groupValue);

    useEffect(() => {
        axios.get(comm.SERVER_URL+"/sms/sendHistory/"+groupValue.jiguId).then(res =>{
            console.log(" >>>>>>>> ", res.data);
            setSmsHistoryArr(res.data.results);
        });
    }, []);

    const goToDetail = (ev :any, id:number) => {
        history.push('/smsHistoryDetial',{id:id})
    }

    const getFormattedTime = (time:any) => {
        let date = new Date(time);
        const year = date.getFullYear().toString().slice(2); // 연도를 2자리로
        const month = String(date.getMonth() + 1).padStart(2, '0'); // 월을 2자리로 맞춤
        const day = String(date.getDate()).padStart(2, '0'); // 일을 2자리로 맞춤
        const hours = String(date.getHours()).padStart(2, '0'); // 시간을 2자리로 맞춤
        const minutes = String(date.getMinutes()).padStart(2, '0'); // 분을 2자리로 맞춤
        const formattedDate = `${year}.${month}.${day} ${hours}:${minutes}`;
        return formattedDate;
    }

    return (<div className='flex flex-col items-start p-1 '>
        <div className='ml-4 mt-2'>*전송 기록</div>
        <div className='flex flex-col items-center'>
        {
            smsHistoryArr?.map(eachHistory => {
                let nicks = "";
                eachHistory.receivers.forEach((nick, index) => {
                    if(index == eachHistory.receivers.length-1){
                        nicks = nicks + nick.name;
                    }else{
                        nicks = nicks + nick.name +", ";
                    }
                })
                  
                return (<div className='flex flex-col m-3 border-gray-400 rounded-md border w-80 p-3' >
                    <span className=''>전송 시간: {getFormattedTime(eachHistory.sendingTime)}</span>
                    <span className=''>보내는 사람:{eachHistory.sender}</span>
                    <span className=''>받는 사람:{nicks}</span>
                    <span className=''>*내용</span>
                    <div className=''>
                        {eachHistory.content}
                    </div>
                </div>)
            })
        }
        </div>

    </div>)
}

export default SmsHistory;

