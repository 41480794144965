import React from 'react';
import './aboutLions.css';
import {useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
 
interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutPurpose: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();
    const contents = [
        "세계 인류 상호간의 이해심을 배양하고 증진시킨다.",
        "건전한 국가관과 시민의식을 고취 시킨다.",
        "지역사회의 생활개선, 사회복지, 공덕심 함양에 적극적인 관심을 갖는다.",
        "우의와 협력 그리고 상호 이해로 클럽간의 유대를 돈독히 한다.",
        "정당과 종교 문제를 제외한 일반인의 관심사인 모든 문제해결을 위한 토론의 장을 마련한다.",
        "지역사회의 숨은 자원 봉사인을 격려하며 각 분야의 효율성을 제고하고 도덕심을 향상시킨다.",
    ];

    const purposeUI = (idx:number, content:string) => {
        return (
            <div className="flex flex-col mt-6 ">
                <p className="purpose-number">{idx+1}</p>
                <div className="purpose-yellow-line"></div>
                <span className="purpose-text">{content}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="라이온스의 목적" />
            </div>
            <div className="flex flex-col mb-16 mt-16 pl-8 pr-9">
                {
                    contents.map((content, idx) => {
                        return purposeUI(idx, content);
                    })
                }
            </div>
            <div className="h-20"></div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutPurpose;
