import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../../common';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import GroupContext from '../../../context/groupContext';
import { to2DArray } from '../../common/util';
import { useHistory, useParams } from 'react-router-dom';
import ExecutiveFooter from '../../common/executiveFooter';
import { pageProps } from './common356E';


const JiguClubSelectScreen = (props : pageProps) => {

    const groupValue = useContext(GroupContext);
    const {pathInfo}= useParams<any>();
    let history = useHistory();
    
    let pathInfoArr = pathInfo.split("&");

    const groupInfo ={
        memberId: pathInfoArr[0],
        clubId: pathInfoArr[1],
        jidaeId: pathInfoArr[2],
        jiyeokId: pathInfoArr[3],
        jiguId: pathInfoArr[4],
    }

    groupValue.memberId = groupInfo.memberId;
    groupValue.clubId = groupInfo.clubId;
    groupValue.jidaeId = groupInfo.jidaeId;
    groupValue.jiyeokId = groupInfo.jiyeokId;
    groupValue.jiguId = groupInfo.jiguId;
    
    const goJiguNotebook = () => {
        history.push('/executiveList/'+pathInfo);
    }

    const goClubNotebook = () => {
        history.push('/clubList/'+pathInfo);
    }

    return(
        <div className='flex flex-col items-center mt-24 bg-white'>
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader clubOnly={true} title="라이온스클럽 모바일 수첩"/>
            </div>
            <div className='flex flex-col items-center mb-18'>
                <div className='select_jigu_notebook' onClick={goJiguNotebook}>
                    <span className='text-lions-blue text-xl'>지구 수첩</span>
                </div>
                <div className='select_club_notebook' onClick={goClubNotebook}>
                    <span className='text-lions-blue text-xl'>지역, 클럽 수첩</span>
                </div>
            </div>
        </div> 
    )
}

export default JiguClubSelectScreen;
