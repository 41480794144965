import React, { useState, useEffect } from 'react';
import comm from '../../common';
import axios from 'axios';
import { Button, Hidden, IconButton, Snackbar } from '@material-ui/core';
import Select from "react-select";
import { autoSendDays, clubOption, jiguOption, jiyeokOption } from './selectItems';
import { userDto } from '../mobile/memberList';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import { SmsDto } from '../common/dto';
import { Location } from 'history';
import { useHistory } from 'react-router-dom';
import { getArrayToCommaString } from '../common/util';

interface ChildProps {
    fire: () => void,
    club: number,
}


const SmsHistoryDetail: React.FC<ChildProps> = (props: ChildProps) => {
    const history: any = useHistory();
    const [smsData, setSmsData] = useState<SmsDto>();
    const smsId = history.location.state.id

    useEffect(() => {
        
        // axios.get(comm.SERVER_URL+"/smsHistory/"+ smsId).then(res =>{
        //     setSmsData(res.data);
        // });
        setSmsData({
            senders:["지구본부", "지구총재"],
            receiverNicks:["클럽회장","지역위원장"],
            content:"공지사항 테스트 공지사항 테스트 공지사항 테스트 공지사항 테스트 공지사항 .....",
            sendTime:'2024-01-22, 11시 15분',
            id:2
        })


    }, []);

    const copySmsToNew = () => {

    }


    return (<div className='flex flex-col max-w-xl'>
                <div className='flex flex-row-reverse mr-4 mb-3'><span className="text-sm italic"
                onClick={copySmsToNew}>발송 내역 복사하기</span></div>
                <span className=''>{smsData?.sendTime}</span>
                <span className=''>보내는 사람:{getArrayToCommaString(smsData?.senders)}</span>
                <span className=''>받는 사람:{getArrayToCommaString(smsData?.receiverNicks)}</span>
                <span className=''>*내용</span>
                <div className=''>
                    {smsData?.content}
                </div>
                <span className='mt-4'>*응답 설정</span>
                <div className=''>

                </div>
                <span className='mt-4'>*응답 현황</span>
                <div className=''>

                </div>
    </div>)
}

export default SmsHistoryDetail;

