import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import homeImg from '../../images/home_back.png';

// var encodeUrl = require('encodeurl')

function MemberQuitRequest(props: any): any {
    const history: any = useHistory();
    const [mobileNum, setMobileNum] = useState("");
    const [quitReason, setQuitReason] = useState("");

    const handleSubmit = async () => {
      let postResult = await axios.post(comm.SERVER_URL+"/member/quit", {mobileNum:mobileNum, quitReason:quitReason});
      if(postResult.data.ok){
        alert("탈퇴 또는 계정 삭제 요청이 정상적으로 완료 되었습니다.")
      };
    };

    return (
        <div className="flex flex-col w-full justify-center items-center mt-12">
            <div className='relative w-90 mb-12 mt-4'>
                <div className='text-2xl'>라이온스 토탈(Lions total)</div>
                <div className='text-xl mt-4'>회원 탈퇴 및 계정 삭제 요청 페이지</div>
                <div className='text-lg mt-4'>다음의 본인의 전화번호와 탈퇴 사유를 입력해 주시고, 탈퇴 요청 버튼을 클릭해 주시면, 24시간 이내에 탈퇴 처리를 해드리겠습니다.</div>
                <div className='text-base mt-4 text-red-600'>탈퇴 및 계정 삭제 요청시, 멤버쉽 가입시 입력된 모든 회원 정보(이름, 전화번호, 클럽 소속 등)가 영구 삭제 됩니다.</div>
                <div className='border border-gray-500 p-5 rounded-lg mt-4'>
                <div className='flex flex-row mt-4'>
                    <div><span>전화번호:</span> <input className='pl-2 w-34 h-9 border border-gray-500' type='number' placeholder='숫자만 입력' onChange={(ev)=>{setMobileNum(ev.target.value)}}/></div>
                    <div className='ml-4'><span>탈퇴 및 계정 삭제 요청 사유: </span> <input className='pl-2 w-96 h-9 border border-gray-500' type='text' onChange={(ev) => {setQuitReason(ev.target.value)}}/></div>
                </div>
                <button type="button" className='rounded-xl border-blue-700 border-solid border w-96 h-16 mt-7 ml-60' onClick={handleSubmit}>탈퇴 요청</button> 
                </div>
                <div className='mt-10'>관리자: memeSong, 기타 추가 문의처: memehsforever@gmail.com </div>
            </div>            
        </div>
    )
}

export default MemberQuitRequest;
