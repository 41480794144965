import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";
import comm from '../../common';
import axios from 'axios';

import Snackbar from '@material-ui/core/Snackbar';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import DeleteWarningSnackBar from './deleteWarningSnackBar';

interface SupportProps {
    fire: () => void,
    memberId: number,
}

export interface Support {
    id:number,
    support: string,
    supportMoney: number,
    supportDay: string,
    owner: number,
}

const AddSupport: React.FC<SupportProps> = (supportProps: SupportProps) => {
    const { register, handleSubmit, watch, errors, getValues, setValue } = useForm();
    const [supportId, setSupportId] = useState(-1);
    const [supportResult, setSupportResult] = useState<Array<Support>>([]);
    const [deleteSponsorSnackBarOpen, setDeleteSponsorSnackBarOpen] = useState(false);

    useEffect(() => {
        axios.get(comm.SERVER_URL + '/spon/supportByMember', {
            params: {
                id: supportProps.memberId
            }
        }).then(res => {
            console.log(" support res => ", res.data);
            setSupportId(res.data.id);
            setSupportResult(res.data)
        });
    }, [])

    const getSupport = () => {
        axios.get(comm.SERVER_URL + '/spon/supportByMember', {
            params: {
                id: supportProps.memberId
            }
        }).then(res => {
            console.log(" support res => ", res.data);
            setSupportId(res.data.id);
            setSupportResult(res.data)
        });
    }

    const updateUserWithSupportId = (supportId:number) => {
        let updatedSupportData = {
            memberId : supportProps.memberId,
            supportId: supportId
        }
        axios.put(comm.SERVER_URL + "/member/support", updatedSupportData).then(res => {
            console.log(" updateUserWithSupportId res >>> ", res.data);
        });    
    }

    // add, modify sponsor
    const onSubmit = (data: any) => {
        console.log(" onSubmit !!!!!!!!!! ");
        const { inputDay, inputSupport, inputMoney} = getValues();
        let supportData = {
            owner: supportProps.memberId,
            supportDay: inputDay,
            support: inputSupport,
            supportMoney: inputMoney,
        };

        if (!supportId) {
            //  AddMember
            axios.post(comm.SERVER_URL + "/spon/support", supportData).then((res) => {
                console.log(" add res >> ", res.data);
                if (res.data.ok) {
                    console.log(" add sponsor data to DB success !!");
                    updateUserWithSupportId(res.data.supportId);
                    getSupport();
                }
            });
        } else {
            //  updateMember
            let updatedSponsorData = {
                id: supportId,
                ...supportData
            }

            console.log(" updatedSponsorData >> ", updatedSponsorData);
            axios.post(comm.SERVER_URL + "/spon/support", updatedSponsorData).then((res) => {
                console.log(" update res >> ", res.data);
                if (res.data.ok) {
                    console.log(" Modify member to DB success !!");
                    updateUserWithSupportId(res.data.supportId);
                    getSupport();
                }
            });
        }
    };

    // Delete sponsor
    const deleteSupport = (supportIdToDelete: number) => {
        console.log(" !!!!!!!!!!!!!!! ");
        // setDeleteSponsorSnackBarOpen(true);
        axios.delete(comm.SERVER_URL+"/spon/deleteSupport", {
            params: {
                id:supportIdToDelete
            }
        }).then(res => {
            console.log(" res >>>> ", res.data);
            if(res.data.ok){
                getSupport();
            }
        });
    }

    const closeSupport = (ev:any) => {
        console.log(" @@@@@@@@@@@@@ ");
        supportProps.fire();
    }

    const fileSponsorInputStyle = {
        width: '90px'
    }

    return (  
        <div> 
            <form onSubmit={handleSubmit(onSubmit)} className=" border-gray-800 border-2 bg-white">
                <div className="flex m-8 flex-row">
                    <div className="w-240">
                        <div className="flex flex-row"><div className="flex flex-1"></div><button onClick={closeSupport}><CloseIcon /></button></div>
                        <div className="flex flex-row items-center justify-center">
                            <span className="text-xl">찬조 정보 입력(수정)</span>
                        </div>
                        {supportResult.length > 0 ? <div className="h-40 overflow-auto border-2 pl-1 border-yellow-400 border-opacity-35 rounded-lg mt-4">
                        <table className="mt-2"> 
                            <tr className="m-4">
                                <td align="left" className="font-bold bg-yellow-200 w-128">찬조 내용</td>
                                <td align="left" className="font-bold  bg-yellow-200 w-52 ml-2">찬조 날짜</td>
                                <td align="left" className="font-bold bg-yellow-200 w-48 ml-2">찬조 금액</td>
                                <td align="center" className="font-bold  bg-yellow-200 mr-2">삭제</td>
                            </tr>
                            <tr className="h-1">

                            </tr>
  
                            {
                                supportResult.map(support => {
                                    console.log(" support => ", support);
                                    return (<tr>
                                        <td className="w-128">{support.support}</td>
                                        <td className="w-52 ml-2">{support.supportDay}</td>
                                        <td className="w-52 ml-2">{support.supportMoney} 원</td>
                                        <td className="w-10 mr-2" onClick={(ev:any) => {
                                            // console.log(" ev >>> ", ev.target.id);
                                            deleteSupport(support.id);
                                        }}><div><DeleteIcon /></div></td>
                                    </tr>)  
                                    })
                            } 
                        </table>
                        </div>: null}
                        <div className="flex flex-row items-start mt-8">
                            <div className="flex flex-col w-42 w-120 ">
                                <span>찬조 내용</span>
                                <input type="text" name="inputSupport" className="input mt-2" ref={register} required />
                            </div>
                            <div className="flex flex-col w-42 w-1/3 ml-6">
                                <span>찬조 날짜</span>
                                <input type="date" name="inputDay" className="input mt-2" ref={register}  />
                            </div>
                            <div className="flex flex-col w-42 w-1/3 ml-6">
                                <span>찬조 금액</span>
                                <div className="flex flex-row items-center">
                                    <input type="number" name="inputMoney" className="input mt-2" ref={register}  />
                                    <span className="ml-2 mt-2">원</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row justify-center items-center h-28 ml-6 mr-6">
                    <button type="submit" className="mr-8 rounded-xl border-blue-600 border-solid border-2 border-opacity-60 w-48 h-16 hover:bg-blue-200">찬조 정보(입력/수정)</button>
                    <button type="button" className="ml-8 rounded-xl border-gray-600 border-solid border-2 border-opacity-60 w-24 h-16 hover:bg-gray-200" onClick={supportProps.fire}>닫기</button>
                </div>
            </form>  
            <DeleteWarningSnackBar fire={()=>{setDeleteSponsorSnackBarOpen(false)}} doubleFire={()=>{supportProps.fire()}} afterDelete={()=>{}}
            open={deleteSponsorSnackBarOpen} idToDelete={supportId} message="삭제 진행 버튼을 클릭하시면 해당 회원의 찬조 정보가 삭제됩니다. 정말로 삭제 하시겠습니까?" place="bottom"/>
        </div>
    )
}

export default AddSupport;