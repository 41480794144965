import React, { useState, useEffect, useContext} from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import './clubMemberlist.css';
import { useHistory, useParams } from 'react-router-dom';
import MobileFooter from '../common/mobileFooter';
import GroupContext from '../../context/groupContext';
import MobileSeeSpon from '../common/mobileSeeSpon';
import ReactGA from 'react-ga';
import { getMemberImg, getMemberImgSimple, getMemberImgWithOption2 } from '../common/util';

declare global {
    interface Window {
        ReactNativeWebView: any;
    }
  }

interface MobileMainProps {
    club: string,
    id: number,
    jiyeokChecked: boolean,
}
 export interface clubType {
    id: number;
    name:string;
    positionClub: string;
    belongTo:string;
    startDay:string;
    jiguNo:string;
    gukjeNo:string;
    sponsorClub:number;
    address:string;
    tel:string;
    fax:string;
    homepage:string;
    email:string;
    slogan:string;
    clubOnly:string;
  
}

interface ClubNChairManType {
    clubInfo: clubType;
    chairManInfo: chairmanType;
}
interface chairmanType{
    id:number;
    name: string;
    imgName:string;
}

interface jiyoekInfo{
    id:number;
    name: string;
}

type clubsType = {
    jidae:string,
    clubs:Array<clubType>,
}


const ClubList : React.FC<MobileMainProps> = (props: any) => {
    const [clubList, setClubList] = useState<Array<clubsType>>([]);
    const [jigu, setJigu] = useState("");
    const [jiyeok, setJiyeok] = useState("");
    const [authLevel, setAuthLevel] = useState(0);
    const [isClubOnly, setIsClubOnly] = useState(false);
    const [jiyeoklist, setJiyeoklist] = useState<Array<jiyoekInfo>>([]);
    const history = useHistory();

    // ===================== Test 때문에 필요한 코드 =======================  //
    const {pathInfo}= useParams<any>();
    const groupValue = useContext(GroupContext);
    // console.log(" pathInfo => ", pathInfo);
    let pathInfoArr = pathInfo.split("&");

    const groupInfo ={
        memberId: pathInfoArr[0],
        clubId: pathInfoArr[1],
        jidaeId: pathInfoArr[2],
        jiyeokId: pathInfoArr[3],
        jiguId: pathInfoArr[4],
    }

    groupValue.memberId = groupInfo.memberId;
    groupValue.clubId = groupInfo.clubId;
    groupValue.jidaeId = groupInfo.jidaeId;
    groupValue.jiyeokId = groupInfo.jiyeokId;
    groupValue.jiguId = groupInfo.jiguId;
    
    // ===============================================  //
    useEffect(()=>{
        console.log('useEffect !!!')
        console.log('useEffect !!!' , groupValue.superUser)
        // getAuthLevel();
        getJiyeokClubList();
        // if(groupValue.superUser){
        //     getJiguName();
        //     getJiyeokName();
        // }
        getJiguName();
        getJiyeokName();
        
        getGroupNUser();
        getIsClubOnly(groupInfo.clubId);
        window.ReactNativeWebView?.postMessage("lastScreen");
        console.log("lastScreen >> ", "lastScreen");
    },[]);

    // const getAuthLevel = async () => {
    //     console.log(" memberId >> ", groupValue.memberId);
    //     let user = await axios.get(comm.SERVER_URL+"/member/oneMember/"+groupValue.memberId);
    //     console.log(" user @@@@ >> ", user.data);
    // }

    const getJiyeokClubList = () => {
        axios.get(comm.SERVER_URL + "/group/jiyeokclubs", {
            params: {
                id: groupInfo.jiyeokId
            }
        }).then(res => {
            setClubList(res.data);
        })
    };

    const getIsClubOnly = (clubId:number) => {
        axios.get(comm.SERVER_URL + "/group/clubInfo/", {
            params: { id: clubId }
        }).then(res => {
            // console.log(" res @@@@ >> ", res.data.clubInfo.clubOnly);
            let resultData = res.data?.clubOnly ? res.data?.clubOnly : 'false';
            if(resultData == "clubOnly"){
                // setIsClubOnly(true);
                goToClub(clubId, true);
            }else{
                // setIsClubOnly(false);
            }
        });
    };
    
    const getJiguName = () => {
        axios.get(comm.SERVER_URL + "/group/jiguById",{
            params:{
                id: groupInfo.jiguId
            }
        }).then(res =>{
            groupValue.jiguName = res.data.name;
            console.log(" jiguName >> ", res.data.name);
            setJigu(res.data.name);
         
        });
    }
    
    const getJiyeokName = () => {
        axios.get(comm.SERVER_URL + "/group/jiyeokById",{
            params:{
                id: groupInfo.jiyeokId
            }
        }).then(res =>{
            groupValue.jiyeokName = res.data.name;
            console.log(" jiyeokName >> ", res.data.name);
            setJiyeok(res.data.name);
            ReactGA.set({dimension14:res.data.name});
            ReactGA.set({jiyeokId:groupValue.jiyeokId},["test"]);
        });
    }
    
    const getGroupNUser = () => {
        axios.get(comm.SERVER_URL+"/member/allUserNData/"+groupValue.memberId).then(res => {
                console.log(" res.data >>> ", res.data);
                setAuthLevel(res.data.authLevel);
                // setJigu(res.data.jiguName);
                // setJiyeok(res.data.jiyeokName);
                if(res.data.authLevel > 0 && !groupValue.superUser){
                    groupValue.superUser = true;
                    // history.push("/jiyeokList", {id: groupValue.jiguId  , name:res.data.jiguName});
                }
            }
        ).catch(ex => {

        }).finally(

        );
    }

    const goToClub = (clubId: number, isClubOnly:boolean) => {
        console.log("isClubOnly >> ", isClubOnly);
        history.push("/memberList", {clubId: clubId, clubOnly:isClubOnly});
    };

    const getClubUI = (data:any) => {
        // console.log(" data >>> @@@ ", data);
        // let memberImg = (data.chairmanInfo?.imgName === null || data.chairmanInfo?.imgName === undefined) ? logo : `https://clublions.s3.ap-northeast-2.amazonaws.com/${data.chairmanInfo.imgName}`;
        // let memberImg = getMemberImgSimple(data.clubInfo.jiguId, data.chairmanInfo.imgName, data.chairmanInfo.gender);
    
        // if(data.chairManInfo.belongToJigu == 3){
        //     memberImg = getMemberImgWithOption2(data.chairmanInfo, 'NFC')
        // }else{
        //     memberImg = getMemberImgSimple(data.clubInfo.jiguId, data.chairmanInfo.imgName, data.chairmanInfo.gender);   
        // }
        console.log(" chairmanInfo >> ", data);
        let memberImg = getMemberImgWithOption2(data.chairmanInfo,'')
        
        // let memberImg = getMemberImgSimple(data.clubInfo.jiguId, data.chairmanInfo?.imgName, data.chairmanInfo?.gender); 

        if(data.clubInfo.name === "ADMIN" || data.clubInfo.name.substr(0,4).toLowerCase() == "test"){
            return null;
        }else{
            return(
                <div className="bg-white h-27 flex flex-col justify-center pt-1.5 pb-1.5 pl-3 pr-3 mb-2 ml-2 mr-2 rounded-sm" onClick={() => {goToClub(data.clubInfo.id, false)}}>
                    <div className="flex flex-row">
                        <div className="w-70">
                            <div className="club-text">{data.clubInfo.name}</div>
                            
                            {data.chairmanInfo?.slogan?<div className="slogan-text">{data.chairmanInfo?.slogan}</div> :<div className="h-5">{data.chairmanInfo?.slogan}</div> }
                            
                            
                            <div className="flex flex-row mt-1 items-center">
                                <img src={memberImg} className="w-10 h-12 rounded-3xl"/>
                                <div className="chairman-name-text">{data.chairmanInfo === undefined? "" : data.chairmanInfo?.name+" 회장"}</div>
                            </div>
                        </div>
                        <div className="w-6">
                           
                        </div>
                    </div>
                </div>)  
        }

    };

    return(
      
            <div className="flex flex-col min-h-screen">
                <div className="home-header"><span className="home-header-text">국제라이온스협회 {jigu+"지구 "+ jiyeok}</span></div>
                <div className="p-4 flex-1 mb-16">
                    <div className="flex flex-col ">
                        {clubList.map(jidae => {
                            return (
                                <div className="flex flex-col bg-lions-black mt-4">
                                    <div className="jidae-text mt-2 mb-2">{jidae.jidae}</div>
                                    {jidae.clubs.map(data => {
                                        // console.log(" club data >>> ", data);
                                        return getClubUI(data);
                                    })}
                                </div>)
                        })}
                    </div>
                </div>
                <div className="fixed bottom-0 left-0 right-0">
                    <MobileFooter />
                </div>
                
                <div className={authLevel > 0 ? "fixed bottom-18 left-2" :"hidden"}>
                    <MobileSeeSpon title="지역리스트" />     
                </div>

                <div className="fixed bottom-16 right-22">
                    <MobileSeeSpon title="공지" />     
                </div>
                <div className="fixed bottom-16 right-4">
                    <MobileSeeSpon title="협찬" />     
                </div>
            </div>
    )
}

export default ClubList;  
