import React, { useState } from 'react';
// import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import FacebookLogin from '@greatsumini/react-facebook-login';
import ReactDOM from 'react-dom';
import axios from 'axios';
import { useHistory } from 'react-router-dom';

const FBLogin = (props: any) => {
    const history = useHistory();
    
    return (
        <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center', height:'100vh' }}>
            <FacebookLogin
           
              style={{
                backgroundColor: '#4267b2',
                color: '#fff',
                fontSize: '16px',
                padding: '12px 24px',
                border: 'none',
                borderRadius: '4px',
              }}
                appId="582987830136502"
                onSuccess={(response: any) => {
                    console.log('Login Success!');
                    console.log('response >> ', response);
                    let short_lived_token = response.accessToken;
                    let userID = response.userID;

                    console.log(" short_lived_token >> ", short_lived_token);
                    console.log(" userID >> ", userID);

                    
                    // axios.get(`https://graph.facebook.com/v16.0/${userID}/posts`).then(resPost => {
                        //     console.log(" resPost >> ", resPost.data);
                        // });
                        
                        // axios.get(`https://graph.facebook.com/v13.0/oauth/access_token?grant_type=fb_exchange_token&client_id=582987830136502&client_secret=1381b067d2fd9044bd8baca413ce687b&fb_exchange_token=${short_lived_token}`).then(resToken => {
                            //     console.log(" res >> ", resToken.data);
                            //     let long_lived_token = resToken.data.access_token;
                            //     console.log(" long_lived_token >> ", long_lived_token);
                            //     axios.post(`https://graph.facebook.com/v13.0/${userID}/subscribed_apps?access_token=${long_lived_token}`).then(resSubscribe => {
                                //         console.log(" resSubscribe >> ", resSubscribe.data);
                                //     });
                                // });
                                
                            }}
                            onFail={(error) => {
                                console.log('Login Failed!');
                                console.log('status: ', error.status);
                            }}
                            onProfileSuccess={(response) => {
                                console.log('Get Profile Success!');
                                console.log('name: ', response.name);
                                // history.push('/fbNotice');
                            }}
                            // render={({ onClick, logout }) => (
                            //     <div onClick={(ev) => history.push('/fbNotice')}>공지 화면으로 이동</div>
                            // )}
                            scope='user_posts'
            ><div>페이스북으로 로그인</div>
            <div>Login With Facebook</div>
            </FacebookLogin>
        </div>

    )
} 

export default FBLogin;