import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import comm from '../../common';
import axios from 'axios';
import logo from '../../images/lions_logo.png';
import { userDto } from '../mobile/memberList'
import { Sponsor } from '../admin/addSponsor';
import { Support } from '../admin/addSupport';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import ExecutiveFooter from './executiveFooter';
import backgroundLogo from '../../images/lionsSymbol.png';
import ImgCall from '../../images/icon-call.png';
import ImgMoney from '../../images/icon_money.png';
import MobileSeeSpon from '../common/mobileSeeSpon';
import { checkEncodeType, checkImage, getMemberImg, getMemberImgAuto, getMemberImgWithOption, getMobilePhoneFormText } from '../common/util';

declare global {
    interface Window {
        ReactNativeWebView: any;
        flutter_inappwebview:any;
    }
}

interface MemberDetialProps {
    memberId: number,
    sponsorId?: number,
    jiguId?:number,
}

export interface userHistory{
    term:number;
    positionType:string;
    positionTitle:string;
}

const MemberDetailCommon: React.FC<MemberDetialProps> = (props) => {
    const [memberInfo, setMemberInfo] = useState<userDto>();
    const [sponsorInfo, setSponsorInfo] = useState<Sponsor>();
    const [supportInfo, setSupportInfo] = useState<Array<Support>>();

    const [userHistoryArr, setUserHistoryArr] = useState<Array<userHistory>>();
    const [imgHeight, setImgHeight] = useState(0);

    const [seePastHistory, setSeePastHistory] = useState<boolean>(false);

    const location = useLocation<MemberDetialProps>();
    let imgURL = "";
    let isAppReady = false;

    let memberId = location.state.memberId;
    let sponsorId = location.state.sponsorId;
    let jiguId = location.state.jiguId;

    useEffect(() => {
        axios.get(comm.SERVER_URL + "/member/history/"+memberId).then(res => {
            // if(res.data?.imgName){
            //     const  url = `https://clublions.s3.ap-northeast-2.amazonaws.com/${res.data?.imgName}`
            //     let type = checkEncodeType(res.data.imgName);
            //     console.log("type >> ", type);
            //     console.log("url >> ", url);
            //     checkImage(url).then(isValid => {
            //         console.log("isValid >> ", isValid);

            //         if(isValid){
            //             res.data.imgName = res.data.imgName?.normalize('NFC');
            //         }else{
            //             res.data.imgName = res.data.imgName?.normalize('NFD');
            //         }
            //         console.log(" type 22 >> " ,checkEncodeType(res.data.imgName))
            //         setMemberInfo(res.data);
            //     })
            // }else{
            // }
            console.log(" res data >>> ", res.data);
            setMemberInfo(res.data);
            setUserHistoryArr(res.data.history);
            if (sponsorId) {
                axios.get(comm.SERVER_URL + "/spon/bymember/", {
                    params: {
                        id: memberId
                    }
                }).then(response => {
                    setSponsorInfo(response.data);
                });
            }
            if (res.data.supportCnt > 0) {
                axios.get(comm.SERVER_URL + "/spon/supportByMember/", {
                    params: {
                        id: memberId
                    }
                }).then(supportRes => {
                    setSupportInfo(supportRes.data)
                });
            }
        });
        // axios.get(comm.SERVER_URL + "/member/history/"+memberId).then(res => {
        //     console.log("member >> ", res.data);
        // });

    }, []);

    const getFooter = (jiguId:number) => {
        if(jiguId == 2){
            return <MobileFooter />
        }else if(jiguId == 3){
            return <ExecutiveFooter />
        }
    }

    const seeHistory = () => {
        console.log("see History!");
        setSeePastHistory(!seePastHistory);
    }
    
    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    };

    const onImgLoad = (event: React.SyntheticEvent<HTMLImageElement, Event>) =>{
        console.log(event.currentTarget.offsetHeight);
        setImgHeight(event.currentTarget.offsetHeight+90);
    };

    const isSponsorOrSupportExist = () => {
        if (sponsorInfo) {
            return true;
        }

        if (supportInfo) {
            return true;
        }

        return false;
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title="회원 상세정보" />
            </div>
            <div className="flex-1 mt-14 h-full bg-lions-black">
                <div id="memberInfo-area" className={isSponsorOrSupportExist() ? "memberDetail-memberInfo" : "memberDetail-memberInfo-fullheight"}>
                    <img src={backgroundLogo} className="memberDetail-back-img" />
                    <img src={memberInfo ? getMemberImg(memberInfo) : logo} alt="" className="memberDetail-member-img object-fill rounded-full" />  
                    <span className="memberDetail-name-text">{memberInfo?.name}</span>
                    <span className="memberDetail-general-info">{memberInfo?.englishName}</span>
                    <span className={memberInfo?.positionJigu ? "memberDetail-general-info" : "hidden"}>{memberInfo?.positionJigu}</span>
                    <span className="memberDetail-general-info">{memberInfo?.positionClub}</span>
                    <span className="memberDetail-general-info">회원번호: {memberInfo?.memberNo}</span>
                    <span className="memberDetail-general-info">{memberInfo?.startDay ? "입회: " + memberInfo?.startDay : ""} </span>
                    <span className="memberDetail-general-info">{memberInfo?.job}</span>
                    <span className="memberDetail-general-info-narrow">{memberInfo?.address}</span>
                    <span className="memberDetail-general-info">{memberInfo?.phoneNumWork ? "직장: " + memberInfo?.phoneNumWork : ""}</span>
                    
                    {/* <span className="memberDetail-general-info">{memberInfo?.birthYear ? "생일: " + memberInfo?.birthYear : ""}</span> */}
                    <span className="memberDetail-general-info">{memberInfo?.sponsorName ? "추천인: " + memberInfo?.sponsorName : ""}</span>
                    <span className="memberDetail-general-info">{memberInfo?.slogan ? "슬로건: " + memberInfo?.slogan : ""}</span>
                    <div className="ex-tel2" onClick={(ev) => { handleCall(memberInfo?.mobileNum) }}>
                        <img src={ImgCall} className="ex-tel-img2" />
                        <span className="ex-tel-text2">{getMobilePhoneFormText(memberInfo?.mobileNum ? memberInfo?.mobileNum : "")}</span>
                    </div>
                    <div className='flex items-center justify-center border-2 border-gray-500 p-4 pt-1 pb-1 rounded-lg mb-2' onClick={seeHistory}>
                        <span className='text-white'>{seePastHistory ? "과거 직책 숨기기" : "과거 직책 보기" }</span>
                    </div>
                    <div className={seePastHistory ? 'flex flex-col p-2 mb-22' : 'hidden'} id='history_list'>
                    {
                        userHistoryArr?.map((eachHistory : userHistory) => {
                            let term = eachHistory.term;
                            return <div className='flex flex-row'>
                                <span className='ml-2 text-white'>{term}~{term+1} 회기: </span>
                                <span className='ml-2 text-white'>{eachHistory.positionTitle}</span> 
                            </div>
                        })
                    }
                    </div>
                    {/* <div className={isSponsorOrSupportExist() ? "h-0" : "h-35h"}>
                    </div> */}
                </div>
                {sponsorInfo ?
                    <div style={{height:imgHeight}}>
                        <div className="relative mt-5 pl-5 pr-5 ">
                            {sponsorInfo.sponsorImgName ? <img onLoad={onImgLoad} src={`https://clublions.s3.ap-northeast-2.amazonaws.com/${sponsorInfo.sponsorImgName}`} alt="" className="w-9/10  ml-5 mr-7 max-h-full" /> : <img src={logo} alt="" className="w-30 h-30 ml-5 mr-7" />}
                        </div>
                    </div> : null
                }
                {supportInfo ? <div className="mt-4 ml-4 mr-4 mb-4"></div> : null}
                {supportInfo ?
                    <table className="table-wrapper">
                        <tbody>
                        <tr className="m-2">
                            <td align="left" className="font-bold text-white bg-lions-blue w-6/10 pl-2">찬조 내용</td>
                            <td align="left" className="font-bold text-white bg-lions-blue w-2/10 ml-2">날짜</td>
                            <td align="left" className="font-bold text-white bg-lions-blue w-2/10 ml-2">찬조금</td>
                        </tr>
                        <tr className="h-1">
                        </tr>
                        {
                            supportInfo ? supportInfo.map(support => {
                                let supportDay = support.supportDay.substr(2, support.supportDay.length - 1);
                                return (<tr className="table-back-color">
                                    <td className="w-6/10 text-sm table-back-color">{support.support.length > 18 ? support.support.substring(0, 17) + ".." : support.support}</td>
                                    <td className="w-2/10 ml-2 text-xs table-back-color">{supportDay}일</td>
                                    <td className="w-2/10 ml-2 text-sm table-back-color ">{support.supportMoney}원</td>
                                </tr>)
                            }) :null}
                        </tbody>
                    </table> : null}
                   
            </div>
            <div className="fixed bottom-0 left-0 right-0 z-10">
                {jiguId ? getFooter(jiguId) : getFooter(2)}
            </div>
        </div>
    );
};

export default MemberDetailCommon;
