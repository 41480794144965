import React, { useState, useRef, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../../common';
import { useHistory, useParams } from 'react-router-dom';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import MobileFooter from '../../common/mobileFooter';
import GroupContext from '../../../context/groupContext';
import ImgCall from '../../../images/icon-call.png';
import NameField from '../../../images/nameField.png';
import { getMemberImg, getMemberImgWithOption, getMobilePhoneFormText } from '../../common/util';
import ExecutiveFooter from '../../common/executiveFooter';
import { clubType } from '../jiguMemberList';
import { PrimeMemberUI } from '../../common/primeMemberUI';
import { pageProps } from './common356E';

const ExecutiveList = (props : pageProps) => {
    const [jiguMemberList, setJiguMemberList] = useState<Array<userDto>>([]);
    const groupValue = useContext(GroupContext);
    const {pathInfo}= useParams<any>();
    const history: any = useHistory();

    // console.log(" pathInfo => ", pathInfo);
    let pathInfoArr = pathInfo.split("&");

    const groupInfo ={
        memberId: pathInfoArr[0],
        clubId: pathInfoArr[1],
        jidaeId: pathInfoArr[2],
        jiyeokId: pathInfoArr[3],
        jiguId: pathInfoArr[4],
    }

    groupValue.memberId = groupInfo.memberId;
    groupValue.clubId = groupInfo.clubId;
    groupValue.jidaeId = groupInfo.jidaeId;
    groupValue.jiyeokId = groupInfo.jiyeokId;
    groupValue.jiguId = groupInfo.jiguId;
    
    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }

    useEffect(() => {
        console.log("group Info >> ", groupInfo);
        getJiguMemberList(groupInfo.jiguId);
        window.ReactNativeWebView?.postMessage("normalScreen");
        console.log("normalScreen >> ", "normalScreen");
    
    }, []);

    const getJiguMemberList = (jiguId: number) => {
        axios.get(comm.SERVER_URL + "/member/pureJiguMembers", {
            params: {
                id: jiguId,
                type:'jibhang'
            }
        }).then(res => {
            console.log("jigu res >>>> ", res.data);
            setJiguMemberList(res.data);
        })
    }; 

    const isWrapBoxExecutive = (positionTitle?:string) => {

        if((positionTitle && positionTitle.includes("부총재")) || (positionTitle && positionTitle.includes("연수원 원장")) || (positionTitle && positionTitle.includes("전의장"))){
            return true;
        }else{
            return false;
        }
    }

    const getBoxExecitiveTitle = (title:string, formerChongjae:string) => {
        let result = "";
        if(title?.substring(0,3) == "지구 "){
            result = title?.substr(3);
        }else{
            // if(formerChongjae.length > 0){
            //     result = "전총재"
            // }else{
            //     result = title;
            // }
            result = formerChongjae;
        }
        return result;
    };

    const getExcutives = () => {
        const members = jiguMemberList.filter(each => {
            return each.positionJigu !== "" && each.positionJigu !== "총재" && each.positionJigu !== "직전총재" && !each.positionJigu?.includes("총재협의회장") && (each.positionJigu !== "" || each.positionFreeJigu !== "");
        });
        {/* <div className="flex flex-row mt-1 p-2"> */}
        return (
        <div className="ex-wrap-center">
            {members.map(member => {
                let imgUrl = getMemberImg(member);
                console.log("imgUrl >>> ", imgUrl);
                return (
                    <div className={isWrapBoxExecutive(member.positionJigu) ? "w-full mt-2" : "w-full"} onClick={(ev) => goDetail(ev, member)}>
                        <div className={isWrapBoxExecutive(member.positionJigu)  ? "chairman-upper-bar" : "h-0"}><span className="chairman-upper-text">{getBoxExecitiveTitle(member.positionJigu ? member.positionJigu : "", member.formerJiguChongjae ? member.formerJiguChongjae :"")} </span></div>
                        <div className={isWrapBoxExecutive(member.positionJigu) ? "flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4" : "flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4"}>
                        <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                        <div className="flex flex-col ml-2">
                            <span className="ex-name-text">{member.name}</span>
                            <div className='flex flex-row'>
                                <span className="ex-position-text">{member.positionJigu} / </span>
                                <span className="ex-position-text ml-1">{member.clubName}</span>
                            </div>
                            <div className="ex-tel" onClick={(ev) => { handleCall(member?.mobileNum); ev.stopPropagation();}}>
                                <img src={ImgCall} className="ex-tel-img" />
                                <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                            </div>
                        </div>
                        </div>
                    </div>
                )
            })}
        </div>
        )
    }

    const handleCall = (phoneNum: string | undefined) => {
        console.log("fffphone > "+phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum).then(function() {
            console.log('finished');
        });
    }

    return (
        <div className="flex flex-col">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title="총재단"/>
            </div>
            <div className="flex flex-col mb-16 mt-14">
                <div className="flex flex-col mt-3 items-center">
                    {PrimeMemberUI(jiguMemberList, "총재")}
                    {PrimeMemberUI(jiguMemberList, "총재협의회장")}
                    {PrimeMemberUI(jiguMemberList, "직전총재")}
                </div>
                <div className="h-2"></div>
                {getExcutives()}
            </div>
            <div className="footer">
                <ExecutiveFooter position={0}/>
            </div>
        </div>
    )
}

export default ExecutiveList;
