import React from 'react';
import './aboutLions.css';
import backgroundLogo from '../../images/lionsSymbol.png';
import ChairMan from '../../images/lions_current_chairman.png';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';

interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutChairman: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();

    const textUI = (text1: string, text2: string, text3: string) => {
        return (
            <div className="init-text-wrapper">
                <span className="chairman-text1">{text1}</span>
                <div className="chairman-yellowbar"></div>
                <span className="chairman-text2">{text2}</span>
                <span className="init-text3">{text3}</span>
            </div>
        )
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="국제라이온스협회 회장" />
            </div>
            <div className="flex flex-col items-center">
                <div className="wrapper-init-back-logo">
                    <img src={backgroundLogo} className="init-back-logo" />
                </div>
                <div className="flex flex-col items-center mb-16 mt-14">
                    <div className="w-100w h-96 items-center flex flex-col bg-lions-black">
                        <span className="melvin-jones">파브리시오 올리베이라</span>
                        <img src={ChairMan} className="w-54 h-54 rounded-full mt-4 z-10 object-fill" />
                    </div>

                    <div id="bottom" className="flex flex-col">
                        {textUI("주제", "우리는 봉사한다", "We Serve")}
                        {textUI("표제", "새로운 발자취를 만듭니다", "MAKE YOUR MARK")}
                    </div>
                    <div className="mb-8"></div>
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}

export default AboutChairman;
