import React from 'react';
import './aboutLions.css';
import slogan1 from '../../images/fir_slogan.png'; 
import slogan2 from '../../images/sec_slogan.png'; 
import slogan3 from '../../images/third_slogan.png'; 
import slogan4 from '../../images/forth_slogan.png'; 
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
 
interface AboutLionsProps {
    club: string,
    id: number,
}



const AboutSlogan: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();

    const sloganUI = (imgUrl:string, text1:string, text2:string) => {
        return(
            <div className="flex flex-col justify-items-center items-center">
                <img src={imgUrl} className="slogan-img"/>
                <span className="slogan-text1">{text1}</span>
                <span className="slogan-text2">{text2}</span>
            </div>
        );
    }

    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="라이온스 사자후" />
            </div>
            <div className="flex flex-col items-center mb-16 mt-14">
                <div className="slogan-first-row">
                    {sloganUI(slogan1, "우 -", "단결")}
                    {sloganUI(slogan2, "왔 -", "성실")}
                </div>
                <div className="slogan-second-row">
                    {sloganUI(slogan3, "왔 -", "용맹")}
                    {sloganUI(slogan4, "우 - 왔", "번영")}
                </div>

            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutSlogan;
