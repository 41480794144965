import React, { useContext } from 'react';
import { useHistory } from "react-router-dom";
import GroupContext from '../../context/groupContext';
import '../mobile/clubMemberlist.css';

interface MobileSeeSponProps {
    clubId?: number,
    title?:string,
};

const MobileSeeSpon: React.FC<MobileSeeSponProps> = (props: MobileSeeSponProps) => {
    let history = useHistory();
    const context = useContext(GroupContext)
    let extraUrl = context.jiguId;
    const goSponPage = () => {
        if(props.title == "협찬"){
            history.push("/sponsorList", { clubId: props.clubId });
        }else if(props.title == "공지"){
            history.push("/noticeList");
        }else if(props.title == "지역리스트"){
            history.push("/jiyeokList", {id:context.jiguId, name:context.jiguName});
        }
    };
    if(props.title == "지역리스트"){
        return(
            <div id="footer" className="w-18 h-10 bg-blue-500 rounded-md flex flex-row justify-center items-center text-white" onClick={goSponPage}>
                <span className="text-sm">{props.title}</span>
            </div>
        )
    }else{
        return (
            <div id="footer" className="w-14 h-14 bg-blue-500 rounded-full flex flex-row justify-center items-center text-white" onClick={goSponPage}>
                <span className="sponsor-btn-text">{props.title}</span>
            </div>
        );
    }



}

export default MobileSeeSpon;