import ImgCall from '../../images/icon-call.png';
import NameField from '../../images/nameField.png';
import { getMemberImg, getMemberImgWithOption, getMemberImgWithOption2, getMobilePhoneFormText } from './util';
import GroupContext from '../../context/groupContext';
import { useHistory } from 'react-router-dom';
import { userDto } from '../mobile/memberList';
import { handleCall, isWrapBoxExecutive } from './listUtils';


export const PrimeMemberUI = (members:Array<userDto>, position: string) => {
    const history = useHistory();

    const primeMember = members.filter(each => {
        return (each.positionJigu?.includes(position) || each.positionJiyeok?.includes(position))
    });
    
    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }

    if (primeMember.length === 0) {
        return (null)
    } else {
        let fChongjae = primeMember[0];
        let imgUrl = getMemberImg(fChongjae);

        return (
            <div className="primemember-wrapper" onClick={(ev) => goDetail(ev, fChongjae)}>
                <div className="primemember-title">{position}</div>
                <div className="primemeber-box">
                    <div className="primemember-img-wrapper">
                        <img src={imgUrl} alt="" className="primemeber-img" />
                        <span className="primember-name-text">{fChongjae.name}</span>
                        <img src={NameField} className="primember-name-area" />
                    </div>
                    <div className="flex flex-col items-start ">
                        <span className="primemember-slogan-text">{fChongjae.slogan}</span>
                        <span className="primemember-info-text">{fChongjae.clubName}</span>
                        <span className="primemember-info-text">{fChongjae.address}</span>
                        <div className="primemeber-tel" onClick={(ev) => {handleCall(fChongjae?.mobileNum); ev.stopPropagation();}}>
                            <img src={ImgCall} className="primemeber-tel-img" />
                            <span className="primemeber-tel-text">{getMobilePhoneFormText(fChongjae.mobileNum)}</span>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export const PrimeMemberUIOnlyChongJae = (member:userDto, position: string) => {
    const history = useHistory();

    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }


    let fChongjae = member;
    let imgUrl = '';
    if(fChongjae){
        imgUrl = getMemberImg(fChongjae);
    }else{
        imgUrl = "";
    }

    return (
        <div className="primemember-wrapper" onClick={(ev) => goDetail(ev, fChongjae)}>
            <div className="primemember-title">{position}</div>
            <div className="primemeber-box">
                <div className="primemember-img-wrapper">
                    <img src={imgUrl} alt="" className="primemeber-img" />
                    <span className="primember-name-text">{fChongjae.name}</span>
                    <img src={NameField} className="primember-name-area" />
                </div>
                <div className="flex flex-col items-start ">
                    <span className="primemember-slogan-text">{fChongjae.slogan}</span>
                    <span className="primemember-info-text">{fChongjae.clubName}</span>
                    <span className="primemember-info-text">{fChongjae.address}</span>
                    <div className="primemeber-tel" onClick={(ev) => {handleCall(fChongjae?.mobileNum); ev.stopPropagation();}}>
                        <img src={ImgCall} className="primemeber-tel-img" />
                        <span className="primemeber-tel-text">{getMobilePhoneFormText(fChongjae.mobileNum)}</span>
                    </div>
                </div>
            </div>
        </div>
    )
}  

export const SubPrimeMember = (member:userDto) => {
    const history = useHistory();

    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }

    let fChongjae = member;
    let imgUrl = '';
    if(fChongjae){
        imgUrl = getMemberImg(fChongjae);
    }else{
        imgUrl = "";
    }

    return (<div className="w-full mt-2" onClick={(ev) => goDetail(ev, member)}>
                <div className="chairman-upper-bar"><span className="chairman-upper-text">{member.positionJigu}</span></div>
                <div className="flex flex-row pt-2 pb-2 items-center border border-blue-900 ml-4 mr-4">
                <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
                <div className="flex flex-col ml-2">
                <span className="ex-name-text">{member.name}</span>
                <div className='flex flex-row'>
                    <span className="ex-position-text">{member.positionJigu} / </span>
                    <span className="ex-position-text ml-1">{member.clubName}</span>
                </div>
                <div className="ex-tel" onClick={(ev) => { handleCall(member?.mobileNum); ev.stopPropagation();}}>
                    <img src={ImgCall} className="ex-tel-img" />
                    <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                </div>
            </div>
            </div>
        </div>)
}

export const GeneralMember = (member:userDto) => {
    const history = useHistory();

    const goDetail = (ev:any, member: userDto) => {
        history.push('/memberDetailCommon', { memberId: member.id, sponsorId:member.sponId, jiguId:member.belongToJigu });
    }

    let imgUrl = '';
    if(member){
        imgUrl = getMemberImg(member);
    }else{
        imgUrl = "";
    }

    return (<div className="w-full" onClick={(ev) => goDetail(ev, member)}>
        <div className="flex flex-row mt-1 pt-2 pb-2 items-center ml-4 mr-4 w-full">
            <img src={imgUrl} className="w-14 h-16 rounded-3xl object-fill ml-10" />
            <div className="flex flex-col ml-2">
                <span className="ex-name-text">{member.name}</span>
                <div className='flex flex-row'>
                    <span className="ex-position-text">{member.positionJigu} / </span>
                    <span className="ex-position-text ml-1">{member.clubName}</span>
                </div>
                <div className="ex-tel" onClick={(ev) => { handleCall(member?.mobileNum); ev.stopPropagation(); }}>
                    <img src={ImgCall} className="ex-tel-img" />
                    <span className="ex-tel-text">{getMobilePhoneFormText(member.mobileNum)}</span>
                </div>
            </div>
        </div>
    </div>)
}