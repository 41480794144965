import React from 'react';
import './aboutLions.css';
import { useHistory } from 'react-router-dom';
import CloseIcon from '@material-ui/icons/Close';
import { getMobilePhoneFormText } from '../common/util';

interface AboutLionsProps {
    club: string,
    id: number,
}

const handleCall = (phoneNum: string | undefined) => {
    console.log(" fffphone >> ", phoneNum);
    // window.ReactNativeWebView?.postMessage(phoneNum);
    window.flutter_inappwebview?.callHandler('fromReact', phoneNum);
    
};
  
const sponsorDetail: React.FC<AboutLionsProps> = (props) => {
    const history :any = useHistory();
    let sponInfo = history.location.state; 
    let homepage = "";
    let address = "";
    let contact1 = "";
    if(sponInfo.homepage){
        homepage = "http://"+sponInfo.homepage;
    }
    if(sponInfo.address){
        address = "주소: " + sponInfo.address;
    }
    if(sponInfo.contact1){
        contact1 = "문의: "+getMobilePhoneFormText(sponInfo.contact1);
    }

    return (
        <div className="relativeflex flex-col">
            <div className="flex flex-row-reverse pt-2 pr-2 pb-2" onClick={(ev) => history.goBack()}><CloseIcon className="w-1 h-1"/></div>
            <div className="w-100w h-100h pl-2 pr-2 mt-12">
                <div className='ml-3 mb-3 text-2xl'>{sponInfo.title}</div>
                <img src={sponInfo.imgUrlSpon} className="object-contain"/>
                <div className='mt-6 ml-3'>
                    <div className='text-xl'>{address}</div>
                    <div className = {sponInfo.contact1 ? 'underline text-xl' : 'hidden'} onClick={(ev) => {handleCall(sponInfo.contact1)}}>{contact1}</div>
                    <a href={homepage} target='_blank' className="underline text-xl text-blue-700">{homepage}</a>
                </div>
            </div>
        </div>
    )
}

export default sponsorDetail;
