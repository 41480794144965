import React, { useState, useRef, useEffect } from 'react';
import logo from '../../images/lions_logo.png';
import axios from 'axios';
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import homeImg from '../../images/home_back.png';
import Select from "react-select";
import { changeMonthFormat, getObjFromOptionLabelInSelect } from '../common/util';
import { userDto } from '../mobile/memberList';
import { clubOption, gender } from './selectItems';
import { BasicBlueButton } from '../common/commonUI';
import { saveAs } from 'file-saver';
import dayjs from 'dayjs'
// var encodeUrl = require('encodeurl')
interface DashboardTotal{
    totalMemberCount: number,
    maleMemberCount: number,
    femaleMemberCount: number,
    familyMemberCount: number,
}

interface Volunteer{
    clubId:number;
    month:string; 
    activity:string;
    type:string;
    activitydate:Date;
    cost:number;
}

interface ClubUpperInfo{
    club:number;
    jidae:number;
    jiyeok:number;
    jigu:number;
}

function DashBoardDetail(props: any): any {
    const history: any = useHistory();
    // const [newMemberArr, setNewMemberArr] = useState<Array<userDto>>([{ name:"", englishName: "", birthYear: "", memberTypeArr: [], job: "", sponsorName:"", mobileNum:"", positionClub:"", memberNo:"", startDay:"", zipCode:"", address:"", approvalDay:"",belongToClub:0, gender:""}])
    const [newMemberArr, setNewMemberArr] = useState<Array<userDto>>([])
    const [quitMemberArr, setQuitMemberArr] = useState<Array<userDto>>([])
    const [dashboardData, setDashboardData] = useState<DashboardTotal>();
    const [jiguName, setJiguName] = useState("");
    const [clubUpperInfo, setClubUpperInfo] = useState({
        club:0,
        jidae:0,
        jiyeok:0,
        jigu:0,
    });
    const [volunteers, setVolunteers] = useState<Array<Volunteer>>([]);
    const [selectedMonth, setSelectedMonth] = useState({value:0, label:'달 선택'})
    const [jiyeokNClubs, setJiyeokNClubs] = useState<Array<Array<any>>>([]);
    
    const [reportedNone, setReportedNone] = useState(false);
    const [reportedCurClubMemberCnt, setReportedCurClubMemberCnt] = useState(0);
    const [reportedPreClubMemberCnt, setReportedPreClubMemberCnt] = useState(0);
    const [reporterMobile, setReporterMobile] = useState("");
    const [dropoutConfirm, setDropoutConfirm] = useState(false);
    const [entConfirm, setEntConfirm] = useState(false);

    const [enterConfirmText, setEnterConfirmText] = useState("");
    const [exitConfirmText, setExitConfirmText] = useState("");

    let month = history.location.state.month;
    let monthToFind = changeMonthFormat(month.label);
    const allMonths = history.location.state.allMonths;
    const clubName = history.location.state.clubName;
    const clubId = history.location.state.clubId;
    const x_cor = history.location.state.x_cor;
    const y_cor = history.location.state.y_cor;

    const getVolData = async (month:string) => {
        console.log("month >> ", month);
        let searchMonth = month ? month : monthToFind;
        let vols = await axios.get(comm.SERVER_URL+"/volunteer/byclubmonth/"+clubId+"/"+searchMonth);
        setVolunteers(vols.data);
        // let users = await axios.get(comm.SERVER_URL+"/member/neworretired/"+clubId+"/"+month[0].label.replace('.','-'))
    };

    const getClubTotalInfo = async () => {
        let totalInfo = await axios.get(comm.SERVER_URL+"/group/belongto", { params: {
            id: clubId}
        });
        setClubUpperInfo({
            club:clubId,
            jidae:totalInfo.data.belongTo,
            jiyeok:totalInfo.data.jiyeok,
            jigu:totalInfo.data.jigu

        })
    }

    const getClubReporting = async (clubId:number, reportingMonth:string) => {
        const clubReporting = await axios.get(comm.SERVER_URL+"/monthReport/reportingClub/"+clubId+"/"+reportingMonth);
        const tempDay = dayjs(reportingMonth+'-15','YYYY-MM-DD');
        const prevMonth = tempDay.subtract(1,'month').format('YYYY-MM')
        const clubReportingPrev = await axios.get(comm.SERVER_URL+"/monthReport/reportingClub/"+clubId+"/"+prevMonth);

        if(clubReporting.data.reportingClub){
            setReporterMobile(clubReporting.data.reportingClub.reporterMobile)
            setReportedNone(clubReporting.data.reportingClub.isNone);
            setReportedCurClubMemberCnt(clubReporting.data.reportingClub.reportedMember);            
        }else{
            setReporterMobile('')
            setReportedNone(false);
            setReportedCurClubMemberCnt(0);
        }

        if(clubReportingPrev.data.reportingClub){
            setReportedPreClubMemberCnt(clubReportingPrev.data.reportingClub.reportedMember);     
        }else{
            setReportedPreClubMemberCnt(0);
        }       
        
    }

    const getInputUsers = async () => {
        let searchMonth = month ? month : monthToFind;
        let inputUsers = await axios.get(comm.SERVER_URL+"/group/usersInMonthlyReport/"+clubId+"/"+changeMonthFormat(searchMonth.label));
        if(inputUsers.data.length > 0){
            setNewMemberArr(inputUsers.data);
        }
    }

    const getQuitMembers = async () => {
        let searchMonth = month ? month : monthToFind;
        let quitMembers = await axios.get(comm.SERVER_URL+"/group/quitUsersInReport/"+clubId+"/"+changeMonthFormat(searchMonth.label));
        if(quitMembers.data.length > 0){
            setQuitMemberArr(quitMembers.data);
        }
    }

    useEffect(()=>{
        // getData("");
        getClubTotalInfo(); 
        getClubReporting(clubId, monthToFind);
        setSelectedMonth(month);
        getInputUsers();
        getQuitMembers();
    },[])

    useEffect(() => {
        console.log("vols 1111 >> ");
        if(selectedMonth.value !=0){
            getVolData(changeMonthFormat(selectedMonth.label));
        }
    },[selectedMonth]);

    useEffect(() => {
        const unblock = history.block((tx:any) => {
          if (tx.pathname === '/dashboard') {
            tx.state = { ...tx.state, from: 'dashboardDetail', clubId:clubId};
          }
        });
    
        return () => {
          unblock();
        };
      }, [history]);


    const noExist = (noExistObj:string) => {
        return(
            <div className='flex flex-col items-center justify-items-center rounded-lg w-168 h-16 p-5 bg-blue-100'>
                {noExistObj} 없습니다
            </div>
        )
    }

    const memberBox = () => {
        return newMemberArr.map((each,index) =>{
            return(
                <div className='border-2 rounded-lg p-2 mt-2 bg-indigo-100 w-168' id='mBox'>

                    <div className='flex flex-row items-center'>
                        <div className='flex flex-col'>
                        <div className='flex flex-row'>
                                <span className='ml-2'>이름</span> 
                                <span className='ml-3 text-green-700'>{each.isEnterConfirm ? "(입회 승인 완료)" :""}</span>
                            </div>
                            <input type="text" name="inputVolunteerDetail" className="input w-32"  value={each.name}/>
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2'>영문이름</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-32"  value={each.englishName}/>
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2'>생년월일</span>
                            <input type="date" placeholder='예)1968-05-05' name="inputVolunteerDetail" value={each.birthYear ? each.birthYear : '1965-01-01'} className="input w-36" />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2 mr-1'>성별</span>
                            <Select
                                value={each.gender == "남자" ? gender[1] : each.gender == "여자" ? gender[2] : gender[0]}
                                className="ml-1 w-28 mr-1 h-12"
                                name="inputGender"
                                options={gender} />
                        </div>
                    </div>  
    
                    <div className='flex flex-row mt-2 items-center'>

                    </div>
    
                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-1'>핸드폰</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-32" value={each.mobileNum}  />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2'>주소</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-64" value={each.address}  />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-2 mr-1'>우편번호</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-24" value={each.zipCode}  />
                        </div>
                    </div>
    
                    <div className='flex flex-row mt-2 items-center'>
                        <div className='flex flex-col'>
                            <span className='ml-1'>회원 구분</span>
                            <div className='flex flex-row ml-2 items-center mt-1'>
                                <label className='flex items-center'>
                                    <input type="checkbox" checked={each.memberTypeArr.includes("일반회원")} name="일반회원" className="h-6 w-6 mr-1 text-sm"/>일반
                                </label>
                                <label className='flex items-center'>
                                    <input type="checkbox" checked={each.memberTypeArr.includes("가족회원")} name="가족회원" className="h-6 w-6 ml-4 mr-1 text-sm"  />가족
                                </label>
                                <label className='flex items-center'>
                                    <input type="checkbox" checked={each.memberTypeArr.includes("재입회원")} name="재입회원" className="h-6 w-6 ml-4 mr-1 text-sm" />재입
                                </label>
                                <label className='flex items-center'>
                                    <input type="checkbox" checked={each.memberTypeArr.includes("전입회원")} name="전입회원" className="h-6 w-6 ml-4 mr-1 text-sm" />전입
                                </label>
                            </div>
                        </div>

                        <div className='flex flex-col ml-4'>
                            <span className='mr-1 ml-1'>이사회승인날짜</span>
                            <input type="date" value={each.approvalDay} className="input w-36" />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-1 mr-1'>스폰서 명</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-24" value={each.sponsorName} />
                        </div>
                    </div> 
    
                </div>
            )
        })
    }

    const memberQuitBox = () => {
        return quitMemberArr.map((each,index) =>{
            console.log("each quitReason > ", each);
      
            // let quitReason = each.quitReason ? JSON.parse(each.quitReason.replaceAll('/"','')) : '';  
            return(
                <div className='border-2 rounded-lg p-2 mt-2 bg-purple-200 w-168' id='mBox'>
                    <div className='flex flex-row items-center'>
                        <div className='flex flex-col'>
                            <div className='flex flex-row'>
                                <span className='ml-2'>이름</span> 
                                <span className='ml-3 text-red-500'>{each.isDropoutConfirm ? "(퇴회 승인 완료)" :""}</span>
                            </div>
              
                            <input type="text" name="inputVolunteerDetail" className="input w-40"  value={each.name}/>
                        </div>

                        <div className='flex flex-col ml-4'>
                            <span className='ml-2'>퇴회 사유</span>
                            <input type="text" value={each.quitReason} className="input w-36" />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='mr-1 ml-1'>퇴회 승인 날짜</span>
                            <input type="date" value={each.approvalDay} className="input w-36" />
                        </div>
                        <div className='flex flex-col ml-4'>
                            <span className='ml-1 mr-1'>회원 번호</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-24" value={each.memberNo}  />
                        </div>
                    </div>  
    
                    <div className='flex flex-row mt-2 items-center'>
                        {/* <div className='flex flex-col'>
                            <span className='ml-2 mr-1'>클럽 직책</span>
                            <input type="text" name="inputVolunteerDetail" className="input w-40" value={each.positionClub}  />
                        </div> */}


                    </div>
                </div>
            )
        })
    }


    const onSelectMonth = (ev:any) => {
        setSelectedMonth(ev);
    }

    const goToClub = (clubId:number) => {
        history.push('/adminMain', clubUpperInfo);
    }
    
    const downloadReport = async (month:string) => {
        let result = await axios.get(comm.SERVER_URL + "/common/makeClubReport/" + month + "/" + clubId);
        console.log(" result >> ", result.data)
        saveAs(result.data.url, result.data.fileName)
    }

    const enterConfirm = async (confirmText: string, idToConfirm:Array<number>) => {
        if(confirmText == '입회 승인'){
            idToConfirm.map((id:number) =>{
                try{
                    let updateResult = axios.get(comm.SERVER_URL+"/member/enterConfirm/"+id).then(res => {
                        if(res.data.ok){
                            alert('입회 승인이 성공적으로 완료되었습니다.');
                            getInputUsers();
                       
                        }
                    })
                }catch(ex){
                    console.log(" ex >> ", ex+"");
                }
            });
        }else{
            alert('입회 승인 입력 후 승인 버튼 클릭하세요')
        }
    }
    
    const exitConfirm = async (confirmText: string, idToConfirm:Array<number>) => {
        if(confirmText == '퇴회 승인'){
            idToConfirm.map((id:number) =>{
                try{
                    let updateResult = axios.get(comm.SERVER_URL+"/member/exitConfirm/"+id).then(res => {
                        if(res.data.ok){
                            alert('퇴회 승인이 성공적으로 완료되었습니다.');
                            getQuitMembers();
                        }
                    })
                }catch(ex){
                    console.log(" ex >> ", ex+"");
                }
            });
        }else{
            alert('퇴회 승인 입력 후 승인 버튼 클릭하세요')
        }

    }

    return (
        <div className="flex flex-col w-180 justify-center ml-5 mr-5 mt-5">
            <div className='flex flex-row items-center'>
                <div className='w-52 items-center ml-3 hover:underline text-xl font-semibold'
                    onClick={(ev) => { goToClub(clubId) }}>
                    {clubName}
                </div>
                <span className='text-lg ml-4 mr-6'>{month.label.split('.')[1]}월 클럽 상세 활동 내역</span>
                <span className={reporterMobile ? 'text-sm' :'hidden'}>보고자: {reporterMobile}</span>
            </div>
            <div className='flex flex-row items-center justify-between mt-4'>
                <div className='flex flex-row items-center'>
                    <div className='ml-4 '>회원수: <span className='text-blue-600 font-semibold'>{reportedCurClubMemberCnt == 0 ? "미보고" : reportedCurClubMemberCnt}</span></div>
                    <div className='ml-4'>전월 회원수: <span className='text-blue-600 font-semibold'>{reportedPreClubMemberCnt == 0 ? "미보고" : reportedPreClubMemberCnt}</span></div>
                    <div className='ml-4'>증감: <span className='text-blue-600 font-semibold'>{reportedPreClubMemberCnt != 0 && reportedPreClubMemberCnt !=0 ? reportedCurClubMemberCnt - reportedPreClubMemberCnt > 0 ? "+"+(reportedCurClubMemberCnt - reportedPreClubMemberCnt) : reportedCurClubMemberCnt - reportedPreClubMemberCnt : "미보고" }</span></div>
                    {/* <div className='ml-4'>봉사활동내역: <span className='text-blue-600 font-semibold'>{reportedPreClubMemberCnt != 0 && reportedPreClubMemberCnt !=0 ? reportedCurClubMemberCnt - reportedPreClubMemberCnt : "미보고" }</span></div> */}
                </div>
                <BasicBlueButton btnText='다운로드' width='w-20' height='h-10' font='text-sm' margin='mr-6' handleClick={(ev:any) => {downloadReport(monthToFind)}} />
                
            </div>
            
            {reportedNone ? (<div className='border-2 border-gray-700 w-160 ml-6 p-4 mt-4 flex justify-center'>
                <span className=''>"보고 없음"으로 월간 보고가 완료 되었습니다.</span>
            </div>) : <div></div>}

            <div className='flex flex-col mt-5'>
                <div className='flex flex-col'>
                    <div>*회원 입회</div>
                    {newMemberArr.length > 0 ? memberBox() : noExist("입회 회원이")}
                    {
                    newMemberArr.length > 0 ? <div className='flex flex-row items-center mt-4'>
                        <span>* 회원 입회를 승인합니다. </span> <input type='text' className='input w-72 mr-3 ml-4' placeholder='입회 승인' onChange={(ev) => {setEnterConfirmText(ev.target.value)}} />
                        <BasicBlueButton btnText='승인' width='w-20' height='h-10' handleClick={(ev: any) => {enterConfirm(enterConfirmText, newMemberArr.map(each=> each.id ? each.id : 0)) }} font="text-sm"></BasicBlueButton>
                    </div> : null
                    }
                    <div className='mt-4'>*회원 퇴회</div>
                    {quitMemberArr.length > 0 ? memberQuitBox() : noExist("퇴회 회원이")}
                    {
                    quitMemberArr.length > 0 ? <div className='flex flex-row items-center mt-4'>
                        <span>* 회원 퇴회를 승인합니다. </span> <input type='text' className='input w-72 mr-4 ml-4' placeholder='퇴회 승인' onChange={(ev) => {setExitConfirmText(ev.target.value)}} />
                        <BasicBlueButton btnText='승인' width='w-20' height='h-10' handleClick={(ev: any) => {exitConfirm(exitConfirmText, quitMemberArr.map(each=> each.id ? each.id : 0)) }} font="text-sm"></BasicBlueButton>
                    </div> : null
                    }
                </div>
                <div className='flex flex-col w-168 mt-4 mb-8'>
                    <div>*봉사 활동</div>
                    {volunteers.length > 0 ? <table className='mt-2 mt-2 mr-2 w-168 '>
                        <thead className='h-14 '>
                            <tr className='bg-gray-200'>
                                <td className='w-40 text-center border border-gray-800'>봉사항목</td>
                                <td className='w-28 ml-2 text-center border border-gray-800'>봉사날짜</td>
                                <td className='w-32 ml-2 text-center border border-gray-800'>금액환산(원)</td>
                                <td className='w-80 ml-2 text-center border border-gray-800'>봉사내용</td>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                volunteers.map(eachVol => {
                                    let date = "" + eachVol.activitydate;
                                    let formattedDate = date.split('T')[0].split('-')[1] + "." + date.split('T')[0].split('-')[2] + "일";
                                    return (<tr className='h-14 '>
                                        <td className='text-center border border-gray-600'>{eachVol.type}</td>
                                        <td className='text-center border border-gray-600'>{formattedDate}</td>
                                        <td className='text-center border border-gray-600'>{eachVol.cost}</td>
                                        <td className='text-center border border-gray-600'>{eachVol.activity}</td>
                                        <td></td>
                                    </tr>)
                                })
                            }
                        </tbody>
                    </table>:noExist("봉사 활동 내역이")}
                </div>
            </div>
        </div>
    )
}

export default DashBoardDetail;
