import React, { useState, useEffect, useContext} from 'react';
import axios from 'axios';
import comm from '../../common';
import './clubMemberlist.css';
import { useHistory, useParams } from 'react-router-dom';
import GroupContext from '../../context/groupContext';
 
declare global {
    interface Window {
        ReactNativeWebView: any;
    }
  }

interface jiguInfoProps {
    jiguName: string,
    id: number,
}

interface jiyoekInfo{
    id:number;
    name: string;
}

const JiyeokList : React.FC<jiguInfoProps> = (props : any) => {
    const [jigu, setJigu] = useState("");
    const [jiyeoklist, setJiyeoklist] = useState<Array<jiyoekInfo>>([]);
    const history = useHistory();
    const groupValue = useContext(GroupContext);
    groupValue.superUser = true;
    useEffect(()=>{
        getJiyeokList(props.location?.state?.id);
    },[]);

    const getJiyeokList = (jiguId: number) => {
        
        axios.get(comm.SERVER_URL + "/group/jiyeok", {
            params: {
                id: jiguId
            }
        }).then(res => {
            setJigu(props.location.state.name);
            let jiyeoks = res.data;
            if (jiguId == 2) {     //354B지구인 경우만,
                let orderedList: jiyoekInfo[] = [];
                jiyeoks.forEach((each: any, idx: number) => {
                    if (each.id !== 2) {
                        orderedList.push(each);
                    }
                    // if (each.id == 12) {
                    //     // orderedList.push(each);
                    //     // orderedList.push(jiyeoks[1]);
                    // }
                })
                setJiyeoklist(orderedList);
            }else{
                setJiyeoklist(jiyeoks);
            }
        });
    }
        
    const moveToClubList = (jiyeokId:number, jiguId:number) => {
        let extraUrl = groupValue.memberId+"&"+groupValue.clubId+"&"+groupValue.jidaeId+"&"+jiyeokId+"&"+jiguId;
        history.push("/clubList/"+extraUrl);   
    }

    const getJiyeokSelectUI = () => {
        return(
            <div className='flex flex-col'>
                <div className='ml-5 text-xl mb-3'>지역을 선택하세요</div>
                <div className='bg-lions-black'>
                {jiyeoklist.map((each,idx) => {
                    if(idx != 0){
                    return <div className='flex flex-row p-4 h-12 border-2 border-white text-white items-center' onClick={() => {moveToClubList(each.id, props.location.state.id)}}>
                        <span className='text-lg'>{each.name}</span>
                    </div>
                    }else{
                        return null;
                    }
                })}
                </div>
            </div>
        )
    }

    return(
            <div className="flex flex-col min-h-screen">
                <div className="home-header"><span className="home-header-text">국제라이온스협회 {jigu+"지구 "}</span></div>
                <div className="p-4 flex-1 mb-16">
                    <div className="flex flex-col ">
                        {getJiyeokSelectUI()}
                    </div>
                </div>
            </div>
    )
}

export default JiyeokList;  
