import React from 'react';
import './aboutLions.css';
import LionsSong from '../../images/lions_song.png';
import { useHistory } from 'react-router-dom';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import comm from '../../common';
import { S3_URL } from '../common/util';
import { classNames } from 'react-select/src/utils';

interface AboutLionsProps {
    club: string,
    id: number,
}

const AboutCeremony: React.FC<AboutLionsProps> = (props) => {
    const history = useHistory();
    return (
        <div className="flex flex-col min-h-screen">
            <div className="fixed top-0 left-0 right-0 h-14">
                <MobileHeader title="라이온스 의전" />
            </div>
            <div className="flex flex-col mt-22 pl-8 pr-8">
                <p className="purpose-number">의전곡</p>
                <div className="purpose-yellow-line-long3"></div>
                <audio controls className='mt-3'>
                    <source src={S3_URL + "audio/intro.mp3"} type="audio/mp3" />
                </audio>
                <div className='h-5'></div>

                <p className="purpose-number">국민의례</p>
                <div className="purpose-yellow-line-long4"></div>
                <audio controls className='mt-3'>
                    <source src={S3_URL + "audio/ceremony.mp3"} type="audio/mp3" />
                </audio>
                <div className='h-5'></div>

                <p className="purpose-number">애국가</p>
                <div className="purpose-yellow-line-long3"></div>
                <audio controls className='mt-3'>
                    <source src={S3_URL + "audio/national_anthem.mp3"} type="audio/mp3" />
                </audio>
                <div className='h-5'></div>

                <p className="purpose-number">묵념</p>
                <div className="purpose-yellow-line-long3"></div>
                <audio controls className='mt-3'>
                    <source src={S3_URL + "audio/silence.mp3"} type="audio/mp3" />
                </audio>
                <div className='h-5'></div>

                <p className="purpose-number">의전서열</p>
                <div className="purpose-yellow-line-long4"></div>
                    <img src={S3_URL + "audio/protoco.jpeg"} className="w-full h-full" />
                <div className='h-5'></div>

            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}


export default AboutCeremony;
