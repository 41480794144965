import React, { useState, useEffect, useContext } from 'react';
import { userDto } from '../memberList';
import MobileHeader from '../../common/mobileHeader';
import GroupContext from '../../../context/groupContext';
import ExecutiveFooter from '../../common/executiveFooter';
import CommonExecutiveList from '../../common/commonExecutiveList';
import { getExecutiveMemberList } from '../../common/listUtils';
import { pageProps } from './common356E';
import { useHistory } from 'react-router-dom';

const CommonExecutivePage = (props : pageProps) => {
    const [jiguMemberList, setJiguMemberList] = useState<Array<userDto>>([]);
    const groupValue = useContext(GroupContext);
    const history: any = useHistory();
    
    useEffect(() => {
        const getMemberData = async () => {
            let members = await getExecutiveMemberList(groupValue.jiguId, history.location.state.keyword);
            setJiguMemberList(members);
        }
        getMemberData(); 
        window.ReactNativeWebView?.postMessage("normalScreen");
    }, []);

    return (
        <div className="flex flex-col">
            <div className="fixed top-0 left-0 right-0 h-14 z-10">
                <MobileHeader title={history.location.state.title}/>
            </div>
            <div className="flex flex-col mb-16 mt-14">
                {CommonExecutiveList(jiguMemberList, ["총재"], history.location.state.title)}
            </div>
            <div className="footer">
                <ExecutiveFooter />
            </div>
        </div>
    )
}

export default CommonExecutivePage;
