import React, { useState, useEffect, useContext } from 'react';
import logo from '../../images/lions_logo.png';
import ImgCall from '../../images/icon-call.png';
import axios from 'axios';
import './sponsorList.css'; 
import comm from '../../common';
import { useHistory } from 'react-router-dom';
import Snackbar from '@material-ui/core/Snackbar';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import GroupContext from '../../context/groupContext';
// import { Sponsor } from '../admin/addSponsor';
 
interface JiyeokProps {
    belongTo?: number,    //Jiyeok-Id
    club?: string,
    id: number,
}

interface Sponsor {
    id: number,
    clubName: string,
    sponsorName: string,
    positionClub?: string,
    positionFreeClub?: string,
    positionJigu?: string,
    positionFreeJigu?: string,
    positionJiyeok?: string,
    positionFreeJiyeok?: string,
    imgName: string,
    mobileNum: string,
    sponsorImgName: string,
    sponsorTel: string,
}
 
const SponsorList : React.FC<JiyeokProps> = (props: JiyeokProps) => {
    const [sponsorList, setSponsorList] = useState<Array<Sponsor>>([]);
    const history = useHistory();
    const groupInfo = useContext(GroupContext);

    useEffect(()=>{
        getSponsorList("belongToJiyeok", groupInfo.jiyeokId);
            window.ReactNativeWebView?.postMessage("normalScreen");
            console.log("normalScreen >> ", "normalScreen");
    },[]);

    const shuffleArray = (array: Array<any>) => {
        for (let i = 0; i < array.length; i++) {
          let j = Math.floor(Math.random() * (i + 1));
          // [array[i], array[j]] = [array[j], array[i]];
          const x = array[i];
          array[i] = array[j];
          array[j] = x;
        }
        return array;
    };
 
    const getSponsorList = (byWhat:string, id:number) => {
        axios.get(comm.SERVER_URL + "/member/byunit", {
            params: {
                with: byWhat,
                id: id,
            }
        }).then(res => {
            setSponsorList(shuffleArray(res.data));
        })
    };

    const goToDetail = (sponInfo: Object) =>{
        history.push("/sponsorDetail", sponInfo);
    };

    const handleCall = (phoneNum: string | undefined) => {
        console.log(" phoneNum >> ", phoneNum);
        // window.ReactNativeWebView?.postMessage(phoneNum);
        window.flutter_inappwebview?.callHandler('fromReact', phoneNum);
    };
  
    const getSponors = (spon : any) => {
        let imgUrl = spon.imgName ? `https://clublions.s3.ap-northeast-2.amazonaws.com/${spon.imgName}` : logo ;
        let imgUrlSpon = `https://clublions.s3.ap-northeast-2.amazonaws.com/${spon.sponId.sponsorImgName}`;
        let homepage = "http://"+spon.sponId.homepage;
        let sponInfo = {
            imgUrlSpon: imgUrlSpon,
            homepage : spon.sponId.homepage,
            title: spon.sponId.title,
            address: spon.sponId.address,
            contact1: spon.sponId.contact1,
            // contact2: spon.spoinId.contact2
        }
        return(
            <div className="mb-4"> 
                <div className="sponsor-upper-bar"><span className="sponsor-upper-bar-text">{spon.clubName}</span></div>
                <div className="sponsor-member-area">
                    <img src={imgUrl} className="sponsor-member-img"/>
                  <div className="sponsor-info-wrap" >
                    <div className="sponsor-name">{spon.name}</div>
                    <div className="sponsor-position">{spon.positionClub}</div>
                    <div className="sponsor-mobile" onClick={(ev) => {handleCall(sponInfo.contact1)}}>
                        <img src={ImgCall} className="mobile-icon" />
                        <span className="mobile-text">{spon.mobileNum}</span>
                    </div>
                  </div>
                  <div className="sponsor-addi-info-wrap" >
                  <div className="sponsor-position-thick">{spon.sponId.title}</div>
                    <div className="sponsor-position-thick">{spon.sponId.address}</div>
                    <div className="sponsor-position-thick">{spon.sponId.contact2}</div>
                    <a href={homepage} target='_blank' className="underline text-blue-700 sponsor-position-thick ">{spon.sponId.homepage}</a>
                  </div>
                </div>
                <div className="sponsor-spon-content-img" onClick={(ev) => goToDetail(sponInfo)}>
                    <img src={imgUrlSpon} className="img-self1"/>  
                </div>
            </div>
        ) 
    }
  
    return(
        <div className="flex flex-col min-h-screen">
            <div className="flex-1">
                <MobileHeader title="협찬" />
                <div className="flex flex-col mt-3 p-4 mb-14">
                    {sponsorList.map(spon => {
                        return getSponors(spon);
                    })}
                </div>
            </div>
            <div className="fixed bottom-0 left-0 right-0">
                <MobileFooter />
            </div>
        </div>
    )
}

export default SponsorList;  
