import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import comm from '../../common';
import axios from 'axios';
import logo from '../../images/lions_logo.png';
import { userDto } from '../mobile/memberList'
import { Sponsor } from '../admin/addSponsor';
import { Support } from '../admin/addSupport';
import MobileHeader from '../common/mobileHeader';
import MobileFooter from '../common/mobileFooter';
import ExecutiveFooter from './executiveFooter';
import backgroundLogo from '../../images/lionsSymbol.png';
import ImgCall from '../../images/icon-call.png';
import ImgMoney from '../../images/icon_money.png';
import MobileSeeSpon from '../common/mobileSeeSpon';
import { getMemberImg, getMemberImgWithOption, getMobilePhoneFormText } from '../common/util';
import { GeneralMember, PrimeMemberUIOnlyChongJae, SubPrimeMember } from './primeMemberUI';

interface ChongjaedanProps {
    chongjaeList: Array<userDto>,
};

export const Chongjaedan : React.FC<ChongjaedanProps> = (props:ChongjaedanProps) => {
    return (
        <div className='flex flex-col items-center w-full'>
            {
                props.chongjaeList.map((each: userDto, index: number) => {
                    if (each.positionJiguVal == 1 || each.positionJiguVal == 3) {
                        return PrimeMemberUIOnlyChongJae(each, each.positionJigu ? each.positionJigu : "");
                    } else if (each.positionJiguVal == 5 || each.positionJiguVal == 7) {
                        return SubPrimeMember(each);
                    } else {
                        return GeneralMember(each);
                    }
                })
            }
        </div>
    )

}